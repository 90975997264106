import { Box, Typography, useMediaQuery, Container, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import lux from "../../../assets/lux.png";
import gold from "../../../assets/gold.png";
import { Trans } from 'react-i18next';

import lamboImage from '../../../assets/images/cars/huracan.png';
import ferrariImage from "../../../assets/images/cars/488.png";
import porscheImage from "../../../assets/images/cars/911.png";

function Supercar() {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');

    const cars = [
        {
            name: "Lamborghini Huracan",
            img: lamboImage,
            description: t('LamboDesc')
        },
        {
            name: "Ferrari 488",
            img: ferrariImage,
            description: t('FerrariDesc')
        },
        {
            name: "Porsche 911",
            img: porscheImage,
            description: t('PorscheDesc')
        },
    ];

    const fadeIn = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { delay: 0.3, duration: 0.5 } },
      };


    const CarPodium = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.grey[900],
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        boxShadow: theme.shadows[5],
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.3s ease-in-out',
        },
        '& img': {
            width: '100%',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
                transform: 'scale(1.1)',
            }
        }
    }));

    return (
        <Container sx={{ mt: isMobile ? 0 : 8, display: "flex", flexDirection: 'column', alignItems: "center", p: "20px", mb: 6 }}>
            {isMobile && (
          <motion.img src={gold} alt='gold' variants={fadeIn} initial="initial" animate="animate"
          style={{ marginTop: "5.5rem", width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>
)}

      <Container style={{
        display: "flex",
        flexDirection: isMobile ? "column" : undefined,
        borderRadius: '0.5rem', 
        padding: "10px",
        background: "linear-gradient(23deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5290908599768032) 50%, rgba(18,18,18,1) 100%)",
        marginTop: isMobile ? "0rem" : '1rem', justifyContent: "space-between", alignItems: "center"}}>
        {/* <motion.div variants={fadeInUp} initial="initial" animate="animate">
          <Typography style={{
            fontSize: isMobile ? '20px' : '35px', 
            fontStyle: "italic", textDecoration: "underline",
            textDecorationColor: "gold"}}>
            Luxury Agency Rome
          </Typography>
        </motion.div> */}


        
<motion.img src={lux} variants={fadeIn} initial="initial" animate="animate"  alt="lux"
          style={{marginTop: isMobile ? '1rem' : "0rem", width: "300px",}} />


        <motion.span variants={fadeIn} initial="initial" animate="animate"  alt="lux" style={{
          textAlign: isMobile ? 'center': "right", marginTop: isMobile ? "0.4rem" : '0rem',
          fontFamily: "Belanosima", fontStyle: "italic",
          fontSize: isMobile ? '12px' : '18px',
          color: "#B8B3AF"}}>
          <Trans i18nKey="NoleggioTitle" />
          
        </motion.span>
      </Container>
            

      <Typography variant="h6" sx={{ mt: 2, color: 'white'}}>
                           {t('SuperCarIntro')}
                        </Typography>


            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center' }}>
                {cars.map((car, index) => (
                    <CarPodium key={index}>
                        <motion.div whileHover={{ scale: 1.1 }}>
                            <img src={car.img} alt={car.name} />
                        </motion.div>
                        <Typography variant="h5" sx={{ mt: 2, color: 'white', fontWeight: 'bold' }}>
                            {car.name}
                        </Typography>
                        <Typography sx={{ color: 'silver' }}>
                            {car.description}
                        </Typography>
                    </CarPodium>
                ))}
            </Box>
        </Container>
    );
}

export default Supercar;
