import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NewsletterSubscription from '../blog/NewsletterSubscription';

const EmptyStateMessage = () => {
    const navigate = useNavigate();

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            textAlign="center" 
            height="100%"
            p={4}
        >
            <Typography variant="h4" style={{ color: '#6490bc', fontFamily: "Belanosima" }}>
                Nessun post disponibile!
            </Typography>
            <Typography variant="body1" style={{ color: 'grey', marginBottom: "1rem", marginTop: '1rem' }}>
                Resta aggiornato! Torna presto per nuovi contenuti.
            </Typography>
           <NewsletterSubscription/>
        </Box>
    );
};

export default EmptyStateMessage;
