import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, MenuItem, Select, InputLabel, FormControl, useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { motion } from 'framer-motion';
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faClock, faEnvelope, faTimes, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import { borderRadius, width } from '@mui/system';
import { Link } from 'react-router-dom';

const ClientList = ({ searchTerm }) => {
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('/requests', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRequests(response.data);
                setFilteredRequests(response.data);
            } catch (error) {
                console.error('Error fetching requests:', error);
            }
        };

        fetchRequests();

        // const socket = io('http://localhost:8000');
        const socket = io('https://api-lar-014fd3aff401.herokuapp.com');

        socket.on('requestUpdate', (data) => {
            switch (data.type) {
                case 'create':
                    setRequests((prevRequests) => [data.request, ...prevRequests]);
                    setFilteredRequests((prevRequests) => [data.request, ...prevRequests]);
                    break;
                case 'update':
                    setRequests((prevRequests) =>
                        prevRequests.map((request) =>
                            request._id === data.request._id ? data.request : request
                        )
                    );
                    setFilteredRequests((prevRequests) =>
                        prevRequests.map((request) =>
                            request._id === data.request._id ? data.request : request
                        )
                    );
                    break;
                case 'delete':
                    setRequests((prevRequests) =>
                        prevRequests.filter((request) => request._id !== data.request._id)
                    );
                    setFilteredRequests((prevRequests) =>
                        prevRequests.filter((request) => request._id !== data.request._id)
                    );
                    break;
                default:
                    break;
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        let filtered = requests;
        if (searchTerm) {
            filtered = filtered.filter(request =>
                request.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                request.phone.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        if (filterType) {
            filtered = filtered.filter(request => request.type === filterType);
        }
        if (filterStatus) {
            filtered = filtered.filter(request => request.status === filterStatus);
        }
        setFilteredRequests(filtered);
    }, [searchTerm, filterType, filterStatus, requests]);

    const handleStatusChange = async (id, status) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.put(`/requests/${id}/status`, { status }, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            console.error('Error updating request status:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`/requests/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setRequests(requests.filter(request => request._id !== id));
            setFilteredRequests(filteredRequests.filter(request => request._id !== id));
            setOpenConfirmDialog(false);
            setSelectedRequestId(null);
        } catch (error) {
            console.error('Error deleting request:', error);
        }
    };

    const openConfirmDialogHandler = (id) => {
        setSelectedRequestId(id);
        setOpenConfirmDialog(true);
    };

    const closeConfirmDialogHandler = () => {
        setOpenConfirmDialog(false);
        setSelectedRequestId(null);
    };

    return (
        <Box style={{ width: "100%",
            padding: '5px', borderRadius: '0.5rem' }}>
<div style={{display: "flex",  alignItems: "center", justifyContent: "space-between"}}>
<Typography style={{ fontFamily: "Belanosima", fontSize: "18px" }}>
                    Numero di utenti: {requests.length} <FontAwesomeIcon icon={faUserAlt} style={{marginLeft: "5px"}}/>
                </Typography>
                
            </div>
            <Box style={{ maxHeight: 'calc(100vh - 270px)', marginTop: "1rem", 
                overflow: 'auto' }}>
                {filteredRequests.map((request) => (
                    <motion.div
                        key={request._id}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ backgroundColor: 'black',
                            boxShadow: '0 4px 8px rgba(255,255,255,0.3)', 

                            padding: '20px',borderRadius: "0.5rem", 
                             marginBottom: '15px', }}
                    >
                        <Box style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center', justifyContent: "space-between" }}>
                            <Box style={{ display: "flex", width: "100%", alignItems: isMobile ? 'center' : 'center' }}>
                                <Typography style={{ color: '#6490bc', fontFamily: 'Belanosima' }}>
                                    ╰ {request.name}
                                </Typography>
                            </Box>
                           
                            <div style={{ display: "flex", gap: isMobile ? undefined : '0.4rem', justifyContent: isMobile ? "space-between" : 'flex-start', width: "100%", alignItems: "center",  }}>
                                    <Typography style={{ color: '#B8B3AF', display: "flex", alignItems: "center",
                                   
                                    fontSize: "15px", fontFamily: 'Belanosima' }}>
                                        <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: '10px', color: 'white', marginRight: "5px" }} />
                                        
                                        <a href={`mailto:${request.email}`} style={{ marginLeft: '5px', color: 'white' }}>{request.email} </a>
                                    </Typography>
                                    <span style={{ color: "white", display: isMobile ? 'none' : 'block' }}>
                                        -
                                    </span>
                                    <Typography style={{ color: '#B8B3AF', display: "flex", alignItems: "center", fontSize: "15px", fontFamily: 'Belanosima'}}>
                                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#6490bc', marginRight: "5px" }} />
                                       <span style={{ color: "white", textDecoration: 'underline',}}>{request.phone}</span>
                                        <a href={`https://wa.me/${request.phone}`} style={{ marginLeft: '5px', color: '#25D366' }}>
                                        </a>
                                    </Typography>
                                </div>
                                <div style={{gap: "1rem", width: isMobile ?  "100%" : "auto", marginTop: isMobile ? '0.8rem' : undefined, justifyContent: "space-between",  display: "flex", alignItems: "center"}}>
                                <Link to={`/richieste/${request._id}`}>
                                    <Button
                                        color="primary"
                                        style={{ 
                                            backgroundColor: "#007bff", color: "white",
                                            width: isMobile ? 'auto' : "180px", 
                                             }}
                                    >
                                      <FontAwesomeIcon icon={faAddressCard} style={{marginRight: "10px"}}/>  Scheda Cliente
                                    </Button>
                                </Link>
                                <Button
                                  color="primary"
                                  style={{ 
                                      backgroundColor: "#e74c3c", color: "white",
                                      width: isMobile ? 'auto' : "auto", 
                                    }}
                              
                                    onClick={() => openConfirmDialogHandler(request._id)}
                                >
                            <FontAwesomeIcon icon={faTimes} style={{marginRight: "10px"}}/>
                                    Elimina
                                </Button>
                                </div>
                        </Box>
                        
                    </motion.div>
                ))}
            </Box>
            <Dialog
                open={openConfirmDialog}
                onClose={closeConfirmDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Conferma Eliminazione</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sei sicuro di voler eliminare questa richiesta? Questa azione non può essere annullata.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDialogHandler} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={() => handleDelete(selectedRequestId)} color="secondary" autoFocus>
                        Elimina
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ClientList;
