import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClapperboard } from '@fortawesome/free-solid-svg-icons';

function VideoPlayer({ videoUrl }) {
    const [cinemaMode, setCinemaMode] = useState(false);

    const toggleCinemaMode = () => setCinemaMode(!cinemaMode);

    const playerVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        exit: { opacity: 0, scale: 0.5, transition: { duration: 0.3 } }
    };

    const backdropVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.5 } },
        exit: { opacity: 0, transition: { duration: 0.3 } }
    };

    return (
        <div style={{ position: 'relative', padding: '20px' }}>
            <Button onClick={toggleCinemaMode}>
               <FontAwesomeIcon icon={faClapperboard} style={{marginRight: "10px"}} /> {cinemaMode ? 'Exit' : 'L.A.R. Video Trailer'}
            </Button>
            <AnimatePresence>
                {cinemaMode && (
                    <motion.div
                        variants={backdropVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            zIndex: 10
                        }}
                    >
                        <motion.div
                            variants={playerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{
                                maxWidth: '80vw',
                                margin: 'auto',
                                height: '80vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative'
                            }}
                        >
                            <iframe
                                style={{
                                    width: '100%', 
                                    height: '100%', 
                                    position: 'absolute', 
                                    left: 0, 
                                    top: 0, 
                                    overflow: 'hidden'
                                }}
                                frameborder="0"
                                src={videoUrl}
                                width="100%"
                                height="100%"
                                allowFullScreen
                                title="Dailymotion Video Player"
                                allow="autoplay"
                            ></iframe>
                        </motion.div>
                        <center>              
                <Button onClick={toggleCinemaMode} style={{ marginTop: "1rem", marginBottom: '10px' }}>
                    {cinemaMode ? 'Exit Cinema Mode' : 'Enter Cinema Mode'}
                </Button>
            </center>

                    </motion.div>
                )}
                
            </AnimatePresence>
        </div>
    );
}

export default VideoPlayer;
