import React, { useState } from 'react';
import { Box, Container, TextField, MenuItem, Select, InputLabel, FormControl, Button, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import gold from ".././assets/gold.png";
import { Trans } from 'react-i18next';
import lux from '.././assets/lux.png';
import { faFacebook, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'; // Importa axios per le chiamate API

function ContactForm() {
  const { t } = useTranslation();
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [loading, setLoading] = useState(false);
  
  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.5, duration: 1.5 } },
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      type: formData.get('type'),
      message: formData.get('message'),
    };

    emailjs.sendForm('service_dcgrm3j', 'template_wz9rzwp', e.target, '13dztM6_GrAF7gVxX')
      .then((result) => {
        console.log(result.text);
        setLoading(false);
        toast.success(t('MessaggioInviato')); // Usa la traduzione per il messaggio di successo
        incrementRequestCount(data); // Passa tutti i campi
      }, (error) => {
        console.log(error.text);
        setLoading(false);
        toast.error(t('InvioFallito')); // Usa la traduzione per il messaggio di errore
      });
    e.target.reset();
  };

  const incrementRequestCount = async (data) => {
    try {
      await axios.post('/requests/increment', data);
    } catch (error) {
      console.error('Error incrementing request count:', error);
    }
  };

  return (
    <div style={{ marginTop: "3rem",
     padding: '20px', display: "flex", flexDirection: "column", 
     justifyContent: "center", alignItems: "center", borderRadius: '10px' }}>
      <Container style={{
        display: "flex",
        flexDirection: isMobile ? "column" : undefined,
        borderRadius: '0.5rem', 
        padding: "10px",
        background: "linear-gradient(23deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5290908599768032) 50%, rgba(18,18,18,1) 100%)",
        justifyContent: "space-between", alignItems: "center"}}>
        <img src={lux} variants={fadeIn} initial="initial" animate="animate"  alt="lux"
          style={{marginTop: isMobile ? '1rem' : "0rem", width: "300px",}} />
        <span variants={fadeIn} initial="initial" animate="animate"  alt="lux" style={{
          textAlign: isMobile ? 'center': "right", marginTop: isMobile ? "0.4rem" : '0rem',
          fontFamily: "Belanosima", fontStyle: "italic",
          fontSize: isMobile ? '12px' : '18px',
          color: "#B8B3AF"}}>
          <Trans i18nKey="FastResponse" />
        </span>
      </Container>

      <Typography sx={{ marginTop: "1rem", fontSize: '30px', textAlign: isMobile ? 'left' : 'left'}}>
        {t('Contatti')} <FontAwesomeIcon icon={faEnvelope} style={{marginLeft: "10px"}}/>
      </Typography>

    

      <form  onSubmit={sendEmail}>
        <Box sx={{ display: 'flex', width: isMobile ? 'auto' : '600px',
          marginTop: "1rem",  backgroundColor: "#6490bc",
          padding: "30px", borderRadius: "0.5rem",
          flexDirection: 'column', gap: 4 }}>
          <TextField
            label={t('Nome')} // Usa la funzione t per ottenere la traduzione del label
            name="name"
            required
            fullWidth
            InputProps={{
              startAdornment: <FontAwesomeIcon style={{marginRight: "10px"}} icon={faUser} />
            }}
          />
          
          <div style={{display: "flex", gap: "2rem", alignItems: 'center'}}>
            <TextField
              label="Email"
              name="email"
              type="email"
              required
              fullWidth
              InputProps={{
                startAdornment: <FontAwesomeIcon style={{marginRight: "10px"}} icon={faEnvelope} />
              }}
            />
            <TextField
              label={t('Telefono')} // Usa la funzione t per ottenere la traduzione del label
              name="phone"
              type="tel"
              required
              fullWidth
              InputProps={{
                startAdornment: <FontAwesomeIcon style={{marginRight: "10px"}} icon={faPhone} />
              }}
            />
          </div>
          <FormControl fullWidth>
            <InputLabel id="type-label">{t('TipoReq')}</InputLabel>
            <Select
              labelId="type-label"
              label="Tipo di Richiesta"
              name="type"
              required
            >
              <MenuItem value="Elicotteri">Elicotteri</MenuItem>
              <MenuItem value="Supercar">Supercar</MenuItem>
              <MenuItem value="NCC">NCC</MenuItem>
              <MenuItem value="Pubblicità Aerea">Pubblicità Aerea</MenuItem>
              <MenuItem value="Jet Privati">Jet Privati</MenuItem>
              <MenuItem value="Yacht e Barche di Lusso">Yacht e Barche di Lusso</MenuItem>
              <MenuItem value="Security">Security</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={t('Messaggio')} // Usa la funzione t per ottenere la traduzione del label
            name="message"
            required
            multiline
            rows={3}
            fullWidth
          />
          <center>
          <Button type="submit" style={{backgroundColor: "black", width: "200px"}} disabled={loading}>
            {loading ? <Typography style={{fontFamily: "Belanosima", color: "#6490bc", gap: "0.4rem", fontSize: "20px", display: "flex", alignItems: "center"}}>
            <CircularProgress style={{marginLeft: "20px"}} size={24} /> 
            <Trans i18nKey='InvioInCorso'/>
            <CircularProgress  style={{marginRight: "20px"}}  size={24} /> 
            </Typography>: <><FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '10px' }} />{t('Invia')}</>}
          </Button>
          </center>
        </Box>
      </form>
      <ToastContainer />
    </div>
  );
}

export default ContactForm;
