import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Importa useTranslation
import wa from "../../assets/images/wa.gif";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function WhatsAppMessageButton() {
  const { i18n } = useTranslation();
  const phoneNumber = '393762023340';
  
  // Definisci i messaggi per ogni lingua usando i18n
  const messages = {
    it: "Ciao! Vorrei ricevere maggiori informazioni.",
    en: "Hello! I would like more information."
  };

  // Sceglie il messaggio in base alla lingua corrente
  const message = messages[i18n.language.slice(0, 2)] || messages.it; // Fallback alla lingua italiana se non trovato
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <Button
      color="success"
      href={whatsappUrl}
      target="_blank"
      style={{
        fontFamily: "Belanosima",
        color: "white",
        fontSize: '28px' ,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            transform: 'scale(1.1)',
            transition: '0.3s',
        }
      }}
    >
      <FontAwesomeIcon icon={faWhatsapp}/>
    </Button>
  );
}

export default WhatsAppMessageButton;
