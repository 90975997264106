// src/components/MemoTodoList.js
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Typography, Box } from '@mui/material';
import axios from 'axios';
import { useSocket } from '../../context/SocketContext'; // Importa il contesto del socket
import { format } from 'date-fns';
import { it } from 'date-fns/locale'; // Importa la locale italiana
import { motion, AnimatePresence } from 'framer-motion';

const MemoTodoList = () => {
    const [latestMemos, setLatestMemos] = useState([]);
    const [currentMemoIndex, setCurrentMemoIndex] = useState(0);
    const { user } = useContext(AuthContext);
    const socket = useSocket();

    useEffect(() => {
        const fetchLatestMemos = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('/memos', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const sortedMemos = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setLatestMemos(sortedMemos);
            } catch (error) {
                console.error('Error fetching latest memos:', error);
            }
        };

        fetchLatestMemos();

        socket.on('memoUpdate', () => {
            fetchLatestMemos();
        });

        return () => {
            socket.off('memoUpdate');
        };
    }, [socket]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMemoIndex(prevIndex => (prevIndex + 1) % latestMemos.length);
        }, 3000); // Cambia memo ogni 3 secondi

        return () => clearInterval(interval);
    }, [latestMemos]);

    const formatDate = (dateString) => {
        return format(new Date(dateString), "dd MMMM yyyy 'alle' HH:mm", { locale: it });
    };

    return (
        <div style={{ marginTop: "5px" }}>
            <Typography style={{ fontFamily: "Belanosima", color: "#6490bc", fontSize: "18px" }}>
                To-Do
            </Typography>
            
            {latestMemos.length > 0 ? (
                <Box position="relative" height="30px" overflow="hidden">
                    <AnimatePresence>
                        <motion.div
                            key={latestMemos[currentMemoIndex]._id}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            transition={{ duration: 0.5 }}
                            style={{ position: 'absolute', width: '100%' }}
                        >
                            <Typography style={{ fontFamily: "Belanosima", color: "white", fontSize: "16px" }}>
                                • {latestMemos[currentMemoIndex].content} - <span style={{color: 'grey'}}>{formatDate(latestMemos[currentMemoIndex].createdAt)}</span>
                            </Typography>
                        </motion.div>
                    </AnimatePresence>
                </Box>
            ) : (
                <Typography style={{ fontFamily: "Belanosima", color: "grey", fontSize: "16px" }}>
                    - Nessuna memo
                </Typography>
            )}
        </div>
    );
};

export default MemoTodoList;
