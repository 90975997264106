import React from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { width } from '@mui/system';

const TooltipContent = ({ errors }) => {
    return (
        <Box>
            {errors.map((error, index) => (
                <Box style={{borderRadius: "1rem",}} key={index} display="flex" alignItems="center" mb={1}>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '10px', color: '#e74c3c' }} />
                    <Typography variant="body2" style={{ marginTop: "0.4rem", 
                    padding: "5px", 
                    border: "1px solid white",
                         color: 'white' }}>
                        Errore: {error.statusCode} - {error.message}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default TooltipContent;
