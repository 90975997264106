import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
    Button, TextField, Box, Typography, IconButton, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent, CardActions, Grow, useMediaQuery, FormControl, Select, MenuItem, InputLabel,
    InputAdornment
} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete } from '@mui/icons-material';
import { useSocket } from '../../context/SocketContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faClock } from '@fortawesome/free-solid-svg-icons';

const MemoList = ({ searchTerm }) => {
    const [memos, setMemos] = useState([]);
    const [filteredMemos, setFilteredMemos] = useState([]);
    const [editMemo, setEditMemo] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteMemo, setDeleteMemo] = useState(null);
    const [filterStatus, setFilterStatus] = useState('');
    const { user } = useContext(AuthContext);
    const socket = useSocket();
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    useEffect(() => {
        const fetchMemos = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('/memos', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setMemos(response.data);
                setFilteredMemos(response.data);
            } catch (error) {
                console.error('Error fetching memos:', error);
            }
        };

        fetchMemos();

        socket.on('memoUpdate', (data) => {
            switch (data.type) {
                case 'create':
                    setMemos((prevMemos) => [data.memo, ...prevMemos]);
                    setFilteredMemos((prevMemos) => [data.memo, ...prevMemos]);
                    break;
                case 'update':
                    setMemos((prevMemos) => prevMemos.map((memo) => memo._id === data.memo._id ? data.memo : memo));
                    setFilteredMemos((prevMemos) => prevMemos.map((memo) => memo._id === data.memo._id ? data.memo : memo));
                    break;
                case 'delete':
                    setMemos((prevMemos) => prevMemos.filter((memo) => memo._id !== data.memo._id));
                    setFilteredMemos((prevMemos) => prevMemos.filter((memo) => memo._id !== data.memo._id));
                    break;
                default:
                    break;
            }
        });

        return () => {
            socket.off('memoUpdate');
        };
    }, [socket]);

    useEffect(() => {
        let filtered = memos.filter(memo =>
            memo.content.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (filterStatus) {
            filtered = filtered.filter(memo => memo.status === filterStatus);
        }

        setFilteredMemos(filtered);
    }, [searchTerm, memos, filterStatus]);

    const handleEditMemo = (memo) => {
        setEditMemo(memo);
    };

    const handleUpdateMemo = async () => {
        if (!editMemo || editMemo.content.trim() === '') {
            toast.error('La Memo non deve essere vuota!');
            return;
        }

        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.put(`/memos/${editMemo._id}`, { content: editMemo.content, status: editMemo.status }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMemos(memos.map(m => (m._id === editMemo._id ? response.data : m)));
            setFilteredMemos(filteredMemos.map(m => (m._id === editMemo._id ? response.data : m)));
            setEditMemo(null);
            toast.success('Hai aggiornato una Memo!');
        } catch (error) {
            toast.error('Errore durante l\'aggiornamento della memo.');
        }
    };

    const handleDeleteMemo = async () => {
        if (!deleteMemo) return;

        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`/memos/${deleteMemo._id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMemos(memos.filter(m => m._id !== deleteMemo._id));
            setFilteredMemos(filteredMemos.filter(m => m._id !== deleteMemo._id));
            setOpenDeleteDialog(false);
            setDeleteMemo(null);
            toast.success('Hai eliminato una Memo!');
        } catch (error) {
            toast.error('Errore durante l\'eliminazione della memo.');
        }
    };

    const handleOpenDeleteDialog = (memo) => {
        setDeleteMemo(memo);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setDeleteMemo(null);
    };

    const handleStatusChange = async (id, status) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.put(`/memos/${id}`, { status }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMemos(memos.map(memo => memo._id === id ? response.data : memo));
            setFilteredMemos(filteredMemos.map(memo => memo._id === id ? response.data : memo));
            toast.success('Hai aggiornato lo stato della Memo!');
        } catch (error) {
            toast.error('Errore durante l\'aggiornamento dello stato della memo.');
        }
    };

    return (
        <Box style={{ width: "100%", 
            padding: '5px', borderRadius: '0.5rem' }}>
<div style={{display: "flex",  alignItems: "center", justifyContent: "space-between"}}>
<Typography style={{ fontFamily: "Belanosima", color: "white", fontSize: '18px' }}>
                    Numero di memo: {filteredMemos.length}
                </Typography>
                <FormControl variant="outlined" size="small" style={{ minWidth: "150px" }}>
                    <InputLabel style={{ color: 'white' }}>Filtra per stato</InputLabel>
                    <Select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        label="Filtra per stato"
                        style={{ width: "120px", color: 'white' }}
                    >
                        <MenuItem value=""><em>Tutti</em></MenuItem>
                        <MenuItem value="Da fare">Da fare</MenuItem>
                        <MenuItem value="In Progress">In progress</MenuItem>
                        <MenuItem value="Check">Check</MenuItem>
                        <MenuItem value="Fatta">Fatta</MenuItem>
                    </Select>
                </FormControl>
            </div>
        <div style={{
         maxHeight: 'calc(100vh - 340px)', overflow: 'auto' ,
       display: 'flex'}}>
           
            <Box display="flex" flexWrap="wrap" gap={2}  marginTop='2rem' justifyContent="center">
                {filteredMemos.map(memo => {
                    const formattedDate = new Date(memo.createdAt).toLocaleDateString('it-IT', {
                        day: 'numeric',
                        month: '2-digit',
                        year: '2-digit',
                    });
                    return (
                        <Grow in key={memo._id}>
                            <Card style={{ backgroundColor: '#FFFF49',
                                
                                borderRadius: "0rem",  width: isMobile ? '100%' : '280px', margin: isMobile ? '10px 0' : '0px', position: 'relative' }}>
                                <CardContent>
                                    {editMemo && editMemo._id === memo._id ? (
                                        <TextField
                                            value={editMemo.content}
                                            onChange={(e) => setEditMemo({ ...editMemo, content: e.target.value })}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    ) : (
                                        <Typography style={{ fontSize: '18px', fontFamily: "Belanosima", color: "black", whiteSpace: 'pre-wrap' }}>{memo.content}</Typography>
                                    )}
                                    <Typography style={{ fontSize: '14px', fontFamily: "Belanosima", color: "grey", whiteSpace: 'pre-wrap' }}>
                                        aggiunta da: {memo.addedBy}</Typography>
                                    {editMemo && editMemo._id === memo._id && (
                                        <FormControl variant="outlined" size="small" fullWidth style={{ marginTop: '10px' }}>
                                            <InputLabel>Stato</InputLabel>
                                            <Select
                                                value={editMemo.status}
                                                onChange={(e) => setEditMemo({ ...editMemo, status: e.target.value })}
                                                label="Stato"
                                            >
                                                <MenuItem value="Da fare">Da fare</MenuItem>
                                                <MenuItem value="In Progress">In progress</MenuItem>
                                                <MenuItem value="Check">Check</MenuItem>
                                                <MenuItem value="Fatta">Fatta</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                </CardContent>
                                <CardActions style={{ justifyContent: 'space-between' }}>
                                    <Typography style={{ fontFamily: "Belanosima", display: "flex", alignItems: "center", fontSize: "14px", color: "grey" }}>
                                        <FontAwesomeIcon icon={faClock} style={{ marginRight: "4px" }} />   {formattedDate}
                                    </Typography>
                                    <div style={{ display: "flex", alignItems: "center"}}>
                                        <IconButton onClick={() => handleEditMemo(memo)}>
                                            <Edit style={{ fontSize: "14px", color: "grey" }} />
                                        </IconButton>
                                        <IconButton onClick={() => handleOpenDeleteDialog(memo)}>
                                            <Delete style={{ fontSize: "14px", color: "#e74c3c" }} />
                                        </IconButton>
                                        <FormControl variant="outlined" size="small">
    <Select
        value={memo.status}
        onChange={(e) => handleStatusChange(memo._id, e.target.value)}
        displayEmpty
        inputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon style={{ color: "#e74c3c" }} icon={faArrowDown} />    
                </InputAdornment>
            )
        }}
        
    >
        <MenuItem value="Da fare">Da fare</MenuItem>
        <MenuItem value="In Progress">In progress</MenuItem>
        <MenuItem value="Check">Check</MenuItem>
        <MenuItem value="Fatta">Fatta</MenuItem>
    </Select>
</FormControl>

                                    </div>
                                </CardActions>
                            </Card>
                        </Grow>
                    );
                })}
            </Box>
            {editMemo && (
                <Button variant="contained" color="primary" onClick={handleUpdateMemo} style={{ marginTop: '10px' }}>
                    Aggiorna Memo
                </Button>
            )}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Elimina Memo</DialogTitle>
                <DialogContent>
                    <DialogContentText>Sei sicuro di voler eliminare questa memo?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">Annulla</Button>
                    <Button onClick={handleDeleteMemo} color="secondary">Elimina</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </div>
        </Box>
    );
};

export default MemoList;
