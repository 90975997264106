// src/components/RequestList.js

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, MenuItem, Select, InputLabel, FormControl, useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { motion } from 'framer-motion';
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEnvelope, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import RequestForm from './RequestForm';

const RequestList = ({ searchTerm }) => {
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const [openRequestForm, setOpenRequestForm] = useState(false);
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('/requests', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRequests(response.data);
                setFilteredRequests(response.data);
            } catch (error) {
                console.error('Error fetching requests:', error);
            }
        };

        fetchRequests();

        const socket = io('https://api-lar-014fd3aff401.herokuapp.com');

        socket.on('requestUpdate', (data) => {
            switch (data.type) {
                case 'create':
                    setRequests((prevRequests) => [data.request, ...prevRequests]);
                    setFilteredRequests((prevRequests) => [data.request, ...prevRequests]);
                    break;
                case 'update':
                    setRequests((prevRequests) =>
                        prevRequests.map((request) =>
                            request._id === data.request._id ? data.request : request
                        )
                    );
                    setFilteredRequests((prevRequests) =>
                        prevRequests.map((request) =>
                            request._id === data.request._id ? data.request : request
                        )
                    );
                    break;
                case 'delete':
                    setRequests((prevRequests) =>
                        prevRequests.filter((request) => request._id !== data.request._id)
                    );
                    setFilteredRequests((prevRequests) =>
                        prevRequests.filter((request) => request._id !== data.request._id)
                    );
                    break;
                default:
                    break;
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        let filtered = requests;
        if (searchTerm) {
            filtered = filtered.filter(request =>
                request.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                request.phone.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        if (filterType) {
            filtered = filtered.filter(request => request.type === filterType);
        }
        if (filterStatus) {
            filtered = filtered.filter(request => request.status === filterStatus);
        }
        setFilteredRequests(filtered);
    }, [searchTerm, filterType, filterStatus, requests]);

    const handleStatusChange = async (id, status) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.put(`/requests/${id}/status`, { status }, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            console.error('Error updating request status:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`/requests/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setRequests(requests.filter(request => request._id !== id));
            setFilteredRequests(filteredRequests.filter(request => request._id !== id));
            setOpenConfirmDialog(false);
            setSelectedRequestId(null);
        } catch (error) {
            console.error('Error deleting request:', error);
        }
    };

    const openConfirmDialogHandler = (id) => {
        setSelectedRequestId(id);
        setOpenConfirmDialog(true);
    };

    const closeConfirmDialogHandler = () => {
        setOpenConfirmDialog(false);
        setSelectedRequestId(null);
    };

    const handleOpenRequestForm = () => {
        setOpenRequestForm(true);
    };

    const handleCloseRequestForm = () => {
        setOpenRequestForm(false);
    };

    const handleRequestAdded = (newRequest) => {
        setRequests([newRequest, ...requests]);
        setFilteredRequests([newRequest, ...filteredRequests]);
    };

    return (
        <Box style={{ width: "100%", padding: '5px', borderRadius: '0.5rem' }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography style={{ fontFamily: "Belanosima", fontSize: "18px" }}>
                    Numero di richieste: {requests.length}
                </Typography>
                <Box style={{ display: "flex", gap: "0.8rem", alignItems: isMobile ? 'flex-start' : 'center', }}>
                    <FormControl variant="outlined" size="small" style={{ width: "70px" }}>
                        <InputLabel>Tipo</InputLabel>
                        <Select
                            value={filterType}
                            onChange={(e) => setFilterType(e.target.value)}
                            label="Filtra per tipo"
                            style={{ color: 'white' }}
                        >
                            <MenuItem value=""><em>Tutti</em></MenuItem>
                            <MenuItem value="Elicotteri">Elicotteri</MenuItem>
                            <MenuItem value="Supercar">Supercar</MenuItem>
                            <MenuItem value="NCC">NCC</MenuItem>
                            <MenuItem value="Jet Privati">Jet Privati</MenuItem>
                            <MenuItem value="Yacht e Barche di Lusso">Yacht e Barche di Lusso</MenuItem>
                            <MenuItem value="Security">Security</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl size='small' style={{ width: "75px" }}>
                        <InputLabel>Stato</InputLabel>
                        <Select
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                            label="Filtra per stato"
                            style={{ color: 'white' }}
                        >
                            <MenuItem value=""><em>Tutti</em></MenuItem>
                            <MenuItem value="in attesa">In attesa</MenuItem>
                            <MenuItem value="attiva">Attiva</MenuItem>
                            <MenuItem value="completa">Completa</MenuItem>
                            <MenuItem value="archiviata">Archiviata</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenRequestForm}
                        style={{ color: "white", backgroundColor: "#007BFF" }}
                    >
                        Nuova Richiesta
                    </Button>
                </Box>
            </div>
            <Box style={{ maxHeight: 'calc(100vh - 270px)', marginTop: "2rem", overflow: 'auto' }}>
                {filteredRequests.map((request) => (
                    <motion.div
                        key={request._id}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ backgroundColor: 'black', boxShadow: '0 4px 8px rgba(255,255,255,0.3)', padding: '20px', borderRadius: "0.5rem", marginBottom: '15px', }}
                    >
                        <Box style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center', justifyContent: "space-between" }}>
                            <Box style={{ display: "flex", width: "100%", alignItems: isMobile ? 'center' : 'center' }}>
                                <Typography style={{ color: '#6490bc', fontFamily: 'Belanosima' }}>
                                    ╰ {request.name}
                                </Typography>
                                <Typography style={{ color: 'white', marginLeft: isMobile ? '10px' : "12px", fontSize: "18px", fontFamily: 'Belanosima' }}>
                                    per {request.type}
                                </Typography>
                            </Box>
                            <div style={{ display: "flex", marginTop: isMobile ? '20px' : '0', justifyContent: 'space-between', width: "100%", alignItems: "center" }}>
                                <FormControl variant="outlined" size="small" style={{ minWidth: "150px", }}>
                                    <InputLabel>Stato</InputLabel>
                                    <Select
                                        value={request.status}
                                        onChange={(e) => handleStatusChange(request._id, e.target.value)}
                                        label="Stato"
                                        style={{ color: 'white' }}
                                    >
                                        <MenuItem value="in attesa">In attesa</MenuItem>
                                        <MenuItem value="attiva">Attiva</MenuItem>
                                        <MenuItem value="completa">Completa</MenuItem>
                                        <MenuItem value="archiviata">Archiviata</MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography style={{ color: '#B8B3AF', alignItems: "center", textAlign: isMobile ? 'center' : undefined, display: "flex", flexDirection: "column", fontSize: "15px", fontFamily: 'Belanosima', whiteSpace: 'pre-line', }}>
                                    <span style={{ color: "grey", fontSize: "12px" }}>Ricevuta il</span>
                                    {format(new Date(request.createdAt), "dd/MM/yy '-' HH:mm", { locale: it })}
                                </Typography>
                            </div>
                        </Box>

                        <Box style={{ display: "flex", flexDirection: "column", marginTop: '10px' }}>
                            <Typography style={{ color: 'grey', fontSize: "16px", fontFamily: 'Belanosima' }}>
                                ha chiesto:
                            </Typography>
                            <Typography style={{ color: 'white', fontSize: "20px", fontFamily: 'Belanosima' }}>
                                {request.message}
                            </Typography>

                            <Box style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', alignItems: "center", justifyContent: 'space-between', marginTop: "0.8rem" }}>
                                <Box style={{ display: "flex", justifyContent: isMobile ? "space-between" : 'flex-start', width: "100%", alignItems: "center", gap: "0.5rem", }}>
                                    <Typography style={{ color: '#B8B3AF', display: "flex", alignItems: "center", fontSize: "15px", fontFamily: 'Belanosima' }}>
                                        <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: '10px', color: 'white', marginRight: "5px" }} />
                                        <a href={`mailto:${request.email}`} style={{ marginLeft: '5px', color: 'white' }}>{request.email} </a>
                                    </Typography>
                                    <span style={{ color: "white", display: isMobile ? 'none' : 'block' }}>
                                        -
                                    </span>
                                    <Typography style={{ color: '#B8B3AF', display: "flex", alignItems: "center", fontSize: "15px", fontFamily: 'Belanosima' }}>
                                        <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#6490bc', marginRight: "5px" }} />
                                        <span style={{ color: "white", textDecoration: 'underline', }}>{request.phone}</span>
                                        <a href={`https://wa.me/${request.phone}`} style={{ marginLeft: '5px', color: '#25D366' }}>
                                        </a>
                                    </Typography>
                                </Box>
                                <Link to={`/richieste/${request._id}`}>
                                    <Button
                                        color="primary"
                                        style={{ backgroundColor: "#007bff", color: "white", marginLeft: isMobile ? '0' : '10px', marginTop: isMobile ? '10px' : '0' }}
                                    >
                                        Dettagli
                                    </Button>
                                </Link>
                                <Button
                                    color="secondary"
                                    onClick={() => openConfirmDialogHandler(request._id)}
                                    style={{ backgroundColor: "#e74c3c", color: "white", marginLeft: isMobile ? '0' : '10px', marginTop: isMobile ? '10px' : '0' }}
                                >
                                    Elimina
                                </Button>
                            </Box>
                        </Box>
                    </motion.div>
                ))}
            </Box>
            <Dialog
                open={openConfirmDialog}
                onClose={closeConfirmDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Conferma Eliminazione</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sei sicuro di voler eliminare questa richiesta? Questa azione non può essere annullata.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDialogHandler} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={() => handleDelete(selectedRequestId)} color="secondary" autoFocus>
                        Elimina
                    </Button>
                </DialogActions>
            </Dialog>
            <RequestForm open={openRequestForm} onClose={handleCloseRequestForm} onRequestAdded={handleRequestAdded} />
        </Box>
    );
};

export default RequestList;
