// src/components/MemoForm.js
import React, { useState, useContext } from 'react';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, useMediaQuery, Typography } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const MemoForm = () => {
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('Da fare');
    const { user } = useContext(AuthContext);
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!content) {
            toast.error('La Memo non deve essere vuota!');
            return;
        }

        try {
            const token = localStorage.getItem('authToken');
            await axios.post('/memos', { content, addedBy: user.username, status }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setContent('');
            setStatus('Da fare');
            toast.success('Hai creato una Memo');
        } catch (error) {
            toast.error('Errore durante la creazione della memo.');
        }
    };

    return (
        <div component="form" onSubmit={handleSubmit} style={{ padding: "10px",
            
            display: "flex", flexDirection: "column", justifyContent: "center",
         borderRadius: "0.5rem" }}>
                <Typography style={{ fontFamily: "Belanosima",
                     textAlign: "left",  color: "white", fontSize: '18px' }}>
            Aggiungi Memo <FontAwesomeIcon style={{ fontWeight: "bold", marginLeft: "10px" }} icon={faPenToSquare} />
        </Typography>
            <TextField
                label="Scrivi la Task.."
                value={content}
                multiline
                rows={3}
                onChange={(e) => setContent(e.target.value)}
                fullWidth
                style={{ 
                 marginBottom: "1.5rem", marginTop: "0.3rem"}}
            />
            <FormControl variant="outlined" fullWidth style={{ marginBottom: "1rem" }}>
                <InputLabel>Stato</InputLabel>
                <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Stato"
                >
                    <MenuItem value="Da fare">Da fare</MenuItem>
                    <MenuItem value="In Progress">In progress</MenuItem>
                    <MenuItem value="Check">Check</MenuItem>
                    <MenuItem value="Fatta">Fatta</MenuItem>
                </Select>
            </FormControl>
            <center>
            <Button type="submit" onClick={handleSubmit} style={{backgroundColor: "black", 
            width: "200px"}} 
            >
            <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: "10px"}}/>
                Crea Memo <FontAwesomeIcon icon={faCheckCircle} style={{marginLeft: "10px"}}/>
            </Button>
            </center>
            <ToastContainer />
        </div>
    );
};

export default MemoForm;
