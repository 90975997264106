import React from 'react';
import { Container, Typography, Box, useMediaQuery } from '@mui/material';
import lux from '../assets/lux.png';

const PrivacyPolicy = () => {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    return (
        <div style={{display: "flex", marginTop: "5rem", flexDirection: 'column', alignItems: "center", 
            justifyContent: "center"}}>
                
                <img src={lux} alt='Luxury' style={{ width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>

        <Container>
            <Box mt={5}>
                <Typography variant="h4" gutterBottom>
                    Politica sulla Privacy
                </Typography>
                <Typography paragraph>
                    La tua privacy è importante per noi. Utilizziamo i tuoi dati personali solo per rispondere alle tue richieste e non li condividiamo con terze parti. 
                    <br/> <br/>I tuoi dati sono protetti e non sono esposti in alcun modo ad Internet al di fuori dei nostri sistemi.
                </Typography>
                <Typography paragraph>
                    Per maggiori informazioni su come gestiamo i tuoi dati, puoi contattarci direttamente.
                </Typography>
            </Box>
        </Container>
        </div>
    );
};

export default PrivacyPolicy;
