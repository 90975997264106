import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const login = async (username, password) => {
        try {
            const response = await axios.post('/auth/login', { username, password });
            localStorage.setItem('authToken', response.data.token);
            await checkAuth();
            navigate('/admin/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const register = async (username, password) => {
        try {
            await axios.post('/auth/register', { username, password });
            await login(username, password);
            navigate('/admin/dashboard');
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('authToken');
        navigate('/admin/access');
    };

    const checkAuth = async () => {
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                const response = await axios.get('/auth/checkAuth', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUser(response.data.user);
            } catch (error) {
                setUser(null);
                console.error('Check auth failed:', error);
            }
        } else {
            setUser(null);
        }
        setLoading(false);
    };

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, register, logout, loading, checkAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
