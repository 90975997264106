import React from 'react'
import { Helmet } from 'react-helmet';
import i18n from '../../../../lang/i18n';
import { Trans, useTranslation } from 'react-i18next';
import lux from "../../../../assets/lux.png";
import gold from "../../../../assets/gold.png";
import { useMediaQuery } from '@mui/system';
import { Typography } from '@mui/material';
import pgiorno from "../../../../assets/images/gallery/pgiorno.jpg";
import pilot from "../../../../assets/images/tour/7.png";
import sea from "../../../../assets/images/tour/3.JPG";
import pi2 from "../../../../assets/images/tour/5.png";

function PilotaGiorno() {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const { t } = useTranslation();
    
    return (
        <div style={{
            marginTop: "6rem",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column"
        }}>
            <Helmet>
    <title>{t('MetaPilotaGiornoTitle')}</title>
    <meta name="description" content={t('MetaPilotaGiornoDesc')} />
    <meta name="keywords" content="tour di lusso Roma, elicottero Roma, noleggio supercar Roma, esperienze esclusive Roma, tour esclusivi Roma, jet privato Roma, vacanze di lusso Roma"></meta>
    <meta name="author" content="Luxury Agency Rome" />
    <meta name="publisher" content="Luxury Agency Rome" />
    <meta property="og:title" content={t('MetaPilotaGiornoTitle')} />
    <meta property="og:description" content={t('MetaPilotaGiornoDesc')} />
    <meta property="og:url" content="https://www.luxuryagencyrome.it/pilota-per-un-giorno" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.luxuryagencyrome.it/pilota-per-un-giorno" />
    <html lang={i18n.language} />
</Helmet>
            {/* Immagini banner */}
            <img src={lux} alt='gold' style={{ width: isMobile ? '100%' : "500px", objectFit: 'contain'}} />
            <img src={gold} alt='gold' style={{ marginTop: "1rem", width: isMobile ? '100%' : "500px", objectFit: 'contain'}} />
  


            <div 
            style={{width: isMobile ? '100%' : "100%", display: "flex", flexDirection: "column",
                height: isMobile ? 'auto' : "320px",
            
            alignItems: "center", justifyContent: "center", marginTop: "0.5rem",
            padding: "30px", 
            background: `linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url(${pgiorno}) ${isMobile ? 'center' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`,}}>
           
           <Typography sx={{ fontSize: isMobile ? '20px' : '30px', marginTop: "2rem", textAlign: "center"}}>
                {t('PilotaGiornoTitle')}
            </Typography>
            <Typography sx={{ fontSize: isMobile ? '20px' : '30px', marginTop: "2rem", textAlign: "center"}}>

<Trans i18nKey='PilotaGiornoDesc1'/>
</Typography>
            </div>

        
                <div style={{display: 'flex', width: isMobile ? '100%' : undefined, flexDirection: "column"}}>

                <Typography sx={{ fontSize: isMobile ? '16px' : '20px', marginTop: "2rem", textAlign: "center"}}>

<Trans i18nKey='PilotaGiornoDesc2'/>
</Typography>

<div style={{display: "flex", flexDirection: isMobile  ? 'column' : undefined, gap: "2rem",  marginTop: "2rem", alignItems: 'center'}}>
<img src={pilot} alt='gold' style={{ height: "200px", borderRadius: "0.5rem",  objectFit: 'contain'}} />

<img src={sea} alt='gold' style={{height: "200px", borderRadius: "0.5rem", bjectFit: 'contain'}} />
</div>
<Typography sx={{ fontSize: isMobile ? '16px' : '20px', marginTop: "2rem", textAlign: "center"}}>

<Trans i18nKey='PilotaGiornoDesc3'/>
</Typography>

<img src={pi2} alt='gold' style={{height: isMobile ? 'auto' : "350px", borderRadius: "0.5rem", bjectFit: 'contain'}} />


<Typography sx={{ fontSize: isMobile ? '16px' : '20px', marginTop: "2rem", textAlign: "center"}}>

<Trans i18nKey='PilotaGiornoDesc4'/>
</Typography>

                </div>


  </div>
  )
}

export default PilotaGiorno;