import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import EmailThread from './EmailThread';
import '../../theme/Email.css';

const EmailList = ({ searchTerm }) => {
    const [emailThreads, setEmailThreads] = useState([]);

    useEffect(() => {
        const fetchEmails = async () => {
            try {
                const response = await axios.get('/emails', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                const emails = response.data;
                const groupedEmails = emails.reduce((acc, email) => {
                    const { from } = email;
                    if (!acc[from]) {
                        acc[from] = [];
                    }
                    acc[from].push(email);
                    return acc;
                }, {});

                const threads = Object.keys(groupedEmails).map(from => ({
                    from,
                    emails: groupedEmails[from]
                }));

                setEmailThreads(threads);
            } catch (error) {
                console.error('Error fetching emails:', error);
            }
        };

        fetchEmails();
    }, []);

    return (
        <Box style={{ width: "100%",
            padding: '5px', borderRadius: '0.5rem' }}>
            <Typography variant="h5" style={{fontFamily: "Belanosima", marginBottom: "1rem"}} className="email-title">
                Casella Email: info@luxuryagencyrome.it
            </Typography>
            {emailThreads.filter(thread => 
                thread.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
                thread.emails.some(email => email.subject.toLowerCase().includes(searchTerm.toLowerCase()))
            ).map((thread, index) => (
                <EmailThread key={index} thread={thread} />
            ))}
        </Box>
    );
};

export default EmailList;
