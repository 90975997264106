import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Container, Tab, Tabs, TextField, Button, useMediaQuery, Typography } from '@mui/material';
import lux from "../assets/lux.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUserTie } from '@fortawesome/free-solid-svg-icons';

const Access = () => {
    const { login, register } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState('login');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (activeTab === 'login') {
            await login(username, password);
        } else {
            await register(username, password);
        }
    };

    return (
        <div style={{display: "flex", marginTop: "5rem", marginBottom: "2rem", flexDirection: 'column', alignItems: "center", 
            justifyContent: "center"}}>
                            <img src={lux} alt='Luxury' style={{ width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>

                <Typography style={{fontSize: "30px", marginTop: "2rem", color: "white", fontFamily: "Belanosima"}}>
                <FontAwesomeIcon icon={faLock} style={{marginRight: "10px"}} /> ACCESSO ADMIN <FontAwesomeIcon icon={faUserTie} style={{marginLeft: "10px"}} />
                </Typography>

        <Container style={{width: isMobile ? 'auto' : "500px", border: "1px solid white",
        borderRadius: "1rem",
            padding: "30px", boxShadow: "0px 0px 20px 0px white", marginTop: "2rem"}}>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab style={{backgroundColor: '#6490bc',fontWeight: 'bolder', color: "black"}} label="Login" value="login" />
                <Tab style={{backgroundColor: '#6490bc',fontWeight: 'bolder', color: "black"}} label="Register" value="register" />
            </Tabs>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    {activeTab === 'login' ? 'Login' : 'Register'}
                </Button>
            </form>
        </Container>
        </div>
    );
};

export default Access;
