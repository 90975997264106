import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ArrowBack } from '@mui/icons-material';
import { Trans } from 'react-i18next';

function BackButton() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (location.pathname !== '/') {
      navigate(-1); // -1 indica "indietro" nella history
    }
  };

  return (
    <div style={{ position: 'fixed', display: "flex", bottom: 10, right: 20, zIndex: 1000 }}>
      {location.pathname !== '/' && (
        <Button
          onClick={handleClick}
          startIcon={<ArrowBack style={{color: "#6490bc"}} />}
          sx={{ borderRadius: '1rem', border: '1px solid grey',  color: "#6490bc", backgroundColor: "black"}}
        ><Trans i18nKey='Back'/> </Button>
      )}
    </div>
  );
}

export default BackButton;
