import React from 'react'
import lux from '../../../assets/lux.png';
import { Typography } from '@mui/material';
import { color, fontSize, useMediaQuery } from '@mui/system';
import { Helmet } from 'react-helmet';
import i18n from '../../../lang/i18n';
import { t } from 'i18next';

function Security() {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  return (
    <div style={{display: 'flex', marginTop: isMobile ?  "4rem" : '0rem',
      alignItems: 'center', justifyContent: "center", height: '75vh',
      flexDirection: 'column'}}> 

<Helmet>
            <title>Security - L.A.R.</title>
            <meta name="description" content='Body guard and Security Services' />
            <meta name="author" content="Luxury Agency Rome" />
            <meta name="publisher" content="Luxury Agency Rome" />
            <meta property="og:title" content='Security - L.A.R.' />
            <meta property="og:description" content='Body guard and Security Services' />
            <meta property="og:url" content="https://www.luxuryagencyrome.it/security" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://www.luxuryagencyrome.it/security" />
            <html lang={i18n.language} />
          </Helmet>
  
  <img src={lux} alt="lux" style={{marginTop: isMobile ? '1.5rem' : "0rem", width: "300px"}} />
            
            <Typography style={{
              marginTop: "3rem",
              fontSize: "30px", color: "white"
              }}>

                  Work in Progress

              </Typography>
            
            </div>
  )
}

export default  Security;
