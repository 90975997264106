import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faLocationDot, faVideo } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography, Button, Container, useMediaQuery, Card, CardMedia, CardContent } from '@mui/material';
import { useState } from 'react';
import gold from "../../../assets/gold.png";
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import lux from "../../../assets/lux.png";
import economyImage from '../../../assets/images/heli5.jpg';
import premiumImage from '../../../assets/images/heli7.jpg';
import luxuryImage from '../../../assets/images/heliview.jpg';
import transfer from "../../../assets/images/transfer.jpg";
import baita1 from "../../../assets/images/pranzo/baita1.jpg";
import baita2 from "../../../assets/images/pranzo/baita2.jpg";
import baita3 from "../../../assets/images/pranzo/baita3.jpg";
import paliano1 from "../../../assets/images/pranzo/paliano1.jpeg";
import paliano2 from "../../../assets/images/pranzo/paliano2.jpg";
import paliano3 from "../../../assets/images/pranzo/paliano3.jpg";
import paliano4 from "../../../assets/images/pranzo/paliano4.jpg";
import ponzano1 from "../../../assets/images/pranzo/ponzano1.jpg";
import ponzano2 from "../../../assets/images/pranzo/ponzano2.jpg";
import campo1 from "../../../assets/images/pranzo/campo1.jpg";
import campo2 from "../../../assets/images/pranzo/campo2.jpg";
import campo3 from "../../../assets/images/pranzo/campo3.jpg";
import driver from "../../../assets/images/driver.jpg";
import videomaker from "../../../assets/images/videomaker.jpg";
import avv2 from "../../../assets/images/avventura/3.jpg";
import eco1 from "../../../assets/images/tour/1.JPG";
import eco2 from "../../../assets/images/tour/2.png";
import premium1 from "../../../assets/images/tour/3.JPG";
import premium2 from "../../../assets/images/tour/4.png";
import premium3 from "../../../assets/images/tour/5.png";
import lux1 from "../../../assets/images/tour/6.JPG";
import lux2 from "../../../assets/images/tour/7.png";
import lux3 from "../../../assets/images/tour/8.png";
import oasi1 from "../../../assets/images/pranzo/oasi1.jpg";
import oasi2 from "../../../assets/images/pranzo/oasi2.jpg";
import oasi3 from "../../../assets/images/pranzo/oasi3.jpg";
import intro from "../../../assets/images/pranzo/paliano2.jpg";
import cav1 from "../../../assets/images/avventura/cav1.jpg";
import monte from "../../../assets/images/avventura/monte.jpg";
import pool from "../../../assets/images/avventura/pool.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import banner from "../../../assets/images/video/banner.webm";
import { faPagelines } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import i18n from '../../../lang/i18n';


function TourPersonalizzati() {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const { t } = useTranslation();

    const cardVariants = {
        offscreen: {
            y: 50,
            opacity: 0
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    };

    const flightWithoutLunch = [
      {
        title: t('Economy'),
        durata: '~30 min',
        linkpkg: "/elicotteri/tour/economy",
        desc: t('EconomyPackageDetails.description'),
        transfer: t('EconomyPackageDetails.transfer'),
        pic: `${economyImage}`,
        img: [eco1, eco2], // Immagini multiple per ogni categoria
        plus: t('EconomyPackageDetails.transfer'),
        plus2: t('EconomyPackageDetails.video')
      },
      {
        title: t('Premium'),
        durata: '~45 min',
        linkpkg: "/elicotteri/tour/premium",

        desc: t('PremiumPackageDetails.description'),
        transfer: t('PremiumPackageDetails.transfer'),
        pic: `${premiumImage}`,
        img: [premium1, premium2, premium3], // Immagini multiple
        plus: t('PremiumPackageDetails.transfer'),
        plus2: t('PremiumPackageDetails.video')
      },
      {
        title: t('Luxury'),
        durata: t('LuxuryPackageDetails.flightDuration'),
        linkpkg: "/elicotteri/tour/luxury",

        desc: t('LuxuryPackageDetails.description'),
        transfer: t('LuxuryPackageDetails.transfer'),
        pic: `${luxuryImage}`,
        img: [lux1, lux2, lux3], // Immagini multiple
        plus: t('LuxuryPackageDetails.transfer'),
        plus2: t('LuxuryPackageDetails.video')
      }
    ];
    

    const flightWithLunch = [
        {
            title: t('VoloConPranzoDetails.VoloConPranzo1'),
            desc: t('VoloConPranzoDetails.descriptionVolo1'),
            img: [`${baita1}`,`${baita2}`, `${baita3}` ],
            linkpkg: "/elicotteri/tour/baita-saracinesco"

        },
        {
            title: t('VoloConPranzoDetails.VoloConPranzo2'),
            desc: t('VoloConPranzoDetails.descriptionVolo2'),
            img: [`${paliano1}`,`${paliano2}`, `${paliano3}`, `${paliano4}` ],
            linkpkg: "/elicotteri/tour/paliano"
        },
        {
            title: t('VoloConPranzoDetails.VoloConPranzo3'),
            desc: t('VoloConPranzoDetails.descriptionVolo3'),
            img: [ `${ponzano1}`,  `${ponzano2}`],
            linkpkg: "/elicotteri/tour/ponzano"
        },
        {
            title: t('VoloConPranzoDetails.VoloConPranzo4'),
            desc: t('VoloConPranzoDetails.descriptionVolo4'),
            img: [ `${oasi1}`,  `${oasi2}`,  `${oasi3}`],
            linkpkg: "/elicotteri/tour/vescovio"
        },
        {
            title: t('VoloConPranzoDetails.VoloConPranzo5'),
            desc: t('VoloConPranzoDetails.descriptionVolo5'),
            img: [ `${campo1}`,  `${campo2}`,  `${campo3}`],
            linkpkg: "/elicotteri/tour/campotosto"
        }
    ];

    const flightAdventure = [
        {
            title: t('VoloAvventura'),
            desc: t('VoloAvventuraDetails.desc'),
            img: [`${avv2}`,`${monte}`, `${pool}` ],
            linkpkg: "/elicotteri/tour/avventura",
        }
        
    ];

    return (
        <div style={{
            marginTop: "6rem",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column"
        }}>
            <Helmet>
    <title>{t('MetaTourTitle')}</title>
    <meta name="description" content={t('MetaTourDesc')} />
    <meta name="keywords" content="tour di lusso Roma,  elicottero roma, luxury agency rome,  giro in elicottero Roma, noleggio supercar Roma, esperienze esclusive Roma, tour esclusivi Roma, jet privato Roma, vacanze di lusso Roma"></meta>
    <meta name="author" content="Luxury Agency Rome" />
    <meta name="publisher" content="Luxury Agency Rome" />
    <meta property="og:title" content={t('MetaTourTitle')} />
    <meta property="og:description" content={t('MetaTourDesc')} />
    <meta property="og:url" content="https://www.luxuryagencyrome.it/tour" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.luxuryagencyrome.it/tour" />
    <html lang={i18n.language} />
</Helmet>
            {/* Immagini banner */}
            <img src={lux} alt='gold' style={{ width: isMobile ? '100%' : "500px", objectFit: 'contain'}} />
            <img src={gold} alt='gold' style={{ marginTop: "1rem", width: isMobile ? '100%' : "500px", objectFit: 'contain'}} />


            <div 
            style={{width: isMobile ? 'auto' : "100%", display: "flex", flexDirection: "column",       
            alignItems: "center", justifyContent: "center", marginTop: "1.5rem",
            padding: "20px", height:  isMobile ? undefined : "200px",
            background: `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,1)), url(${banner}) ${isMobile ? 'center' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`,}}>
                    
                    
                    <Typography sx={{ fontSize: '30px',
            color: '#6490bc',
            textAlign: "center"}}>
        {t('TourPersonalizzatiTitle')}
                    </Typography>
                    
                    <Typography sx={{ textAlign: 'center', color: 'white',
                             fontSize: isMobile ? '20px' : "18px"}}>
                            <Trans i18nKey="TourPersonalizzatiIntro" />

                            

                    <Typography sx={{ fontSize: isMobile ? '30px' : '30px', marginTop: "1rem",
            color: 'white',
            textAlign: "center"}}>
        {t('TourSub')}
                    </Typography>
            </Typography>
            </div>





            <Typography sx={{ fontSize: '22px',  fontWeight: "bold", marginTop: "1rem",
            color: '#6490bc',
            textAlign: "center"}}>
        {t('TourEliTitle')} <br/>
        <span style={{fontSize: "16px", fontWeight: 'normal', color: "white"}}>
        {t('TourEliSub')}

        </span>
                    </Typography>
            <div style={{ display: isMobile ? 'flex' : "grid",
            padding: "20px", borderRadius: "0.5rem",
                flexDirection: isMobile ? "column" : undefined,
                alignItems: "center",

                justifyContent: "center", 
                gridTemplateColumns: "repeat(3, 1fr)", gap: "1rem", marginBottom: "1.5rem" }}>
                    
                {flightWithoutLunch.map((detail, detailIndex) => (
                    <motion.div
                        key={detailIndex}
                        variants={cardVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.1 }}
                    >
                        <ServiceCard detail={detail} isMobile={isMobile} />
                    </motion.div>
                ))}
            </div>

            <div 
            style={{width: isMobile ? 'auto' : "100%", display: "flex", flexDirection: "column",       
            alignItems: "center", justifyContent: "center", marginTop: "1.5rem",
            padding: "10px", 
            background: `linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,1)), url(${intro}) ${isMobile ? 'center' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`
            }}>
                  <Typography sx={{color: '#6490bc', fontWeight: "bold", marginTop: "1.5rem",  fontSize: '24px', textAlign: isMobile ? 'left' : 'center'}}>
               {t('VoloConPranzo')}
               <br/>
        <span style={{fontSize: "16px", fontWeight: 'normal', color: "white"}}>
            <Trans i18nKey='TourPranzoSub'/>

        </span>
            </Typography>
            <div style={{ display: isMobile ? 'flex' : "grid",
            padding: "10px", borderRadius: "0.5rem",
                flexDirection: isMobile ? "column" : undefined,
                alignItems: "center",
                marginTop: "1.5rem",
                justifyContent: "center", 
                gridTemplateColumns: "repeat(4, 1fr)", gap: "2rem", marginBottom: "1.5rem" }}>
                {flightWithLunch.map((detail, detailIndex) => (
                    <motion.div
                        key={detailIndex}
                        variants={cardVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.1 }}
                    >
                        <Link to={detail.linkpkg}>
                        <ServiceCardPranzo detail={detail} isMobile={isMobile} />
                        </Link>
                    </motion.div>
                ))}
            </div>
            </div>
            
            <div 
            style={{width: isMobile ? '100%' : "100%", height: isMobile ? 'auto' : undefined, display: "flex", flexDirection: "column",       
            alignItems: "center", justifyContent: "center", marginTop: "1.5rem",
            padding: "20px", 
            background: `linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,1)), url(${monte}) ${isMobile ? 'center' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`
            }}>
                  <Typography sx={{color: '#6490bc',fontWeight: "bold", marginTop: "1.5rem",  fontSize: '24px', textAlign: isMobile ? 'left' : 'center'}}>
               {t('VoloAvventura')}
               <br/>
        <span style={{fontSize: "16px", fontWeight: 'normal', color: "white"}}>
            <Trans i18nKey='VoloAvventuraIntro'/>

        </span>
            </Typography>
          
            <div style={{ display: isMobile ? 'flex' : "grid",
            padding: "10px", borderRadius: "0.5rem",
                flexDirection: isMobile ? "column" : undefined,
                alignItems: "center",
                marginTop: "1.5rem",
                justifyContent: "center", 
                gridTemplateColumns: "repeat(1, 1fr)", gap: "1rem", marginBottom: "1.5rem" }}>
                {flightAdventure.map((detail, detailIndex) => (
                    <motion.div
                        key={detailIndex}
                        variants={cardVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.1 }}
                    >
                        <ServiceCardAvventura detail={detail} isMobile={isMobile} />
                    </motion.div>
                ))}
            </div>
            </div>

           

            <div 
            style={{width: isMobile ? 'auto' : "100%", display: "flex", flexDirection: "column",       
            alignItems: "center", justifyContent: "center", marginTop: "3.5rem",
            padding: "10px", 
            background: `linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,1)), url(${transfer}) ${isMobile ? 'center' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`,}}>
                   <Container sx={{ display: 'flex',
                flexDirection: isMobile ? "column" : undefined,
                justifyContent: "center",                alignItems: "center",
                marginTop: "1rem", gap: "3rem", marginBottom: "2rem" }}>
                    <motion.div
                        variants={cardVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.1 }}
                    >
                        <AdditionalServices sMobile={isMobile} />
                    </motion.div>


            </Container>
            </div>

        
           
        </div>
    );
}




const AdditionalServices = () => {
  const { t } = useTranslation();

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeInOut' } }
  };

  const serviceCardVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: 'easeInOut' } }
  };
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      style={{ display: 'flex',  marginTop: "1.5rem", flexDirection: 'column', alignItems: 'center', gap: 20 }}
    >

<Typography sx={{ fontSize: isMobile ? '30px' : '30px', marginTop: "3rem",
            color: 'white',
            textAlign: "center"}}>
        {t('ServiziAggiuntivi')}
                    </Typography>
      <Container sx={{ display: 'flex', alignItems: "center", flexDirection: isMobile ? 'column' : 'none',  gap: isMobile ? '3rem' : "10rem", justifyContent: 'space-between'}}>
        <motion.div variants={serviceCardVariants}>
          <Card style={{width: "320px", border: "1px solid white"}}>
            <CardMedia component="img" style={{height: "200px"}} image={driver} alt="Driver" />
            <CardContent>
              <Typography color='#6490bc' variant="h6">{t('TrasferimentoAuto')}</Typography>
              <Typography fontSize='14px' gutterBottom>{t('TrasferimentoAutoDesc')}</Typography>
            </CardContent>
          </Card>
        </motion.div>
      

        <motion.div
          variants={serviceCardVariants}
        >
          <Card style={{width: "320px", border: "1px solid white"}}>
            <CardMedia component="img"  style={{height: "200px"}} image={videomaker} alt="Videomaker" />
            <CardContent>
              <Typography color='#6490bc'  variant="h6">{t('VideoMaker')}</Typography>
              <Typography fontSize='14px' gutterBottom>{t('VideoMakerDesc')}</Typography>
            </CardContent>
          </Card>
        </motion.div>
        
      </Container>
    </motion.div>
  );
}



function ServiceCard({ detail, isMobile }) {
    const [open, setOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);


      const {t} = useTranslation();
    
      const handleMouseEnter = () => setHovered(true);
      const handleMouseLeave = () => setHovered(false);

    return (
        <Box sx={{ width: '300px',  textAlign: 'center', margin: '20px' }}>
            <Link to={detail.linkpkg}>
            <motion.div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                whileHover={{ scale: 1.1 }}
                style={{
                    position: 'relative',
                    backgroundImage: `url(${detail.img[0]})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderBottomLeftRadius: '1rem',
                    borderBottom: "2px solid white",
                    boxShadow: "0px 0px 10px 0px white",
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                    borderBottomRightRadius:'1rem',
                    height: '180px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                }}
                // onClick={handleOpen}
            >
               

<Typography sx={{backgroundColor: 'white', display: "flex", 
                            bottom: 0,
                            left: 0,
                            right: 0,
                        position: 'absolute',
                                    justifyContent: "space-between", alignItems: "center",
                                    padding: "8px", borderBottomLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem",
                                    borderTop: "1px solid black", color: "black", }}>
                                        {detail.title}
                                        <span style={{fontSize: "14px", padding: "5px", color: '#6490bc', borderRadius: "0.3rem", backgroundColor: "black"}}>
                    {t('DurataDelVolo')} {detail.durata}
                </span>
            </Typography>
            </motion.div>

    </Link>
        </Box>
    );
}



function ServiceCardPranzo({ detail, isMobile }) {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = () => {
    setOpen(true);
    setSelectedImage(detail.img[0]); // Imposta l'immagine iniziale come prima dell'array
  };

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);


  const {t} = useTranslation();
  return (
    <Box sx={{ width: '300px', textAlign: 'center'}}>
    <motion.div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        whileHover={{ scale: 1.1 }}
        style={{
            position: 'relative',
            backgroundImage: `url(${detail.img[0]})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderBottomLeftRadius: '1rem',
            borderBottom: "2px solid white",
            boxShadow: "0px 0px 10px 0px white",
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
            borderBottomRightRadius:'1rem',
            height: '180px',
            cursor: 'pointer',
            overflow: 'hidden',
        }}
        onClick={handleOpen}
    >
        {hovered && (
            <Button
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    padding: '10px',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    transition: 'transform 0.5s ease',
                }}
            >
                {t('Select')}
            </Button>
        )}



<Typography sx={{backgroundColor: 'white', display: "flex", 
   bottom: 0,
   left: 0,
   right: 0,
   position: 'absolute',
            justifyContent: "space-between", alignItems: "center",
            padding: "8px", borderBottomLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem",
            borderTop: "1px solid black", color: "black", }}>
                              <span style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
              <FontAwesomeIcon icon={faLocationDot} style={{marginRight:"5px"}}/>   {detail.title}
              </span>
              <span style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "space-around"}}>
              <FontAwesomeIcon icon={faVideo} style={{marginRight:"10x"}}/> 
              <FontAwesomeIcon icon={faCar} style={{marginRight:"10x"}}/> 
              </span>
    </Typography>
    </motion.div>
       
        </Box>
  );
}


function ServiceCardAvventura({ detail, isMobile }) {
    const [open, setOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
  
    const handleOpen = () => {
      setOpen(true);
      setSelectedImage(detail.img[0]); // Imposta l'immagine iniziale come prima dell'array
    };
    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => setHovered(false);
    
    
    const {t} = useTranslation();
    return (
      <div style={{ width: isMobile ? '320px' : '800px', height: isMobile ? 'auto' : undefined, textAlign: 'center', padding: '20px' }}>
          
          
           <Link to={detail.linkpkg}>
        

      <motion.div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          whileHover={{ scale: 1.1 }}
          style={{
              position: 'relative',
              backgroundImage: `url(${cav1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'top',
              borderBottomLeftRadius: '1rem',
              borderBottom: "2px solid white",
              boxShadow: "0px 0px 10px 0px white",
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
              borderBottomRightRadius:'1rem',
              height: isMobile ? '350px' : '380px',
              cursor: 'pointer',

              overflow: 'hidden',
          }}
          onClick={handleOpen}
      >
          {hovered && (
              <Button
                  sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      color: 'white',
                      padding: '10px',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      transition: 'transform 0.5s ease',
                  }}
              >
                  {t('Select')}
              </Button>
          )}
  
  
  
  <Typography sx={{backgroundColor: 'white', display: "flex", 
     bottom: 0,
     left: 0,
     right: 0,
     position: 'absolute',
              justifyContent: "space-between", alignItems: "center",
              padding: "8px", borderBottomLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem",
              borderTop: "1px solid black", color: "black", }}>
                                <span style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                <FontAwesomeIcon icon={faPagelines} style={{marginRight:"10px"}}/>   {detail.title}
                </span>
                <span style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "space-around"}}>
                <FontAwesomeIcon icon={faVideo} style={{marginRight:"10x"}}/> 
                <FontAwesomeIcon icon={faCar} style={{marginRight:"10x"}}/> 
                </span>
      </Typography>
      </motion.div>
      </Link>
          </div>
    );
  }



export default TourPersonalizzati;
