import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import lux from '../../../assets/lux.png';
import bannerImage from '../../../assets/images/jet.jpg'; // Assicurati di avere un'immagine banner per i jet
import i18next from 'i18next';
import '../../../theme/Jet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import jet1 from "../../../assets/images/jet/1.jpeg";
import jet2 from "../../../assets/images/jet/2.jpeg";
import jet3 from "../../../assets/images/jet/3.jpeg";
import jet4 from "../../../assets/images/jet/4.jpeg";
import jet5 from "../../../assets/images/jet/5.jpeg";
import jet6 from "../../../assets/images/jet/6.jpeg";

function Jet() {
  const { t } = useTranslation();
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
     justifyContent: 'center', mt: isMobile ? 0 : 0, height: 'auto',
      backgroundColor: '#121212', color: 'white'}}>
      <Helmet>
        <title>{t('JetTitle')}</title>
        <meta name="description" content={t('JetDescription')} />
        <meta name="author" content="Luxury Agency Rome" />
        <meta name="publisher" content="Luxury Agency Rome" />
        <meta property="og:title" content={t('JetTitle')} />
        <meta property="og:description" content={t('JetDescription')} />
        <meta property="og:url" content="https://www.luxuryagencyrome.it/jet" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.luxuryagencyrome.it/jet" />
        <html lang={i18next.language} />
      </Helmet>

      <img src={lux} alt='Luxury' style={{ marginTop: "5rem", width: isMobile ? '100%' : "500px", objectFit: 'contain' }} />

      <div
        style={{
          width: isMobile ? '100%' : "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1.5rem",
          height: isMobile ? '300px' : "400px",
          background: `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,1)), url(${bannerImage}) ${isMobile ? 'top' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`,
        }}>

        <Typography sx={{ fontSize: isMobile ? '20px' : '30px', textAlign: "center" }}>
          {t('JetPrivati')}
        </Typography>
      </div>

      <Typography variant="h4" sx={{ mb: 4, mt: 4, textAlign: 'center' }}>
        {t('JetDescription')}
      </Typography>

        <div style={{width: "80%"}}>
      <Typography sx={{ mb: 4, textAlign: 'center' }}>
        <Trans i18nKey='JetGeneralDescription'/>
      </Typography>
      </div>

      <Box className="section-cards">

      <div className="section-card">
          <img src={jet2} alt="Jet 2" className="section-card__image" />
          <div className="section-card__overlay">
            <Typography variant="h5" className="section-card__title">{t('JetFamilyTitle')}</Typography>
          </div>
          <div className="section-card__content">
          <Typography variant="h5" className="section-card__title">{t('JetFamilyTitle')}</Typography>

            <Typography style={{
                            textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
                            fontSize: isMobile ? '16px' : undefined,     
              marginTop:"1rem"}}>{t('JetFamilyDescription')}</Typography>
          </div>
        </div>


        <div className="section-card">
          <img src={jet1} alt="Jet 1" className="section-card__image" />
          <div className="section-card__overlay">
            <Typography variant="h5" className="section-card__title">{t('JetBusinessTitle')}</Typography>
          </div>
        
          <div className="section-card__content">
          <Typography variant="h5" className="section-card__title">{t('JetBusinessTitle')}</Typography>
            <Typography style={{
                  fontSize: isMobile ? '16px' : undefined,         textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
              marginTop:"1rem"}}>
            <Trans i18nKey='JetBusinessDescription'/>
            </Typography>
          </div>
        </div>


      </Box>

      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
        {t('JetFleetTitle')}
      </Typography>

      <div style={{flexDirection: isMobile ? 'column' : undefined, 
        display: "flex", alignItems: isMobile ? 'center' : undefined,
        gap: isMobile ? '3rem' : 0,
        justifyContent: isMobile ? 'center' : undefined}} className='flotta-container'>

      <div class="card-flotta">
 <img src={jet6}  style={{width: "100%"}}/>
  <div class="bottom-section">
    <span class="title">JET 340</span>
    <div class="row row1">
      <div class="item">
        <span class="big-text">Passeggeri</span>
        <span class="regular-text">2-6</span>
      </div>
      <div class="item">
        <span class="big-text">Raggio</span>
        <span class="regular-text">3000 km</span>
      </div>
      <div class="item">
        <span class="big-text">Confort</span>
        <span class="regular-text"> 
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </span>
      </div>
    </div>
  </div>
</div>

<div class="card-flotta">
 <img src={jet3}  style={{width: "100%"}}/>
  <div class="bottom-section">
    <span class="title">JET 340</span>
    <div class="row row1">
      <div class="item">
        <span class="big-text">Passeggeri</span>
        <span class="regular-text">2-6</span>
      </div>
      <div class="item">
        <span class="big-text">Raggio</span>
        <span class="regular-text">3000 km</span>
      </div>
      <div class="item">
        <span class="big-text">Confort</span>
        <span class="regular-text"> 
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </span>
      </div>
    </div>
  </div>
</div>


<div class="card-flotta">
 <img src={jet4}  style={{width: "100%"}}/>
  <div class="bottom-section">
    <span class="title">JET 340</span>
    <div class="row row1">
      <div class="item">
        <span class="big-text">Passeggeri</span>
        <span class="regular-text">2-6</span>
      </div>
      <div class="item">
        <span class="big-text">Raggio</span>
        <span class="regular-text">3000 km</span>
      </div>
      <div class="item">
        <span class="big-text">Confort</span>
        <span class="regular-text"> 
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </span>
      </div>
    </div>
  </div>
</div>


<div class="card-flotta">
 <img src={jet5}  style={{width: "100%"}}/>
  <div class="bottom-section">
    <span class="title">JET 340</span>
    <div class="row row1">
      <div class="item">
        <span class="big-text">Passeggeri</span>
        <span class="regular-text">2-6</span>
      </div>
      <div class="item">
        <span class="big-text">Raggio</span>
        <span class="regular-text">3000 km</span>
      </div>
      <div class="item">
        <span class="big-text">Confort</span>
        <span class="regular-text"> 
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </span>
      </div>
    </div>
  </div>
</div>


</div>

      
    </Box>
  );
}

export default Jet;
