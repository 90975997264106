import React, { useState } from 'react';
import { motion } from 'framer-motion';
import gold from "../assets/gold.png";
import lux from '../assets/lux.png';
import simone from "../assets/images/simone.png";
import {Typography, Container, useMediaQuery } from '@mui/material';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faHelicopter, faPlaneUp, faSailboat, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


function AboutUs() {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [storyOpen, setStoryOpen] = useState(false);

  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.3, duration: 0.5 } },
  };

  const hoverVariants = {
    hover: {
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
    tap: {
      scale: 0.95
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', marginTop: isMobile ? "4rem" : '6rem',
      alignItems: 'center', justifyContent: "center",
      marginBottom: '12rem',
    }}>
      <motion.img src={lux} variants={fadeIn} initial="initial" animate="animate" alt="lux"

        style={{ marginTop: isMobile ? '1.5rem' : "0rem", width: "300px" }} />
     
     <div style={{width: "95%", marginTop: "1rem", borderBottom: '1px solid grey'}}></div>
      <div style={{ display: "flex",  marginTop: "2rem",  flexDirection: isMobile ? 'column' : undefined, alignItems: "center", justifyContent: 'center', gap: "2rem" }}>
        <div style={{ padding: '20px', borderRadius: '10px' }}>
              <Typography style={{ color: 'white', textAlign: isMobile ? "center" : 'left', fontSize: isMobile ? '18px' : "16px" }}>
                <Trans i18nKey='Lar' />
              </Typography>
              <div style={{display: "flex",  marginTop: '1.5rem', justifyContent: "center", alignItems: "center", gap: isMobile ? '1rem' : "2rem"}}>
                <Link style={{ color: "white", textDecoration: "none"}} to='/elicotteri'>
                  <FontAwesomeIcon icon={faHelicopter} style={{fontSize: "30px"}}/>
                </Link>
                <Link style={{ color: "white", textDecoration: "none"}} to='/pubblicita-aerea'>
                  <FontAwesomeIcon icon={faPlaneUp} style={{fontSize: "30px"}}/>
                </Link>
                <Link style={{ color: "white", textDecoration: "none"}} to='/supercar'>
                  <FontAwesomeIcon icon={faCar} style={{fontSize: "30px"}}/>
                </Link>
                <Link style={{ color: "white", textDecoration: "none"}} to='/yacht'>
                  <FontAwesomeIcon icon={faSailboat} style={{fontSize: "30px"}}/>
                </Link>
                <Link style={{ color: "white", textDecoration: "none"}} to='/ncc'>
                  <FontAwesomeIcon icon={faCar} style={{fontSize: "30px"}}/>
                </Link>
                <Link style={{ color: "white", textDecoration: "none"}} to='/security'>
                  <FontAwesomeIcon icon={faUserShield} style={{fontSize: "30px"}}/>
                </Link>
              </div>
        </div>
        
        <div style={{display: "flex",alignItems: "center",  flexDirection:"column"}}>
        <Typography style={{ color: "#6490bc", textAlign: "center", fontSize: isMobile ? '18px' : "20px" }}>
          <Trans i18nKey='Simone' />
        </Typography>
        
        <motion.div style={{ cursor: "pointer", borderRadius: '10px', position: 'relative' }}
        variants={hoverVariants}
        initial="initial"
        animate="animate"
        whileHover="hover"
        whileTap="tap"
        onClick={() => setStoryOpen(!storyOpen)}
      >

        <img src={simone} alt='simone' style={{  marginTop: "1rem",  objectFit: "contain", borderRadius: "100%", height: "180px" }} />
        <motion.div style={{
          position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
          display: "flex", justifyContent: "center", alignItems: "center",
          opacity: 0
        }}
          variants={overlayVariants}
          initial="hidden"
          whileHover="visible"
        >
          <Typography style={{ color: 'white', fontSize: "24px", fontWeight: "bold" }}>
          {storyOpen ? <Trans i18nKey="Close" /> : <Trans i18nKey="Open" />}
          </Typography>
        </motion.div>
        
      </motion.div>
      </div>
      </div>

      <div style={{width: "95%", marginTop: "1rem", borderBottom: '1px solid grey'}}></div>


      {storyOpen && (
          <div className="modal-container">
            <div className="modal-container-header">
              <span className="modal-container-title">

                <Trans i18nKey='story-title' />
              </span>
              <button className="icon-button" onClick={() => setStoryOpen(false)}>
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="white"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="currentColor"></path>
                </svg>
              </button>
            </div>
            <div className="modal-container-body rtf">
              <Typography style={{ marginBottom: '10px' }}>
                <Trans i18nKey='story1' />
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                <Trans i18nKey='story2' />
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                <Trans i18nKey='story3' />
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                <Trans i18nKey='story4' />
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                <Trans i18nKey='story5' />
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                <Trans i18nKey='story6' />
              </Typography>
            </div>
          </div>
        )}

        
    </div>

  );
}

export default AboutUs;
