import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const SidebarBlog = () => {
    const [recentPosts, setRecentPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRecentPosts = async () => {
            try {
                const response = await axios.get('/posts?limit=3&page=1'); // Limita a 3 post
                setRecentPosts(response.data);
            } catch (error) {
                console.error('Error fetching recent posts:', error);
            }
        };

        fetchRecentPosts();
    }, []);

    return (
        <SidebarContainer>
            <Typography variant="h6" textAlign='center'>
                Articoli Recenti
            </Typography>
            {recentPosts.map((post) => (
                <a 
                    key={post._id} 
                    href={`/blog/${post.slug}`} 
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <motion.div
                        style={{ cursor: "pointer", borderBottom: "1px solid #ccc", margin: "10px 0", borderRadius: "6px", overflow: "hidden" }}
                        whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                        <RecentPostCard>
                            <div className="card-image" style={{ backgroundImage: `url(${post.coverImage})` }}></div>
                            <CardContent>
                                <Typography style={{textShadow: "none"}} className="category">
                                    {post.category}
                                </Typography>
                                <Typography style={{textShadow: "none"}}  className="heading">
                                    {post.title}
                                </Typography>
                                <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
                                    <Typography style={{textShadow: "none"}}  className="author">
                                        {new Date(post.createdAt).toLocaleDateString()}
                                    </Typography>
                                    <div style={{display: 'flex', justifyContent: "center", gap: "0.5rem", alignItems: "center"}}>
                                        <Typography style={{textShadow: "none"}}  className="author">
                                            {post.readingTime}
                                        </Typography>
                                        <Typography style={{textShadow: "none"}}  className="author">
                                            {post.views}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </RecentPostCard>
                    </motion.div>
                </a>
            ))}
        </SidebarContainer>
    );
};

const SidebarContainer = styled(Box)`
    display: flex;
    background-color: #121212;
    padding: 20px;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 1rem;
`;

const RecentPostCard = styled(Card)`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: none;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    .card-image {
        background-color: rgb(236, 236, 236);
        width: 100%;
        height: 130px;
        background-size: cover;
        background-position: center;
        transition: transform 0.2s ease-in-out;
    }
    .card-image:hover {
        transform: scale(0.98);
    }
    .category {
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: 600;
        color: rgb(63, 121, 230);
        padding: 10px 7px 0;
    }
    .category:hover {
        cursor: pointer;
    }
    .heading {
        font-weight: 600;
        color: rgb(88, 87, 87);
        padding: 7px;
    }
    .heading:hover {
        cursor: pointer;
    }
    .author {
        color: gray;
        font-weight: 400;
        font-size: 11px;
        padding-top: 20px;
    }
    .name {
        font-weight: 600;
    }
    .name:hover {
        cursor: pointer;
    }
`;

export default SidebarBlog;
