import {
    Typography,
    Button,
    useTheme,
    useMediaQuery,
    Dialog,
    DialogContent,
    IconButton,
  } from '@mui/material';
import React from 'react';
  import { faEnvelope, faChevronLeft, faChevronRight, faBuilding } from '@fortawesome/free-solid-svg-icons';
  import CloseIcon from '@mui/icons-material/Close';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { Trans, useTranslation } from 'react-i18next';
  


  // Import images
  import lux from '../../../assets/lux.png';
  import gold from '../../../assets/gold.png';
  import pubae from '../../../assets/images/pubblicita/pubae.jpg';
  import gf from '../../../assets/images/pubblicita/gf.png';
  import pubair from '../../../assets/images/pubblicita/pubair.jpg';
  import pub1 from '../../../assets/images/pubblicita/1.jpg';
  import pub2 from '../../../assets/images/pubblicita/2.jpg';
  import pub3 from '../../../assets/images/pubblicita/3.jpg';
  import pub4 from '../../../assets/images/pubblicita/4.jpg';
  import pub5 from '../../../assets/images/pubblicita/5.jpg';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import { Box, display } from '@mui/system';
  
  const images = [pub1, pub2, pub3, pubae, pub4, pub5];


  function PubblicitaAerea() {
    const { t } = useTranslation();
    const theme = useTheme();
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

 // Style for the main container
 const mainContainerStyle = {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    marginTop: '4rem',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isMobile ? '1rem' : '2rem',
  };

  const serviceImages = images.map((image, index) => (
    <img
      key={`image-${index}`}
      src={image}
      alt={`Service ${index + 1}`}
      style={{
        width: isMobile ? '300px' : 'auto',
        boxShadow: '0px 0px 20px 0px white',
        border: '2px solid white',
        objectFit: 'contain',
        height: isMobile ? 'auto' : '120px',
        borderRadius: '1rem',
        cursor: 'pointer',
      }}
      onClick={() => handleImageClick(index)}
    />
  ));
  
    // Style for the ad section
    const adSectionStyle = {
      width: isMobile ? 'auto' : '95%',
      marginTop: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '30px',
      borderRadius: '0.5rem',
      background: `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url(${pubae}) center / ${
        isMobile ? 'contain' : 'cover'
      } no-repeat`,
    };

    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
      const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      };
    
      const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      };
    
  
    return (
      <div style={mainContainerStyle}>

<Helmet>
    <title>{t('MetaPubAereaTitle')}</title>
    <meta name="description" content={t('MetaPubAereaDesc')} />
    <meta name="keywords" content="tour di lusso Roma, elicottero Roma, noleggio supercar Roma, esperienze esclusive Roma, tour esclusivi Roma, jet privato Roma, vacanze di lusso Roma"></meta>
    <meta name="author" content="Luxury Agency Rome" />
    <meta name="publisher" content="Luxury Agency Rome" />
    <meta property="og:title" content={t('MetaPubAereaTitle')} />
    <meta property="og:description" content={t('MetaPubAereaDesc')} />
    <meta property="og:url" content="https://www.luxuryagencyrome.it/pubblicita-aerea" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.luxuryagencyrome.it/pubblicita-aerea" />
    <html lang={i18next.language} />
</Helmet>
        <img
          src={lux}
          alt='Luxury'
          style={{ width: isMobile ? '100%' : '500px', objectFit: 'contain' }}
        />
        <img
          src={gold}
          alt='Gold'
          style={{ marginTop: '1rem', width: isMobile ? '100%' : '500px', objectFit: 'contain' }}
        />
  
        <div style={adSectionStyle}>
          <Typography variant='h2' sx={{ color: '#6490bc', fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
            {t('PubblicitaAerea')}
          </Typography>
        </div>
  
        <div style={{ width: '100%', padding: '20px'}}>
          <div style={{ alignItems: "center", justifyContent: "center", display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            
            
          <div style={{ alignItems: "center", 
          gap: isMobile ? '1.5rem' : 0,
            width: "100%", flexDirection: isMobile ? 'column' : undefined,
            justifyContent: "space-around", display: 'flex' }}>
            <Typography sx={{textAlign: isMobile ? 'center' : 'left', marginTop: '2rem', fontSize: '18px' }}>
              <Trans i18nKey='PubblicitaAereaDesc' />
            </Typography>

            <Box style={{display: "flex", borderRadius:" 0.4rem", flexDirection: "column", backgroundColor: "black",
              padding: "20px"
            }}>
              <Typography style={{textAlign: isMobile ? 'center' : "right", color: "white"}}>
               <FontAwesomeIcon icon={faBuilding} style={{marginRight: "10px"}}/> Sei un'azienda? <br/>
                Contattattaci e scopri i nostri servizi su misura!
              </Typography>
            </Box>
            </div>

            {!isMobile && (
            <div
              style={{
                display: 'flex',
                background:
                  'linear-gradient(284deg, rgba(196,201,255,1) 0%, rgba(139,142,175,1) 28%, rgba(255,255,255) 55%, rgba(196,201,255,1) 89%)',
                padding: '20px',
                flexDirection: 'row',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {serviceImages}
            </div>
          )}




            {isMobile && (
              <img
                src={pubair}
                alt='pubair'
                style={{ marginTop: '3rem', width: '300px', objectFit: 'contain' }}
              />
            )}
            <Typography sx={{ marginTop: '2rem', textAlign: 'center', fontSize: '18px' }}>
              <Trans i18nKey='PubblicitaAereaDesc2' />
            </Typography>




            {isMobile && (
            <div
              style={{
                display: 'flex',
                background: isMobile ? 
                'linear-gradient(180deg, rgba(0,0,0) 0%, rgba(196,201,255,1) 100%)'
                
                : 'linear-gradient(284deg, rgba(196,201,255,1) 0%, rgba(139,142,175,1) 28%, rgba(255,255,255) 55%, rgba(196,201,255,1) 89%)',
                padding: "20px",
                flexDirection: isMobile ? 'column' : undefined,
                gap: isMobile ? '1rem' : '1rem',
                width: "100%",
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {serviceImages}
            </div>
)}
            

                <div style={{ display: 'flex', alignItems: "center", 
                justifyContent: "center", width: isMobile ? '90%' : "50%",
                height: "200px", backgroundPosition: "center", backgroundSize: 'cover', 
                backgroundRepeat: 'no-repeat',
                padding: "30px", borderRadius: "0.5rem", borderBottom: "5px solid white",
                boxShadow: '0px 0px 20px 0px',
                backgroundImage: `url(${gf})`,
                flexDirection: 'column' }}>
                </div>
                <Typography style={{
                  textAlign: "center",
                  borderRadius: "0.5rem", fontSize: '22px' }}>
                    <Trans i18nKey='PubblicitaAereaInfo' />
                  </Typography>
          </div>
        </div>
  
  <Link to='/contact'>
        <Button
          sx={{ mt: 4, backgroundColor: '#6490bc', color: 'black', border: '1px solid white' }}
        >
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} /> {t('Prenota')}
          <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: '10px' }} />
        </Button>
        </Link>
         {/* Modal/Lightbox Implementation */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>

        <DialogContent style={{backgroundColor: 'white', borderRadius: "0.7rem", 
        padding: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
         
         <Typography  gutterBottom style={{fontSize: "30px", color: "black"}}>
            Gallery
        </Typography>
        <img src={images[currentIndex]} alt={`Selected ${currentIndex + 1}`} style={{ borderRadius: "0.5rem", width: '100%' }} />
          <div style={{ display: 'flex', gap: '3rem', marginTop: '1rem' }}>
            <IconButton onClick={handlePrev}>
              <FontAwesomeIcon style={{ color: "black"}}icon={faChevronLeft} />
            </IconButton>
            <IconButton onClick={handleClose} style={{ color: "#e74c3c"}}>
            <CloseIcon />
          </IconButton>
            <IconButton onClick={handleNext}>
              <FontAwesomeIcon style={{ color: "black"}}icon={faChevronRight} />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
      </div>
    );
  }
  
  export default PubblicitaAerea;
  