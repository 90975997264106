import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Tooltip, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTimes, faPenToSquare, faGlasses } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import { motion } from 'framer-motion';

const PostList = ({ searchTerm }) => {
    const [posts, setPosts] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('/posts');
                setPosts(response.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    const handleDelete = async () => {
        try {
            await axios.delete(`/posts/${selectedPost._id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });
            setPosts(posts.filter(post => post._id !== selectedPost._id));
            setOpen(false);
            toast.success('Post eliminato con successo!');
        } catch (error) {
            toast.error('Errore nell\'eliminazione del post');
        }
    };

    const handleOpen = (post) => {
        setSelectedPost(post);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedPost(null);
    };

    const filteredPosts = posts.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.category.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box style={{ width: "100%", 
        padding: '5px', borderRadius: '0.5rem' }}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Typography style={{ color: "white", fontFamily: "Belanosima"}}>
                    Post nel Blog: {posts.length}
                    <FontAwesomeIcon style={{ fontFamily: "Belanosima", marginLeft: "10px" }} icon={faPenToSquare} />
                </Typography>
                <Button style={{backgroundColor: "black"}} onClick={() => navigate('/admin/posts/create')}>
                    CREA POST <FontAwesomeIcon style={{ marginLeft: "10px" }} icon={faPenToSquare} />
                </Button>
            </div>
            <div style={{ maxHeight: 'calc(100vh - 300px)', marginTop: "1rem", overflow: 'auto' }}>

                {filteredPosts.map((post) => {
                    const formattedDate = new Date(post.createdAt).toLocaleDateString('it-IT', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    });

                    return (
                        <motion.div
                            key={post._id}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{  marginBottom: "15px",
                                borderRadius: "0.5rem",
                                backgroundColor: "black",
                               boxShadow: '0 4px 8px rgba(255,255,255,0.3)', 
                                padding: '20px' }}
                        >
                            <Typography style={{ color: '#6490bc', fontFamily: 'Belanosima', 
                                fontSize: isMobile ? '18px' : '24px' }}>
                                {post.title}
                            </Typography>
                            <div style={{ display: "flex", 
                                 flexDirection: "column", marginTop: '5px' }}>
                                <Typography style={{ color: 'white', marginBottom: "5px", fontSize: isMobile ? '14px' : '16px', fontFamily: 'Belanosima', whiteSpace: 'pre-line' }}>
                                    <span style={{ fontSize: isMobile ? '16px' : '20px', color: "#B8B3AF" }}>{post.excerpt} <br/>
                                    </span>
                                </Typography>
                                
                                {user && user.isAdmin && (
                                    <div style={{ display: "flex", marginTop: "0.4rem", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                        <Tooltip title='Quante volte è stato letto il post.'>
                                                <FontAwesomeIcon style={{color: '#6490bc'}} icon={faGlasses} /> 
                                                <span style={{ marginLeft: "5px", color: "grey", fontSize: "14px"}}> [ {post.views} ] </span>
                                            </Tooltip>
                                            <Tooltip title='Apri Post'>
                                                <FontAwesomeIcon icon={faEye} onClick={() => navigate(`/blog/${post.slug}`)} style={{ color: "#6490bc", cursor: 'pointer', marginLeft: "5px" }} />
                                            </Tooltip>
                                            <Tooltip title='Modifica Post'>
                                                <FontAwesomeIcon icon={faEdit} onClick={() => navigate(`/admin/posts/edit/${post._id}`)} style={{ cursor: 'pointer', color: "#6490bc", marginLeft: "5px" }} />
                                            </Tooltip>
                                            <Tooltip title='Elimina Post'>
                                                <FontAwesomeIcon icon={faTimes} onClick={() => handleOpen(post)} style={{ color: "#6490bc", cursor: 'pointer', marginLeft: "5px" }} />
                                            </Tooltip>
                                        </div>
                                        <Typography style={{ color: 'grey', fontSize: isMobile ? '14px' : '16px', fontFamily: 'Belanosima', whiteSpace: 'pre-line' }}>
                                            Creato il {formattedDate}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </motion.div>
                    );
                })}
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Conferma Eliminazione</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sei sicuro di voler eliminare questo post? Questa azione non può essere annullata.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Annulla</Button>
                    <Button onClick={handleDelete} color="secondary">Elimina</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </Box>
    );
};

export default PostList;
