import React from 'react';
import { Box, Typography, Container, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import lux from "../../../assets/lux.png";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';

import ponza from "../../../assets/images/yacht/ponza.webp";
import cata from "../../../assets/images/yacht/cata.jpg";
import crociera from "../../../assets/images/yacht/crociera.jpg";
import '../../../theme/Yacht.css';
import yachtbanner from "../../../assets/images/yacht/ponza.webp";

function Yacht() {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const { t } = useTranslation();

  const services = [
    {
      title: t('YachtPonzaTitle'),
      img: ponza,
      link: "/yacht/ponza"
    },
    {
      title: t('YachtCrocieraTitle'),
      img: crociera,
      link: "/yacht/crociera"
    },
    {
      title: t('YachtCatamaranoTitle'),
      img: cata,
      link: "/yacht/catamarano"
    },
  ];

  return (
    <Box className="page-container">
      <Helmet>
        <title>{t('Yacht')}</title>
        <meta name="description" content={t('MetaYachtDesc')} />
        <meta property="og:title" content={t('Yacht')} />
        <meta property="og:description" content={t('MetaYachtDesc')} />
        <meta property="og:url" content="https://www.luxuryagencyrome.it/yacht" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.luxuryagencyrome.it/yacht" />
        <html lang={i18next.language} />
      </Helmet>

      <img src={lux} alt='gold' style={{ width: isMobile ? '100%' : "500px", objectFit: 'contain' }} />
      <Typography sx={{ marginTop: "1rem", fontSize: '30px', textAlign: isMobile ? 'left' : 'left' }}>
        {t('Yacht')}
      </Typography>
      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'none',
        marginBottom: '1rem',
        padding: '30px',
        backgroundPosition: isMobile ? 'top' : 'bottom',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        justifyContent: "center",
        backgroundImage: "url('https://www.nieddittas.it/wp-content/uploads/2021/08/come-si-e-formato-il-mare.jpg')",
        marginTop: '1rem',
        width: isMobile ? 'auto' : "97%"
      }}>

        <Typography sx={{ fontSize: '22px', fontStyle: "italic", color: "white", textAlign: 'center' }}>
          "<Trans i18nKey="YachtFrase" />"
        </Typography>
      </div>

      <Typography sx={{ marginTop: "1rem", fontSize: '22px', textAlign: isMobile ? 'left' : 'center' }}>
        <Trans i18nKey="YachtTransfer" />
      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: "3rem",
        alignItems: "center",
        justifyContent: "center",
        marginTop: '2rem',
        width: isMobile ? 'auto' : "95%"
      }}>
        {services.map((service, index) => (
          <Link to={service.link} key={index} style={{ textDecoration: 'none' }}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="card"
            >
              <div className="card__image-container">
                <img src={service.img} alt={service.title} className="card__image" />
                <div className="card__overlay">
                  <Typography variant="h6" className="card__discover">
                    {t('Scopri')}
                  </Typography>
                </div>
              </div>
              <div className="card__content">
                <Typography variant="h6" className="card__title">
                  {service.title}
                </Typography>
              </div>
            </motion.div>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default Yacht;
