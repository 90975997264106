// src/lang/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    it: {
        translation: {
            "Seguici": 'Seguici sui Social!',
            "Click": 'Clicca qui!',
            "ServicePhrase1": "Tour di Lusso in Elicottero",
            "ServicePhrase2": "Trasferimenti in Jet",
            "ServicePhrase3": "Spostamenti in Elicottero",
            "ServicePhrase4": "Pubblicita' Aerea",
            "ServicePhrase5": "Supercar",
            "ServicePhrase7": "Noleggio con Conducente",
            "ServicePhrase8": "Gite in barca o yacht",
          "RispostaOk": "La tua risposta è stata inviata con successo. <br/> Puoi chiudere questa pagina.",
          "Scopri": "Scopri",
          "MetaHomeTitle": "L.A.R. - Luxury Agency Rome - Tour in elicottero e servizi di Lusso.",
          "MetaHomeDesc": "Vivi l'Italia dall'alto con un giro in elicottero indimenticabile: Luxury Agency Rome offre tour personalizzati per scoprire città storiche e paesaggi mozzafiato! ",
          "MetaHomeKeyw": "elicottero, Roma, agenzia, lusso, vacanza, escursione, tour, lusso, viaggio, agenzia di lusso Roma, jet privato, supercar, noleggio, LAR, business",
          "MetaEliTitle": "Giro in elicottero Italia: noleggio elicottero privato per coppie/gruppi",
          "MetaHeliDesc": "Vivi l'Italia dall'alto con un giro in elicottero indimenticabile: Luxury Agency Rome offre tour personalizzati per scoprire città storiche e paesaggi mozzafiato! ",
          "MetaEliKeyw": "elicottero, Roma, agenzia, lusso, vacanza, escursione, tour, lusso, viaggio, agenzia di lusso Roma, jet privato, supercar, noleggio, LAR, business",
          "MetaTourTitle": "Pacchetti volo in Elicottero: dal volo con pranzo al volo avventura ",
          "MetaTourDesc": "Scopri i nostri tour personalizzati in elicottero. Dal pacchetto Economy al Luxury. Dal volo con pranzo al volo avventura a cavallo e su quad!",
          "MetaYachtTitle": "Giro su Yacht e Barche ",
          "MetaYachtDesc": "Esperienze e gite indimenticabili in barca o yacht nei mari d'Italia.",
          "Open": "Apri",
          "Close": "Chiudi",
          "Home": "Home",
          "Simone": "La Storia di Simone",
          'Lar': "Specializzati in tour aerei di lusso, ci dedichiamo a fornire esperienze personalizzate <br /> che spaziano dalle escursioni panoramiche sui monumenti storici di Roma <br/> alle avventure su misura nei paesaggi mozzafiato dell'Italia. <br/>  <br/> La nostra flotta è dotata delle più moderne tecnologie e gestita da piloti professionisti, <br/> assicurando non solo sicurezza, ma anche comfort e avventura!",
          "story-title": "La Storia di Simone. <br/> CEO di LAR - Luxury Agency Rome",  
          "story1": "Simone, un ragazzo di 32 anni, nasce in una famiglia con una solida tradizione imprenditoriale nel settore degli stabilimenti balneari, proprietaria del famoso stabilimento di Ostia il “Tibidabo”.",
            "story2": "La sua storia professionale ha inizio quando, a seguito della vendita dello stabilimento, si trova a lavorare, insieme al padre, al nonno e allo zio, in un noto negozio di termoidraulica del litorale romano.<br/>Mentre si forma nel mondo del commercio termoidraulico, dimostrando una tenacia e una dedizione agli affari non comuni, Simone decide di intraprendere un percorso scolastico in grafica pubblicitaria.",
            "story3": "Fin da piccolo, tuttavia, Simone nutriva un'altra grande passione: il volo. Sognava di solcare i cieli come pilota commerciale di aerei!<br/>Questa passione lo spinse a preparare un concorso come pilota commerciale Alitalia, investendo moltissima energia, tempo e denaro. Questa selezione, tuttavia, andò male e Simone non riuscì ad ottenere la licenza di pilota per un “soffio”.",
            "story4": "Nonostante questa esperienza deludente, la sua passione per il volo cresceva di giorno in giorno, facendo maturare in lui un nuovo obiettivo: l’ottenimento di una licenza commerciale per pilota di elicotteri.<br/>Ma, prima di arrivare alla fine di questo percorso, Simone decise di convertire la sua licenza di pilota commerciale di elicotteri in una licenza privata, in modo da poter utilizzare questa idoneità a scopo di hobby, sempre per preservare i suoi doveri nei confronti dell’attività di famiglia.",
            "story5": "Poco tempo fa, Simone compie un passo decisivo: vende il negozio di termoidraulica e decide di realizzare un altro grande sogno: acquistare un aereo per il traino di striscioni pubblicitari.<br/>Nasce così LAR - Luxury Agency Rome, un'agenzia di noleggio di mezzi di lusso che offre un'esperienza esclusiva ai propri clienti: elicotteri, jet, yacht e barche per trasferimenti via aria, terra e acqua.",
            "story6": "Un'Esempio di Tenacia e Perseveranza<br/><br/>La storia di Simone è un esempio di tenacia e perseveranza. Nonostante i cambiamenti di rotta e le sfide incontrate, ha saputo trasformare le sue passioni in un'impresa di successo.<br/>LAR - Luxury Agency Rome rappresenta la realizzazione di un sogno, un connubio perfetto tra passione per il volo, spirito imprenditoriale e la capacità di offrire un servizio unico ed esclusivo.",
          "GalleryIntro": "La Luxury Agency Rome offre esperienze indimenticabili in tutta Italia. <br/>  <br/> Che sia Aria Terra o Mare, <br/>  siamo pronti a soddisfare le tue richieste. <br/> <br/>  Creando pacchetti su misura per ogni tipo di esigenza.",
          "HomeIntro2": "Che sia Aria Terra o Mare, siamo pronti a soddisfare le tue richieste <br/> creando pacchetti su misura per ogni tipo di esigenza.",
          "HomeIntro": "La Luxury Agency Rome offre esperienze indimenticabili in tutta Italia.",
          "Elicotteri": "Elicotteri",
          "NoleggioAuto" : "Noleggio Auto",
          "Servizi" : "Servizi",
          "Destinazioni": 'Le nostre Destinazioni',
          "Nome": "Nome e cognome",
          "Telefono": "Telefono",
          "Invia": "Invia Richiesta",
          "Messaggio": "Messaggio",
          "ChiSiamo": "Chi siamo",
          "Contatti": 'Inviaci una richiesta di informazioni',
          "ContattiButton": 'CONTATTACI',
          "MetaPilotaGiornoTitle": "Pilota di elicottero per un giorno: vivi l’emozione!",
          "MetaPilotaGiornoDesc": "Hai sempre sognato di volare? Ora puoi realizzare il tuo sogno e sentirti pilota per un giorno! L'istruttore ti guiderà passo dopo passo, insegnandoti a controllare l'elicottero in volo ",
          "PubblicitaAerea": 'Pubblicità Aerea',
          "PilotaGiornoTitle": "Pilota per un Giorno",
          "PilotaGiornoIntro": "Diventa pilota per un giorno: vivi l'emozione di un volo in elicottero!",
          "PilotaGiornoDesc1": "Diventa pilota di elicottero per un giorno: vivi l'emozione di un volo in elicottero! <br/> Hai sempre sognato di volare? Ora puoi realizzare il tuo sogno e sentirti pilota per un giorno!",
          "PilotaGiornoDesc2": "Sali a bordo del nostro elicottero e preparati a vivere un'esperienza indimenticabile. <br/> Sotto la guida di un istruttore esperto, imparerai le nozioni base di pilotaggio  <br/>  e avrai la possibilità di prendere i comandi dell'elicottero. ",
          "PilotaGiornoDesc3": "Sorvolerai panorami mozzafiato,  <br/>godendo di una vista privilegiata che ti lascerà senza fiato.  <br/> <br/>Ammira dall'alto città storiche, coste incontaminate, montagne maestose o suggestivi vigneti.",
          "PilotaGiornoDesc4": "Durante il volo, l'istruttore ti guiderà passo dopo passo, <br/> insegnandoti a controllare l'elicottero in volo e a eseguire manovre semplici.  <br/> Avrai la possibilità di provare l'emozione di decollare,  <br/> atterrare e sorvolare terreni diversi.",
          "PubblicitaAereaDesc": "La Luxury Agency Rome è leader nel settore della pubblicità aerea. <br/><br/> Copriamo l'intero territorio nazionale, sorvolando l'Italia da nord a sud e da est a ovest incluso tutte le spiagge. ",
          "PubblicitaAereaDesc2": "Il nostro servizio è adatto a tutti... Che tu voglia fare un regalo, promuovere la tua azienda, celebrare una comunione o un battesimo, siamo in grado di soddisfare ogni tua richiesta. <br/><br/> Con la nostra pubblicità, il tuo messaggio raggiungerà migliaia di persone in un tempo record. <br/>Chiamaci e metticci alla prova.",
          "MetaAvventuraTitle": "Volo in elicottero con passeggiata a cavallo o giro in quad ",
          "MetaAvventuraDesc": "Ti aspettano i nostri cavalli o i nostri quad, che, insieme alla guida, ci condurranno in un giro di circa due ore attraverso le splendide montagne circostanti",
          "PubblicitaAereaInfo": "Vuoi mandare il tuo messaggio ad un Vip nella casa più famosa d’Italia? Contattaci",
          "FastResponse": "Garantiamo una risposta rapida a tutte le richieste!",
            "NostriServizi": "I nostri Servizi",
            "PilotaPerUnGiornoTitle": "Pilota per un giorno",
            "PilotaPerUnGiornoIntro": "Grazie alla Luxury Agency Rome potrai realizzare il tuo sogno di diventare un pilota professionista. ",
            "PilotaPerUnGiornoDesc": "Il pacchetto “Pilota per un giorno” comprende una lezione teorica in aula con uno dei nostri piloti professionisti che ti introdurrà il mondo del volo, un corso macchina affiancato da un tecnico professionista che ti spiegherà come funziona l’elicottero le sue parti principali e le sue limitazioni ed infine un volo di circa 20 minuti in doppio comando affiancato da un nostro pilota che ti farà pilotare e ti spiegherà i concetti base del volo.",
            "TourPersonalizzatiTitle": "Tour e Gite in Elicottero Personalizzati",
            "ServiziPlusIntro" : "I nostri pacchetti comprendono servizi aggiuntivi come VideoMaker professionale e Autista privato.",
            "TourPersonalizzatiIntro": "Scoprite l'Italia in maniera unica con i nostri tour personalizzati.",
            "TourSub": "Pacchetti Volo in Elicottero: dal pacchetto Economy al Luxury, dal Volo con Pranzo al Volo Avventura",
            "TourPersonalizzatiDesc": "Dalle magiche cascate e ville storiche di Tivoli, ai pittoreschi laghi e castelli intorno a Bracciano, fino ai celebri Castelli Romani, ogni tour è progettato per offrire un'esperienza esclusiva e memorabile. Scegliete tra opzioni economy, premium e luxury per adattarsi perfettamente alle vostre esigenze, con servizi aggiuntivi come trasferimenti auto privati e videomaker personale per immortalare ogni momento del vostro viaggio spettacolare.",
            "TourEliTitle": "Pacchetti tour in elicottero",
            "TourEliSub":"Scegli il pacchetto che preferisci, in funzione delle tue necessità e del tuo budget: volo in elicottero per 1/2 persone o per piccoli gruppi. ",
            "TourPranzoSub": "Vivi un'esperienza indimenticabile! Sorvola paesaggi mozzafiato e gusta un delizioso pranzo in alta quota. ",
            "ViaggiAffariTitle": "Viaggi di affari",
            "ViaggiAffariIntro": "La Luxury Agency Rome offre trasferimenti in elicottero per uomini d’Affari e VIP.",
            "ViaggiAffariDesc": "Se devi spostarti in tutta Italia nel minor tempo possibile la soluzione giusta è questa. Voli mirati che ti faranno spostare velocemente, nel riservo della privacy e nella massima sicurezza, accompagnati da piloti professionisti.",
            "ElicotteriVenditaTitle": "Elicotteri in vendita",
            "ElicotteriVenditaIntro": "",
            "ElicotteriVenditaDesc": "Vasta gamma di elicotteri in vendita per ogni esigenza.",
            "ElicotteriTitle": "Elicotteri",   
            "JetPrivati": "Jet Privati",
            "Avanti": "Avanti",
            "ContactClick" :"Clicca qui! <br/> Inviaci un messaggio per richiedere <br/> informazioni e preventivi sui nostri servizi",
            "Indietro" : "Indietro",
            "MessaggioInviato": "Messaggio inviato ✅",
            "InvioFallito": "Invio del messaggio non riuscito.",
            "Yacht": "Barche e Yacht",
            "YachtDesc": "La Luxury Agency Rome offre esperienze indimenticabili solcando i mari di tutta Italia creando pacchetti di un semplice week-end fino a delle vere e proprie crociere personalizzate su ogni tipo di imbarcazione. Abbiamo a disposizione barche a vela di qualsiasi tipo, barche a motore, bellissimi catamarani e yachts.",
            "ServizioElicotteri": "Tour e gite in Elicottero",   
            "ElicotteriFrase": " ''Ognuno di noi ha un paio di ali, ma solo chi sogna impara a volare.''",
            "ServizioElicotteriDesc": "Scopri il fascino del volo con i nostri esclusivi tour in elicottero, trasferimenti business rapidi <br/> e l'opportunità unica di diventare pilota per un giorno, completi di lezioni teoriche e pratiche. <br/> Esplora le bellezze d'Italia dall'alto e goditi un'esperienza di lusso su misura per ogni esigenza.",
            "ServizioElicotteriDesc2": "Vivi l'Italia dall'alto con un giro in elicottero indimenticabile. <br/> Giro elicottero in Italia con Luxury Agency Rome: trasforma i tuoi sogni in realtà con esperienze esclusive e personalizzate in tutta Italia! <br/> <br/> Se cerchi un noleggio elicottero per lavoro o divertimento o se stai cercando un volo elicottero regalo per una persona cara, sei atterrato nel sito giusto! <br/> Che tu sia un appassionato di avventura o semplicemente alla ricerca <br/> di un modo unico per ammirare la bellezza mozzafiato del Bel Paese un giro in elicottero è l'ideale per te. <br/> <br/> Luxury Agency Rome offre una vasta gamma di tour in elicottero per soddisfare ogni esigenza e budget. <br/>I nostri esperti selezioneranno con cura l'itinerario perfetto per te, garantendoti un'esperienza sicura e indimenticabile.",
            "Scopri": "Scopri",
            "NoleggioTitle": "Super Car",
            "SuperCarIntro": 'Scopri la nostra collezione di SuperCars',

            "DurataDelVolo" : 'Durata del volo:',
            "NoleggioDesc": "Viaggia con stile e comfort con il nostro servizio di noleggio auto di lusso. Scegli tra una vasta gamma di vetture prestigiose per ogni occasione, assicurandoti un'esperienza esclusiva e personalizzata. Goditi il massimo del lusso e della discrezione, con opzioni di autista professionista disponibili per garantire il tuo massimo relax.",
            "Diario": "Diario di Bordo",
            "Select": "Seleziona",
            "Prenota" : "Contattaci",
            "TipoReq": "Seleziona un servizio",
            "Back": "Indietro",
          "YachtFrase" : "Non si può mai attraversare l’oceano se non si ha il coraggio di perdere di vista la riva.",

          
            "YachtPonzaTitle": "Week-end da Sogno a Ponza",
            "YachtPonzaPrice": "A partire da €100 a persona",
            "YachtPonzaDesc": "Vivi un'esperienza indimenticabile con una crociera per 2/6 persone in barca a vela. ",
            "YachtPonzaDesc2": "Imbarco il venerdì pomeriggio da Ostia, Civitavecchia o Anzio.",
            "YachtPonzaDesc3" :"Sbarco la domenica sera.",
            "YachtPonzaDesc4": "Sarai accompagnato da un esperto skipper professionista, garantendo sicurezza e comfort durante tutto il viaggio.",
           
           
            "Imbarco": "Imbarco",
            "ImbarcoDescrizione": "Partenza il venerdì pomeriggio da una delle seguenti località: Ostia, Civitavecchia o Anzio. Preparati a iniziare il tuo week-end di relax e avventura.",
            "Sbarco": "Sbarco",
            "SbarcoDescrizione": "Il tuo viaggio si concluderà la domenica sera, con lo sbarco previsto presso la località di partenza. Rientra a casa con ricordi indimenticabili.",
            "Equipaggio": "Equipaggio",
            "EquipaggioDescrizione": "Il nostro skipper professionista, con anni di esperienza, ti accompagnerà durante tutta la crociera, assicurandoti un viaggio sicuro e piacevole.",

            
              "YachtCrocieraTitle": "Crociera Personalizzata di Lusso",
              "YachtCrocieraPrice": "Prezzo su misura",
              "YachtCrocieraDesc": "Scopri il piacere di una crociera personalizzata, ideale per gruppi da 2 a 8 persone a bordo di una barca a vela o a motore.",
              "YachtCrocieraDesc2": "Imbarco disponibile presso le località di Ostia, Civitavecchia o Anzio.",
              "YachtCrocieraDesc3": "Ogni crociera è accompagnata da un esperto skipper professionista.",
              "YachtCrocieraDesc4": "Goditi un'esperienza su misura, progettata per offrire il massimo comfort e avventura.",

              
                "YachtCatamaranoTitle": "Avventura in Catamarano",
                "YachtCatamaranoPrice": "A partire da €150 a persona",
                "YachtCatamaranoDesc": "Vivi un'avventura indimenticabile a bordo di un catamarano di lusso, perfetto per gruppi da 2 a 10 persone.",
                "YachtCatamaranoDesc2": "Imbarco previsto dalle località di Ostia, Civitavecchia o Anzio.",
                "YachtCatamaranoDesc3": "Sbarco alla fine della giornata o del week-end, a seconda del pacchetto scelto.",
                "YachtCatamaranoDesc4": "Accompagnati da un equipaggio professionale, ti garantirai un'esperienza sicura e confortevole.",
              
              
            
            
          
          "MetaSaracinescoTitle": "Volo in elicottero con pranzo: presso “La Baita” - Saracinesco ",
          "MetaSaracinescoDesc": "Volo in elicottero con pranzo: il pilota vi porterà al ristorante la baita a Saracinesco, dove potrete magiare un ottima cucina, immersi nel verde in una bellissima baita ",
          'YachtCatamaranoTitle' : "Crociera su catamarano",
          "Iscriviti": 'Iscriviti',
          "MetaPalianoTitle": "Volo in elicottero con pranzo: alla Polledrara dei Principi Colonna ",
          "MetaPalianoDesc": "Volo in elicottero con pranzo: il pilota vi porterà all’agriturismo Polledrara dei Principi Colonna, a Paliano in Provincia di Frosinone ",
          "ServiziAggiuntivi": "Servizi Aggiuntivi",
          "Passeggeri" : "Passeggeri",
          "TrasferimentoAuto": "Trasferimento in Auto",
          "VideoMaker": "VideoMaker",
          "YachtPageDesc": "Scopri i nostri esclusivi yacht di lusso per un'esperienza indimenticabile con L.A.R.",
          "YachtTransfer": "La Luxury Agency Rome offre ai propri clienti trasferimenti esclusivi in Italia e all'Estero con lussuosi Yacht privati. <br/> <br/> Scegli la tua destinazione, sali a bordo e vivi l'esperienza del lusso e del comfort in mare aperto.  <br/>I nostri yacht sono dotati di tutti i comfort e gestiti da un equipaggio professionale per garantire un viaggio indimenticabile.",

          'TrasferimentoAutoDesc': "Un autista vi verrà a prendere a casa o nel vostro hotel, vi porterà alla base di decollo e finita l’esperienza vi riporterà a destinazione.",
          "VideoMakerDesc" : "Un videomaker professionista vi accompagnerà durante l'esperienza, dalla partenza all'arrivo. Realizzerà un video ricordo da tenere per sempre!",
          "MetaEcoTitle": "Tour in Elicottero Economy: Tivoli o Lago di Bracciano",
          "MetaEcoDesc": "Tour in Elicottero Economy: decollo dalla nostra aviosuperficie direzione Tivoli dove potremmo ammirare le fantastiche cascate Villa d’Este e Villa Adriana",
          "MetaCampotostoTitle": "Volo in elicottero con pranzo a Campotosto",
          "MetaCampotostoDesc": "Fantastico tour sulle sponde di uno dei laghi principali del centro Italia. Decolleremo dalla nostra elisuperficie direzione lago di Campotosto, dopo aver ammirato dall’alto i nostri Appennini ci troveremo in questo piccolo scorcio di paradiso nel cuore dell Abruzzo, dove sorvoleremo la riva del lago ammirando il gran sasso d’Italia. Atterreremo presso il ristorante da Serena dove mangeremo prodotti tipici locali. Dopo una passeggiata in riva al lago si farà ritorno.",
          "MetaVescovioTitle": "Volo in elicottero con pranzo: a Oasi di Vescovio",
          "MetaVescovioDesc": "Dopo aver sorvolato il Castello Odescalchi, vireremo con destinazione l’Oasi di Vescovio dove potremo gustare le specialità cucina tipica locale.",
          "MetaPubAereaTitle":"Pubblicità Aerea",
          "MetaPubAereaDesc": "La Luxury Agency Rome è leader nel settore della pubblicità aerea. Con la nostra pubblicità, il tuo messaggio raggiungerà migliaia di persone in un tempo record. ",
          "InvioInCorso": "Invio in corso..",
          "Economy": 'Economy',
          "JetTitle": "Jet Privati - L.A.R.",
          "JetDescription": "Jet privati e viaggi di lusso",
          "JetPrivati": "Jet Privati",
          "JetGeneralDescription": "La Luxury Agency Rome offre ai propri clienti trasferimenti in Italia e all'Estero con lussuosi jet privati. <br/> Scegli la destinazione, vai in aeroporto e senza fare file per check in o code per i controlli di sicurezza, decollerai velocemente verso la tua meta.",
          "JetWhyChoose": "Perché scegliere di viaggiare con un jet privato?",
          "JetWhyChooseDescription": "Ci sono molti motivi per cui risulta più efficace viaggiare in jet, la comodità di scegliere orari personalizzati in tempi brevi, destinazioni non coperte dalle compagnie commerciali. Il lusso e il team professionale vi accompagnerà in tutto comfort a destinazione.",
          "JetBusinessTitle": "Sei un cliente business?",
          "JetBusinessDescription": "Se viaggi spesso per lavoro, con i nostri jet i tempi di percorrenza <br/> tra una città e l'altra saranno molto più efficienti e comodi.",
          "JetFamilyTitle": "Famiglia",
"JetFamilyDescription": "Con i nostri jet privati, i viaggi in famiglia diventano un'esperienza di lusso e comfort unici. Ogni dettaglio è curato per garantirvi il massimo relax durante il viaggio.",          "JetFleetTitle": "La nostra flotta",
          "JetModel1Title": "Modello 1",
          "JetModel1Description": "Descrizione del modello 1",
          "JetModel2Title": "Modello 2",
          "JetModel2Description": "Descrizione del modello 2",
          "JetModel3Title": "Modello 3",
          "JetModel3Description": "Descrizione del modello 3",
          "JetModel4Title": "Modello 4",
          "JetModel4Description": "Descrizione del modello 4",
          "Newsletter": "Iscrivti alla L.A.R. Newsletter",
          "Newsletter2": "Inserisci la tua email e ricevi delle notifiche <br/> sui nostri nuovi articoli del Blog!",
            // PACCHETTI PERSONALIZZATI ELICOTTERI:
            "EconomyPackageTitle": "Tour in Elicottero Economy: Tivoli o Lago di Bracciano",
            "EconomyPackageDetails": {
              "description": "Per singoli che desiderano provare un’emozione diversa; per coppie o piccoli gruppi che vogliono provare la prima esperienza di volo in elicottero. <br/> <br/> Decollo dalla nostra aviosuperficie direzione Tivoli per ammirare le fantastiche cascate Villa d’Este e Villa Adriana; oppure decollo con direzione Bracciano dove sorvoleremo due dei laghi più importanti d’Italia: Martignano e Bracciano,  osservando anche il Castello Odescalchi e circuito di Vallelunga.  ",
              "flightDuration": "~30 minuti",

              "MetaEcoDesc": "Tour in Elicottero Economy: decollo dalla nostra aviosuperficie direzione Tivoli dove potremmo ammirare le fantastiche cascate Villa d’Este e Villa Adriana ",
              "transfer": "Un autista vi verrà a prendere a casa, vi porterà sulla piazzola di decollo e finito il tour vi riporterà a casa.",
              "video": "Un videomaker vi accompagnerà per tutta l’esperienza, vi scatterà foto e video che potrete rivedere in qualsiasi momento."
            },
            "PremiumPackageTitle": "Tour in Elicottero Premium: Tour dei Castelli Romani",
            "Premium": 'Premium',
            "MetaPremiumTitle": "Tour in Elicottero Premium: Tour dei Castelli Romani  ",
            "MetaPremiumDesc": "Tour in Elicottero Premium: decollo dalla nostra aviosuperficie direzione Castelli Romani, dove sorvoleremo il grande lago di Nemi e Castel Gandolfo.",
            "PremiumPackageDetails": {
              "description": "• Per singoli che desiderano provare un’emozione diversa <br/> • Per coppie o piccoli gruppi che vogliono provare la prima esperienza di volo in elicottero. <br/> <br/> Decollo dalla nostra aviosuperficie direzione Castelli Romani, dove sorvoleremo il grande lago di Nemi e Castel Gandolfo.  ",
              "flightDuration": "~45 minuti",
              "transfer": "Un autista vi verrà a prendere a casa, vi porterà sulla piazzola di decollo e finito il tour vi riporterà a casa.",
              "video": "Un videomaker vi accompagnerà per tutta l’esperienza, vi scatterà foto e video che potrete rivedere in qualsiasi momento."
            },


            "MetaLuxuryTitle": "Tour in Elicottero Luxury: Tout dei Castelli Romani",
            "MetaLuxuryDesc": "Tour in Elicottero Luxury: decollo dalla nostra aviosuperficie direzione Castelli Romani dove sorvoleremo il grande lago di Nemi e Castel Gandolfo. ",
            "LuxuryPackageTitle": "Tour in elicottero Luxury: Tour in elicottero dei Castelli Romani",
            "Luxury": "Luxury",
            "LuxuryPackageDetails": {
              "flightDuration": "~60 minuti",
              "description": "• Per singoli che desiderano provare un’emozione diversa. <br/> • Per coppie o piccoli gruppi che vogliono provare la prima esperienza di volo in elicottero. <br/> <br/> Decollo dalla nostra aviosuperficie direzione Castelli Romani, dove sorvoleremo il grande lago di Nemi e Castel Gandolfo."
            },

            "VoloConPranzo": 'Volo con Pranzo',
            "VoloConPranzoDetails": {
              "VoloConPranzo1": "Baita Saracinesco",
              "descriptionVolo1": "Bellissimo tour per chi ama la natura, adatto a tutti. <br/> Decollo dalla nostra base, sorvolo della citta di Tivoli dove potremo ammirare dall’alto <br/> Villa Adriana, Villa d’Este e le bellissime cascate. <br/> <br/> Dopo questo giro su Tivoli il pilota vi porterà al ristorante La baita a Saracinesco, <br/> dove potrete magiare un ottima cucina immersi nel verde in una baita bellissima.  <br/> Dopo pranzo potrete passeggiare o rimanere in relax fino al momento <br/> che si risale a bordo per volare verso casa.",
              "VoloConPranzo2": "Polledrara a Paliano",
              "descriptionVolo2": "Bellissimo tour alle porte di Roma. <br/> Decollo dalla nostra base con direzione Tivoli dove potremmo ammirare  <br/> Villa Adriana, Villa d’Este, le bellissime cascate ed una bellissima vista di tutta Roma. <br/> Dopo questo giro panoramico vireremo verso l’agriturismo <br/> La Polledrara dei Principi<br/><br/> Colonna situata a Paliano in provincia di Frosinone. <br/> Dopo un fantastico pranzo e del relax decollerà di nuovo verso la base.",
              "VoloConPranzo3" : "Villa a Ponzano",
              "descriptionVolo3": "Fantastico tour per chi ama la natura! <br/> <br/>Si decolla dalla nostra base e voleremo su rotte <br/> dalle quali sarà possibile vedere alcuni dei monumenti piu importanti di Roma, <br/> vireremo e ci allontaneremo da Roma per circa 32 miglia fino a Ponzano Romano. <br/> <br/>Una volta atterrati pranzeremo in una località che offre prodotti ottimi prodotti tipici. <br/> Dopo pranzo potremo scegliere se passeggiare, andare a cavallo o goderci il panorama montuoso. <br/> Nel pomeriggio si decollerà in direzione roma per tornare a casa.",
              "VoloConPranzo4": "Oasi di Vescovio",
              "descriptionVolo4": "Fantastico tour giornaliero adatto a coppie amici o famiglie. <br/> <br/> Decolleremo dalla nostra piazzola e voleremo su rotte dalla quale potremo ammirare <br/> alcuni dei monumenti piu importanti di Roma, proseguendo vesro il lago di Bracciano e Calcata Vecchia. <br/> <br/>  Dopo aver sorvolata anche il castello Odescalchi, vireremo con destinazione L’oasi di Vescovio  <br/> dove ci aspetterà un team pronto a farvi provare la cucina tipica locale. <br/><br/> Dopo il pranzo, relax e come siamo pronti si rientrerà alla base in elicottero.",
              "VoloConPranzo5": 'Lago di Campotosto e Gran Sasso',
              "descriptionVolo5": 'Fantastico tour sulle sponde di uno dei laghi principali del centro Italia. <br/> Decolleremo dalla nostra elisuperficie direzione lago di Campotosto, <br/> dopo aver ammirato dall’alto i nostri Appennini <br/> ci troveremo in questo piccolo scorcio di paradiso nel cuore dell Abruzzo, <br/> dove sorvoleremo la riva del lago ammirando il gran sasso d’Italia. <br/> Atterreremo presso il ristorante da Serena dove mangeremo prodotti tipici locali. <br/> Dopo una passeggiata in riva al lago si farà ritorno.'
            },
            "VoloAvventura" : "Volo Avventura",
            "VoloAvventuraTitle": "Giro in Elicottero con Passeggiata a Cavallo o giro in Quad.",
            'VoloAvventuraIntro' : "Attraversa a cavallo i sentieri immersi nella natura, per poi salire a bordo di un quad per vivere l'emozione di un adrenalinico giro fuoristrada. <br/> Sfida terreni accidentati e metti alla prova le tue capacità di guida. ",
            "VoloAvventuraDetails": {
              "VoloAvventura1": "Volo più Pranzo ad Alta Quota",
              "desc": "Decolleremo dalla nostra base, attraverseremo gli Appennini fino a raggiungere Vallemare, nella provincia di Rieti, a quota 1000 metri. <br/> <br/> Qui ci aspettano i nostri cavalli o i nostri quad, che, insieme alla guida, ci condurranno in un giro di circa due ore attraverso le splendide montagne circostanti. <br/> <br/> All’ora di pranzo, ci recheremo in una località privata, dove uno chef ci cucinerà i prodotti locali (pasta e carne alla griglia).  <br/> Avremo anche la possibilità di fare un tuffo in una fantastica piscina di pietra con acqua riscaldata mentre ci godiamo il panorama della valle. <br/> <br/> Dopo pranzo, ripartiremo verso casa."
            },
            "Attivita": "Attività",
            "Quad": "Giro in Quad",
            "Cavallo": "Escursioni a Cavallo",
            "Itinerario": "Itinerario",
            "PartnerTitle": 'I nostri Partners',
            "Payment": "Pagamenti sicuri tramite",
        }
        
    },
    en: {
        translation: {
          "Seguici": 'Follow us on Socials!',
          "Click": 'Click here!',
          "ServicePhrase1": "Luxury Helicopter Tours",
          "ServicePhrase2": "Private Jets",
          "ServicePhrase3": "Supercar Rental",
          "ServicePhrase4": "Exclusive Experiences",
          "ServicePhrase5": "Flight with Lunch",
          "ServicePhrase6": "Aerial Advertising",
          "ServicePhrase7": "Adventure Trips",
          "ServicePhrase8": "Helicopter and Jet Transfers",
          "ServicePhrase9": "Chauffeur Service",
          "Payment": "Easy Payments with",
          "YachtPageDesc": "Discover our exclusive luxury yachts for an unforgettable experience with L.A.R.",
          "YachtTransfer": "Luxury Agency Rome offers its clients exclusive transfers in Italy and abroad with luxurious private Yachts. <br/> <br/> Choose your destination, board the yacht, and experience luxury and comfort on the open sea. <br/> Our yachts are equipped with all the comforts and managed by a professional crew to ensure an unforgettable journey.",

          "Iscriviti": 'Subscribe',
          "Newsletter": "Subscribe to L.A.R. newsletter!",
          "Newsletter2": "Enter your email and receive notifications <br/> about our new blog articles!",
          "MetaHomeTitle": "L.A.R. - Luxury Agency Rome - Helicoper tours and Luxury services",
          "MetaHomeKeyw": "helicopter, Rome, agency, lux, holiday,  excursion, tour, luxury, travel, luxury agency rome, private jet, supercar, rental, LAR, business, ",
          "MetaHomeDesc": "Experience Italy from above with an unforgettable helicopter tour: Luxury Agency Rome offers customized tours to explore historic cities and breathtaking landscapes!",
          "MetaEliTitle": "Helicopter tour Italy: private helicopter rental for couples/groups",
          "MetaEliKeyw": "helicopter, Rome, agency, lux, holiday,  excursion, tour, luxury, travel, luxury agency rome, private jet, supercar, rental, LAR, business, ",
          "MetaEliDesc": "Experience Italy from above with an unforgettable helicopter tour: Luxury Agency Rome offers customized tours to explore historic cities and breathtaking landscapes!",
          "Attivita": "Activities",
          "PartnerTitle": 'Our Partners',
          "Open": "Open",
          "Scopri": "Discover",
          "Close":"Close",
          "Cavallo": "Horseback excursions",
          "Quad": "Quad ride",
          "Destinazioni": 'Our Destinations',
          "Avanti": "Next",
          "Indietro" : "Previous",
          "Home": "Home",
          'Lar': "Specializing in luxury air tours, we are committed to providing personalized experiences <br /> that range from scenic flights over Rome's historic monuments <br/> to custom adventures in Italy’s breathtaking landscapes. <br/>  <br/> Our fleet is equipped with the latest technology and operated by professional pilots, <br/> ensuring not only safety but also comfort and adventure!",
          "Simone": "Simone's Story",
          "story-title": "The Story of Simone, Owner of LAR - Luxury Agency Rome<br/>A Childhood Imprinted on Commerce and the Dream of Flying",
            "story1": "Simone, a 32-year-old man, was born into a family with a strong entrepreneurial tradition in the seaside resort sector, owning the famous 'Tibidabo' beach resort in Ostia.",
            "story2": "His professional story begins when, following the sale of the resort, he finds himself working alongside his father, grandfather, and uncle in a well-known plumbing and heating store on the Roman coast.<br/>While training in the plumbing and heating trade, showing uncommon tenacity and dedication to business, Simone decides to pursue an educational path in advertising graphics.",
            "story3": "Since childhood, however, Simone harbored another great passion: flying. He dreamed of soaring the skies as a commercial airline pilot!<br/>This passion led him to prepare for a commercial pilot competition with Alitalia, investing a great deal of energy, time, and money. Unfortunately, the selection process did not go well, and Simone missed obtaining his pilot's license by a 'whisker'.",
            "story4": "Despite this disappointing experience, his passion for flying grew day by day, fostering a new goal in him: obtaining a commercial helicopter pilot's license.<br/>But before completing this path, Simone decided to convert his commercial helicopter pilot's license into a private one, so he could use this qualification as a hobby, while still preserving his duties towards the family business.",
            "story5": "Not long ago, Simone took a decisive step: he sold the plumbing and heating store and decided to realize another big dream: buying an airplane for towing advertising banners.<br/>Thus was born LAR - Luxury Agency Rome, a luxury vehicle rental agency offering an exclusive experience to its clients: helicopters, jets, yachts, and boats for transfers by air, land, and water.",
            "story6": "An Example of Tenacity and Perseverance<br/><br/>Simone's story is an example of tenacity and perseverance. Despite changes in direction and challenges faced, he has managed to transform his passions into a successful enterprise.<br/>LAR - Luxury Agency Rome represents the fulfillment of a dream, a perfect union of passion for flying, entrepreneurial spirit, and the ability to offer a unique and exclusive service.",
        

          "RispostaOk": "Your reply has been sent successfully. <br/> You can close this page.",
          "Nome": "Full name",
          "Invia": 'Send Request',
          "Messaggio": "Message",
          "Telefono": "Telephone",
          "HomeIntro": "Luxury Agency Rome offers unforgettable experiences throughout Italy.",
          "HomeIntro2": " Whether by air, land, or sea, we are ready to meet your needs <br/> by creating tailor-made packages for every type of requirement.",
          "Elicotteri": "Helicopters",
          "NoleggioAuto" : "Car Rent",
          "ChiSiamo": "About Us",
          "Servizi" : "Services",
          "Contatti": 'Contact us',
          "JetTitle": "Private Jets - L.A.R.",
          "JetDescription": "Private jets and luxury travel",
          "JetPrivati": "Private Jets",
          "JetGeneralDescription": "Luxury Agency Rome offers its clients transfers in Italy and abroad with luxurious private jets. Choose the destination, go to the airport, and without queuing for check-in or security checks, you will quickly take off towards your destination.",
          "JetWhyChoose": "Why choose to travel by private jet?",
          "JetWhyChooseDescription": "There are many reasons why traveling by jet is more efficient, the convenience of choosing personalized schedules in a short time, destinations not covered by commercial airlines. The luxury and professional team will accompany you in full comfort to your destination.",
          "JetBusinessTitle": "Are you a business client?",
          "JetBusinessDescription": "If you travel frequently for work, with our jets the travel times between cities will be much more efficient and comfortable.",
          "JetFamilyTitle": "Family",
"JetFamilyDescription": "With our private jets, family trips become a unique experience of luxury and comfort. Every detail is attended to, ensuring maximum relaxation during your journey.",          "JetFleetTitle": "Our Fleet",
          "JetModel1Title": "Model 1",
          "JetModel1Description": "Description of model 1",
          "JetModel2Title": "Model 2",
          "JetModel2Description": "Description of model 2",
          "JetModel3Title": "Model 3",
          "JetModel3Description": "Description of model 3",
          "JetModel4Title": "Model 4",
          "JetModel4Description": "Description of model 4",
          "TipoReq": "Select a service",
          "Passeggeri" : "Passengers",
            "MetaPilotaGiornoTitle": "Helicopter Pilot for a Day: Live the Thrill!",
            "MetaPilotaGiornoDesc": "Have you always dreamed of flying? Now you can make your dream come true and feel like a pilot for a day! The instructor will guide you step by step, teaching you how to control the helicopter in flight.",
            "PilotaGiornoTitle": "Pilot for a Day",
            "PilotaGiornoIntro": "Become a pilot for a day: experience the thrill of a helicopter flight!",
            "PilotaGiornoDesc1": "Become a helicopter pilot for a day: experience the thrill of a helicopter flight! <br/> Have you always dreamed of flying? Now you can make your dream come true and feel like a pilot for a day!",
            "PilotaGiornoDesc2": "Get on board our helicopter and get ready for an unforgettable experience. <br/> Under the guidance of an expert instructor, you will learn the basics of piloting <br/> and have the opportunity to take the controls of the helicopter.",
            "PilotaGiornoDesc3": "You will fly over breathtaking landscapes, <br/> enjoying a privileged view that will leave you breathless. <br/> <br/>Admire historical cities, pristine coasts, majestic mountains, or picturesque vineyards from above.",
            "PilotaGiornoDesc4": "During the flight, the instructor will guide you step by step, <br/> teaching you how to control the helicopter in flight and perform simple maneuvers. <br/> You will have the chance to experience the thrill of takeoff, <br/> landing, and flying over different terrains.",
          "PubblicitaAerea": "Air Advertising",
          "PubblicitaAereaDesc": "The Luxury Agency Rome is the leader in the air advertising sector. We cover the entire national territory, flying over Italy far and wide, reaching all the beaches. Our service is suitable for everyone... Whether you want to give a gift, promote your company, celebrate a communion, or a baptism, we can fulfill all your requests. With our advertising, your message will reach thousands of people in record time. Call us and put us to the test.",
          "PubblicitaAereaInfo": "Want to send your message to a VIP in Italy's most famous house? Contact us.",

          "JetPrivati": "Private Jets",
          "Yacht": "Yachts and Boats",
          "YachtDesc": "Luxury Agency Rome offers unforgettable experiences sailing the seas throughout Italy, creating packages from a simple weekend up to real personalized cruises on every type of vessel. We have sailing boats of all types, motor boats, beautiful catamarans and yachts available.",
          "FastResponse": "We guarantee a quick response to all inquiries!",
            "NostriServizi": "Our Services",
          "Diario": "LogBook",
          "Itinerario": "Itinerary",
          "GalleryIntro": "Luxury Agency Rome offers unforgettable experiences throughout Italy. <br/> <br/> Whether by Air, Land, or Sea, <br/> we are ready to fulfill your requests. <br/> <br/> Creating tailor-made packages for every type of need." ,
          "Select": "Select",
          "MetaPremiumTitle": "Premium Helicopter Tour: Castelli Romani Tour",
          "MetaPremiumDesc": "Premium Helicopter Tour: take off from our airfield heading towards the Castelli Romani, where we will fly over the great Lake Nemi and Castel Gandolfo.",
          "MetaLuxuryTitle": "Luxury Helicopter Tour: Castelli Romani Tour",
"MetaLuxuryDesc": "Luxury Helicopter Tour: Take off from our airfield heading towards the Castelli Romani, where we will fly over the great Lake Nemi and Castel Gandolfo.",
          "Prenota": "Contact Us",
          "ContattiButton": 'CONTACT US',
          "ContactClick": "Click here! <br/> Send us a message to request <br/> information and quotes for our services.",

            "PilotaPerUnGiornoTitle": "Pilot for a Day",
            "PilotaPerUnGiornoIntro": "Thanks to Luxury Agency Rome, you can fulfill your dream of becoming a professional pilot.",
            "PilotaPerUnGiornoDesc": "The 'Pilot for a Day' package includes a theoretical classroom lesson with one of our professional pilots who will introduce you to the world of aviation, a machine course accompanied by a professional technician who will explain how the helicopter works, its main parts, and its limitations, and finally, a flight of about 20 minutes in dual control accompanied by one of our pilots who will let you pilot and explain the basic concepts of flying.",
            "TourPersonalizzatiTitle": "Customized Helicopter Tours and Trips.",
            "ServiziPlusIntro": "Our packages include additional services such as Professional VideoMaker and Private Driver. [Info]",
            "TourPersonalizzatiIntro" : "Discover Italy in a unique way with the personalized tours offered by Luxury Agency Rome.",
            "TourPersonalizzatiDesc": "From the magical waterfalls and historic villas of Tivoli to the picturesque lakes and castles around Bracciano, to the renowned Castelli Romani, each tour is designed to provide an exclusive and memorable experience. Choose from economy, premium, and luxury options to perfectly suit your needs, with additional services like private car transfers and a personal videographer to capture every moment of your spectacular journey.",
            "ViaggiAffariTitle": "Business Travels",
            "ViaggiAffariIntro": "Luxury Agency Rome offers helicopter transfers for businesspeople and VIPs.",
            "ViaggiAffariDesc": "If you need to travel across Italy in the shortest possible time, this is the right solution for you. Targeted flights that move you quickly, with utmost privacy and safety, accompanied by professional pilots.",
            "ElicotteriVenditaTitle": "Helicopters for Sale",
            "ElicotteriVenditaIntro": "",
            "InvioInCorso": "Sending...",
            "MessaggioInviato": "Message Sent ✅",
            "InvioFallito": "Error while sending request.",
            "ElicotteriVenditaDesc": "A wide range of helicopters for sale to meet every need.",
            "ElicotteriTitle": "Helicopter Services",
            "ElicotteriFrase": "''Each of us has a pair of wings, but only those who dream learn to fly.''",
            "ServizioElicotteriDesc": "Discover the allure of flight with our exclusive helicopter tours, <br/> rapid business transfers and the unique opportunity to become a pilot for a day, <br/>complete with theoretical and practical lessons.  <br/>Explore the beauty of Italy from above and enjoy a tailored luxury experience for every need.",
            "ServizioElicotteriDesc2": "Experience Italy from above with an unforgettable helicopter ride. <br/> Helicopter tours in Italy with Luxury Agency Rome: turn your dreams into reality with exclusive and customized experiences throughout Italy! <br/> <br/> Whether you're looking for a helicopter rental for business or pleasure, or if you're searching for a helicopter flight gift for a loved one, you've landed on the right site! <br/> Whether you are an adventure enthusiast or simply looking <br/> for a unique way to admire the breathtaking beauty of the Bel Paese, a helicopter ride is ideal for you. <br/> <br/> Luxury Agency Rome offers a wide range of helicopter tours to meet every need and budget. <br/>Our experts will carefully select the perfect itinerary for you, ensuring a safe and unforgettable experience.",
            "Scopri": "Discover",
            "ServizioElicotteri": "Helicopters",       
            "NoleggioTitle": "Super Car",
            "DurataDelVolo" : 'Flight duration:',
            "MetaPonzanoTitle": "Volo in elicottero con pranzo: a Ponzano Romano ",
            "MetaPonzanoDesc": "Sorvoleremo alcuni dei monumenti più importanti di Roma, poi vireremo e ci allontaneremo dalla Capitale per circa 32 miglia fino a Ponzano Romano. ",
            "MetaPalianoTitle": "Helicopter Flight with Lunch at 'Polledrara dei Principi Colonna'",
            "MetaPalianoDesc": "Helicopter flight with lunch: the pilot will take you to the 'Polledrara dei Principi Colonna' farm in Paliano, Frosinone Province.",            
            "MetaAvventuraTitle": "Helicopter Flight with Horseback Riding or Quad Bike Tour",
            "MetaAvventuraDesc": "Our horses or quad bikes await you, which, along with a guide, will take you on a roughly two-hour tour through the beautiful surrounding mountains.",            
            "MetaTourTitle": "Helicopter Flight Packages: From Lunch Flights to Adventure Flights",
"MetaTourDesc": "Discover our customized helicopter tours. From the Economy package to Luxury. From lunch flights to adventure flights on horseback and quad!",
            "NoleggioDesc": "Travel in style and comfort with our luxury car rental service. Choose from a wide range of prestigious vehicles for any occasion, ensuring an exclusive and personalized experience. Enjoy the ultimate in luxury and discretion, with professional chauffeur options available to ensure your utmost relaxation.",
            "MetaAvventuraTitle": "Helicopter Flight with Horseback Riding or Quad Bike Tour",
"MetaAvventuraDesc": "Our horses or quad bikes await you, which, along with a guide, will take you on a roughly two-hour tour through the beautiful surrounding mountains.",
            "ServiziAggiuntivi": "Additional Services",
            "TrasferimentoAuto": "Transfer by Car",
            "VideoMaker": "VideoMaker",
            'TrasferimentoAutoDesc': "A driver will pick you up at home or at your hotel, take you to the take-off base and at the end of the experience he will take you back to your destination.",
            "VideoMakerDesc" : "A professional videomaker will accompany you throughout the experience, from the moment of departure to the moment of arrival. It will make a fantastic souvenir video to watch whenever you want",
            "MetaVescovioTitle": "Helicopter Flight with Lunch: at Oasi di Vescovio",
            "MetaVescovioDesc": "After flying over Odescalchi Castle, we will head towards Oasi di Vescovio, where we can enjoy the local culinary specialties.",            
            "SuperCarIntro": 'Discover our SuperCars collection',
            "YachtFrase": "You can never cross the ocean if you don't have the courage to lose sight of the shore.",
            "YachtPonzaTitle": "Weekend in Ponza",
            "YachtPonzaPrice": "Starting from €100 per person",
            "YachtPonzaDesc": "Cruise for 6 people <br/> Sailboat with 3 double cabins <br/> Boarding on Friday afternoon in Nettuno <br/> Disembarking Sunday evening in Nettuno <br/> Professional skipper",
            "MetaPonzanoTitle": "Helicopter Flight with Lunch: in Ponzano Romano",

            "MetaPonzanoDesc": "We will fly over some of the most important monuments of Rome, then turn and head about 32 miles away from the Capital to Ponzano Romano.",            
            "YachtCrocieraTitle": "Customized Cruise",
            "YachtCrocieraPrice": "Price upon request",
            
            "YachtCrocieraDesc": "Cruise for 2-8 people <br/> Sailing boat or motorboat <br/> Embarkation and disembarkation in Rome or <br/> embarkation and disembarkation of choice based on destination <br/> Professional Skipper",

            "YachtCatamaranoTitle": "Catamaran Cruise",
            "YachtCatamaranoPrice": "Price upon request",
            "YachtCatamaranoDesc": "Cruise for 8 people <br/> Sailing area: Northern and Southern Sardinia <br/> Professional skipper",
            "Back": "Back",

            "MetaEcoTitle": "Economy Helicopter Tour: Tivoli or Lake Bracciano",
            "MetaEcoDesc": "Economy Helicopter Tour: Take off from our airfield towards Tivoli where we will admire the fantastic waterfalls, Villa d'Este, and Villa Adriana.",
            "TourEliTitle": "Helicopter tour packages",
            "TourEliSub": "Choose the package you prefer, based on your needs and budget: helicopter flight for 1/2 people or for small groups.",
            "TourSub": "Helicopter Flight Packages: from Economy to Luxury packages, from Flights with Lunch to Adventure Flights",
            ///// PACCHETTI PERSONALIZZATI ELICOTTERI
            "EconomyPackageTitle": "Economy",
            "EconomyPackageDetails": {
              "people": "2-4",
              "description": "Helicopter tour of Tivoli or Lake Bracciano. Take off from our airfield towards Tivoli where we can admire the fantastic waterfalls, Villa d’Este, and Villa Adriana or towards Bracciano where we will fly over two of the most important lakes in Italy, Martignano and Bracciano, the Odescalchi Castle, and the Vallelunga circuit.",
              "flightDuration": "~30 mins",
              "transfer": "A driver will pick you up from home, take you to the takeoff pad, and return you home after the tour.",
              "video": "A videographer will accompany you throughout the experience, taking photos and videos that you can review at any time."
            },
            "PremiumPackageTitle": "Premium",
            "PremiumPackageDetails": {
              "people": "4-6",
              "description": "Helicopter tour of the Castelli Romani. Take off from our airfield towards the Castelli Romani where we will fly over the large lake of Nemi and Castel Gandolfo.",
              "flightDuration": "~45 mins",
              "transfer": "A driver will pick you up from home, take you to the takeoff pad, and return you home after the tour.",
              "video": "A videographer will accompany you throughout the experience, taking photos and videos that you can review at any time."
            },
            "LuxuryPackageTitle": "Luxury",
            "LuxuryPackageDetails": {
              "people": "4-6",
              "flightDuration": "~60 mins",
              "description": "Take off from our airfield towards Tivoli where we can admire the fantastic waterfalls, Villa d’Este, and Villa Adriana, then head towards Bracciano where we will fly over two of the most important lakes in Italy, Martignano and Bracciano, Odescalchi Castle, Trevignano, Calcata Vecchia, and the Vallelunga circuit.",
              "transfer": "A driver will pick you up from home, take you to the takeoff pad, and return you home after the tour.",
              "video": "A videographer will accompany you throughout the experience, taking photos and videos that you can review at any time."
            },
            "VoloConPranzo": "Flight with Lunch",
            "VoloConPranzoDetails": {
              "VoloConPranzo1": "Baita Saracinesco",
              "descriptionVolo1": "A beautiful tour for nature lovers, suitable for everyone. <br/> <br/> Take off from our base, fly over the city of Tivoli where we can admire Villa Adriana, Villa d'Este, and the beautiful waterfalls from above.  <br/> <br/> After this tour around Tivoli, the pilot will take you to La Baita restaurant in Saracinesco, <br/> where you can enjoy excellent cuisine immersed in nature in a beautiful cabin. <br/> <br/>After lunch, you can walk or relax until it's time to board again and fly home.",
              "VoloConPranzo2": "Polledrara at Paliano",
              "descriptionVolo2": "A beautiful tour on the outskirts of Rome, <br/> take off from our base towards Tivoli <br/> where we can admire Villa Adriana, Villa d'Este, and the beautiful waterfalls, and a wonderful view of all of Rome. <br/> <br/> After this panoramic tour, we will head towards <br/> the agritourism La Polledrara dei Principi Colonna located in Paliano in the province of Frosinone. <br/> <br/> After a fantastic lunch and some relaxation, you will take off again towards the base.",
              "VoloConPranzo3": "Villa in Ponzano",
              "descriptionVolo3": "Fantastic tour for nature lovers, we take off from our base and fly routes <br/> from which we can see some of the most important monuments of Rome, <br/> we will veer and move away from Rome for about 32 miles to Ponzano Romano. <br/><br/> Once landed, we will have lunch in a location that offers excellent typical products. <br/><br/> After lunch, we can choose to take a walk, go horseback riding, <br/> or enjoy the mountainous landscape. <br/>In the afternoon, we will take off towards Rome to return home.",
              "VoloConPranzo4": "Oasis of Vescovio",
              "descriptionVolo4": "Fantastic day tour suitable for couples, friends, or families. <br/><br/> We will take off from our platform and fly routes from which <br/> we can admire some of the most important monuments of Rome, <br/>continuing towards Lake Bracciano and Old Calcata. <br/><br/> After flying over Odescalchi Castle as well, we will head towards the Oasis of Vescovio <br/> where a team will be ready to let you try the local cuisine. <br/><br/> After lunch, relax and as soon as we are ready, <br/>we will return to the base by helicopter.",
              "VoloConPranzo5": "Campotosto Lake and Gran Sasso",
              "descriptionVolo5": "Fantastic tour along the shores of one of the main lakes in central Italy. <br/> We will take off from our helipad towards Campotosto Lake. <br/> After admiring the Apennines from above, <br/> we will find ourselves in this little glimpse of paradise in the heart of Abruzzo, <br/> where we will fly over the lake shore admiring the Gran Sasso of Italy. <br/> We will land at the restaurant Da Serena where we will eat typical local products. <br/> After a walk along the lake shore, we will return."
            },
            "VoloAvventura" : "Adventure Flight",
            'VoloAvventuraIntro' : "Are you a nature lover or do you enjoy adventure? We have the perfect package for you. ",
            "VoloAvventuraDetails": {
              "VoloAvventura1": "Flight plus High-Altitude Lunch",
              "desc": "We'll take off from our base and cross the Apennines to reach Vallemare, in the province of Rieti, at an altitude of 1000 meters. Here, our horses or quads, along with the guide, will take us on a two-hour ride through the surrounding breathtaking mountains. At lunchtime, we'll head to a private location where a chef will prepare local delicacies for us (pasta and grilled meat). We'll also have the opportunity to take a dip in a stunning stone pool with heated water while enjoying the panoramic view of the valley. After lunch, we'll take off back home.",
            }
        }
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "it",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
