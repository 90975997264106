import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Typography, TextField, Button, useMediaQuery } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import lux from "../../assets/lux.png";
import gold from "../../assets/gold.png";
import { Trans } from 'react-i18next';

const ResponsePage = () => {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    const { id } = useParams();
    const [message, setMessage] = useState('');
    const [responseSent, setResponseSent] = useState(false); // Stato per gestire la conferma

    const handleSendMessage = async () => {
        try {
            await axios.post(`/requests/${id}/message`, {
                sender: 'user',
                message,
            });

            toast.success('Risposta inviata con successo!');
            setMessage('');
            setResponseSent(true); // Imposta lo stato a true per indicare che la risposta è stata inviata
        } catch (error) {
            console.error('Errore durante l\'invio della risposta:', error);
            toast.error('Errore durante l\'invio della risposta');
        }
    };

    return (
        <div style={{
            display: 'flex', 
            marginTop: isMobile ? "4rem" : '5rem',
            alignItems: 'center', 
            justifyContent: "center",
            flexDirection: 'column'
        }}> 
            <img src={lux} alt="lux" style={{marginTop: isMobile ? '1.5rem' : "0rem", width: "300px"}} />
            <img src={gold} alt='gold' style={{marginTop: "2rem", width: isMobile ? '100%' : "500px", objectFit: 'contain'}}/>

            {!responseSent ? (
                <>
                    <Typography variant="h4">Rispondi alla Richiesta</Typography>
                    <TextField
                        label="Risposta"
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ mt: 2 }}>
                        Invia
                    </Button>
                </>
            ) : (
                <Typography variant="h5" color="success" sx={{ textAlign: "center", mt: 4 }}>
                        <Trans i18nKey='RispostaOk'/>
                </Typography>
            )}
            <ToastContainer />
        </div>
    );
};

export default ResponsePage;
