// src/components/RequestForm.js

import React, { useState } from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const RequestForm = ({ open, onClose, onRequestAdded }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async () => {
        if (!name || !email || !phone || !type || !message) {
            toast.error('Tutti i campi sono obbligatori');
            return;
        }

        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.post('/requests/increment', {
                name,
                email,
                phone,
                type,
                message,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            toast.success('Richiesta inserita con successo!');
            onRequestAdded(response.data);
            onClose();
        } catch (error) {
            console.error('Errore durante l\'inserimento della richiesta:', error);
            toast.error('Errore durante l\'inserimento della richiesta');
        }
    };

    return (
        <Dialog  open={open} onClose={onClose}>
            <DialogTitle>Inserisci Nuova Richiesta</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField label="Nome" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
                    <TextField label="Telefono" value={phone} onChange={(e) => setPhone(e.target.value)} fullWidth />
                    <TextField label="Tipo" value={type} onChange={(e) => setType(e.target.value)} fullWidth />
                    <TextField label="Messaggio" value={message} onClick={(e) => setMessage(' ')} fullWidth multiline rows={4} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Annulla</Button>
                <Button onClick={handleSubmit} color="primary">Inserisci</Button>
            </DialogActions>
            <ToastContainer />
        </Dialog>
    );
};

export default RequestForm;
