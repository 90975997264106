import React, { useEffect } from 'react';
import { Typography, Container, useMediaQuery, Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import helitour from "../../../assets/images/gallery/elicotteri.jpg";
import pilota from "../../../assets/images/video/banner.webm";
import tour from "../../../assets/images/video/tour.webm";
import affari from "../../../assets/images/video/affari.webm";
import lux from "../../../assets/lux.png";
import gold from "../../../assets/gold.png";
import VideoPlayer from "../../../components/fixed/VideoPlayer";
import { Helmet } from 'react-helmet';
import i18n from '../../../lang/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCube, faHelicopter } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

const Elicotteri = () => {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const { t } = useTranslation();
    const videoUrl = "https://www.dailymotion.com/embed/video/k5LLqz4aOk2qtDAzgVe?autoplay=1";

    const services = [
        { title: t('TourPersonalizzatiTitle'), img: tour, link: "/elicotteri/tour", intro: t('TourPersonalizzatiIntro'), description: t('TourPersonalizzatiDesc') },
        { title: t('PilotaPerUnGiornoTitle'), img: pilota, link: "/pilota-per-un-giorno", intro: t('PilotaPerUnGiornoIntro'), description: t('PilotaPerUnGiornoDesc') },
        { title: t('ViaggiAffariTitle'), img: affari, link: "/elicotteri/trasferimenti-affari", intro: t('ViaggiAffariIntro'), description: t('ViaggiAffariDesc') },
        // { title: t('ElicotteriVenditaTitle'), img: heli3, link: "/elicotteri-vendita", intro: t('ElicotteriVenditaIntro'), description: t('ElicotteriVenditaDesc') }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.5 } }
    };

    const itemVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { type: 'spring', stiffness: 120 } }
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0
    });

    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <div style={{display: "flex", marginTop: "5rem", flexDirection: 'column', alignItems: "center", 
        justifyContent: "center"}}>
          <Helmet>
            <title>{t('MetaEliTitle')}</title>
            <meta name="description" content={t('MetaTourDesc')} />
            <meta name="author" content="Luxury Agency Rome" />
            <meta name="publisher" content="Luxury Agency Rome" />
            <meta property="og:title" content={t('MetaEliTitle')} />
            <meta property="og:description" content={t('MetaTourDesc')} />
            <meta property="og:url" content="https://www.luxuryagencyrome.it/elicotteri" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://www.luxuryagencyrome.it/elicotteri" />
            <html lang={i18n.language} />
          </Helmet>
            
            <img src={lux} alt='Luxury' style={{ width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>

            <div 
            style={{width: isMobile ? 'auto' : "100%", display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "center", marginTop: "1.5rem",
            height: isMobile ? '300px' : "400px",
            background: `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,1)), url(${helitour}) ${isMobile ? 'top' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`,}}>
           
           <Typography sx={{ fontSize: isMobile ? '20px' : '30px', marginTop: "2rem", textAlign: "center"}}>
                {t('ServizioElicotteri')}
            </Typography>


            <Typography sx={{ fontSize: isMobile ? '16px' : '20px', 
            textAlign: "center", fontStyle: "italic", color: "white", 
                }}>
                    <Trans i18nKey="ElicotteriFrase" />
                </Typography>

               
            </div>

{!isMobile && (
            <div style={{display: "flex", flexDirection: isMobile ? 'column' : undefined, gap: '1.5rem', alignItems: "center"}}>
            <Link to='/scuola'>
            <Button>
            <FontAwesomeIcon style={{marginRight: "10px"}} icon={faCube} />
             ELICOTTERO IN 3D 
              </Button></Link>
              <VideoPlayer videoUrl={videoUrl} />
              </div>
)}

            <div ref={ref} initial="hidden" animate={controls} variants={containerVariants}
                style={{ display: "flex", flexDirection: 'column', gap: '3rem', padding: "10px", 
                marginTop: isMobile ? '2rem' : "1.5rem",
                justifyContent: "center", width: '100%'}}>

                  
              
<Typography variant="h6"  sx={{ textAlign: 'center', 
                             fontSize: isMobile ? '18px' : "16px"}}>
                            <Trans style={{textAlign: "center"}} i18nKey="ServizioElicotteriDesc2" />
            </Typography>

                {services.map((service, index) => (
                   <>
                   <center>
                    </center>
                    <ServiceCard key={index} service={service} variants={itemVariants} isMobile={isMobile} />
                   </>))}
                
            </div>

        </div>
    );
};

const ServiceCard = ({ service, variants, isMobile }) => {
    const { title, img, link, description, intro } = service;
    const isVideo = img.endsWith('.webm'); // Check if the image is a video

    return (
        <div className="hover-container" style={{
            display: "grid",
            justifyContent: "space-around",
            padding: "20px",
            borderRadius: "0.4rem",
            color: "white",
            gridTemplateColumns: "1fr", // Una colonna che prende tutto lo spazio disponibile
            gridGap: "30px", // Aumenta il gap tra gli elementi della griglia
            overflow: "hidden"
          }}>
            <Link style={{ textDecoration: "none" }} to={link}>
              <div style={{
                display: 'flex',
                padding: "20px",

                borderRadius: "0.5rem",
                flexDirection: isMobile ? 'column' : undefined,
                alignItems: "center",
                gap: isMobile ? '1rem' : "5rem",
                justifyContent: "center",
                position: "relative" // Aggiungi questa linea
              }}>
                <Container style={{
                  display: 'flex',
                  flexDirection: "column",
                  padding: "20px",
                  background: "rgb(18,18,18)",
                  background: "linear-gradient(270deg, rgba(18,18,18,1) 0%, rgba(0,0,0,3) 100%)",
                  backdropFilter: "blur(5px)",
                  borderRadius: "0.5rem"
                }}>
                  <Typography style={{ textAlign: isMobile ? 'center' : undefined, fontSize: "24px", 
                  color: '#6490bc' }}>
                    {title}
                  </Typography>
                  <Typography style={{ textAlign: isMobile ? 'center' : undefined,
                   fontStyle: "italic", fontSize: isMobile ? '14px' : "18px", color: 'grey' }}>
                    {intro}
                  </Typography>
                  <Typography style={{textAlign: isMobile ? 'center' : undefined,
                   marginTop: "0.8rem", fontSize: "16px", color: 'white' }}>
                    {description}
                  </Typography>
                </Container>
                {isVideo ? (
                  <video
                    src={img}
                    autoPlay
                    muted
                    playsInline
                    loop
                    style={{
                      width: isMobile ? '100%' : "100%", 
                      borderBottom: "5px solid black", 
                      borderRadius: "0.5rem",
                      boxShadow: '0px 0px 10px 0px black', 
                      marginRight: isMobile ? '0px' : "10px",
                      height: "220px", 
                      objectFit: "cover"
                    }}
                  />
                ) : (
                  <img src={img} style={{
                    width: isMobile ? '100%' : "100%", 
                    borderBottom: "5px solid black", 
                    borderRadius: "0.5rem",
                    boxShadow: '0px 0px 10px 0px black', 
                    marginRight: isMobile ? '0px' : "10px",
                    height: "220px", 
                    objectFit: "cover"
                  }}/>
                )}
                <div className="hover-overlay">
                  <Typography style={{color: "white",
textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
                  }} variant="h2">{t('Scopri')}
                   <FontAwesomeIcon icon={faArrowRightLong} /> </Typography>
                </div>
              </div>
            </Link>
        </div>
    );
};

export default Elicotteri;
