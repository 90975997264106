import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Box, useMediaQuery, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import lux from '../../assets/lux.png'; 
import { AuthContext } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUserAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const NewsletterSubscribers = () => {
    const { user, loading, checkAuth } = useContext(AuthContext);
    const [subscribers, setSubscribers] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedSubscriberId, setSelectedSubscriberId] = useState(null);
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    useEffect(() => {
        const fetchSubscribers = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('/newsletter/subscribers', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setSubscribers(response.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    toast.error('Non sei autorizzato a visualizzare questa pagina. Effettua il login.');
                } else {
                    console.error('Error fetching subscribers:', error);
                }
            }
        };

        if (user) {
            fetchSubscribers();
        } else {
            checkAuth();
        }
    }, [user, checkAuth]);

    const handleSendEmail = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.post('/newsletter/send-newsletter', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Newsletter inviata con successo!');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Non sei autorizzato a inviare la newsletter. Effettua il login.');
            } else {
                toast.error('Errore nell\'invio della newsletter');
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`/newsletter/subscribers/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setSubscribers(subscribers.filter(subscriber => subscriber._id !== id));
            setOpenConfirmDialog(false);
            setSelectedSubscriberId(null);
        } catch (error) {
            console.error('Error deleting subscriber:', error);
        }
    };

    const openConfirmDialogHandler = (id) => {
        setSelectedSubscriberId(id);
        setOpenConfirmDialog(true);
    };

    const closeConfirmDialogHandler = () => {
        setOpenConfirmDialog(false);
        setSelectedSubscriberId(null);
    };

    if (loading) {
        return <div>Caricamento...</div>;
    }

    if (!user) {
        return <Navigate to="/admin/access" />;
    }

    return (
        <div style={{ padding: '2rem', alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", marginTop: '3rem' }}>
            <Box display="flex" justifyContent="center" mb={4}>
                <img src={lux} alt='Luxury' style={{ width: isMobile ? '100%' : "200px", objectFit: 'contain' }} />
            </Box>
            <Typography variant="h4" gutterBottom>Iscritti alla Newsletter</Typography>
            <Box style={{ width: "100%", backgroundColor: 'black', padding: '20px', borderRadius: '0.5rem' }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography style={{ fontFamily: "Belanosima", fontSize: "18px" }}>
                        Numero di iscritti: {subscribers.length} <FontAwesomeIcon icon={faUserAlt} style={{ marginLeft: "5px" }} />
                    </Typography>
                    <Button  style={{backgroundColor: "#6490bc", color: "black"}} onClick={handleSendEmail}>
                    Invia Newsletter
                </Button>
                </div>
                <Box style={{ maxHeight: 'calc(100vh - 270px)', marginTop: "1.5rem", overflow: 'auto' }}>
                    {subscribers.map((subscriber) => (
                        <motion.div
                            key={subscriber._id}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{ backgroundColor: 'black', boxShadow: '0 4px 8px rgba(255,255,255,0.3)', padding: '20px', borderRadius: "0.5rem", marginBottom: '15px' }}
                        >
                            <Box style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center', justifyContent: "space-between" }}>
                                <Box style={{ display: "flex", width: "100%", alignItems: isMobile ? 'center' : 'center' }}>
                                    <Typography style={{ color: '#6490bc', fontFamily: 'Belanosima' }}>
                                        ╰ {subscriber.email}
                                    </Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: isMobile ? "space-between" : 'flex-start', width: "100%", alignItems: "center", gap: "0.5rem" }}>
                                    {subscriber.name && (
                                        <Typography style={{ color: '#B8B3AF', display: "flex", alignItems: "center", fontSize: "15px", fontFamily: 'Belanosima' }}>
                                            <FontAwesomeIcon icon={faUserAlt} style={{ marginLeft: '10px', color: 'white', marginRight: "5px" }} />
                                            {subscriber.name}
                                        </Typography>
                                    )}
                                    {subscriber.phoneNumber && (
                                        <Typography style={{ color: '#B8B3AF', display: "flex", alignItems: "center", fontSize: "15px", fontFamily: 'Belanosima' }}>
                                            <FontAwesomeIcon icon={faPhoneAlt} style={{ marginLeft: '10px', color: 'white', marginRight: "5px" }} />
                                            {subscriber.phoneNumber}
                                        </Typography>
                                    )}
                                </Box>
                                <Box style={{ display: "flex", justifyContent: isMobile ? "space-between" : 'flex-start', width: "100%", alignItems: "center", gap: "0.5rem" }}>
                                    <Button style={{backgroundColor: "#6490bc", color: "black"}}  onClick={() => openConfirmDialogHandler(subscriber._id)}>
                                        Elimina
                                    </Button>
                                </Box>
                            </Box>
                        </motion.div>
                    ))}
                </Box>
              
            </Box>
            <Dialog
                open={openConfirmDialog}
                onClose={closeConfirmDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Conferma Eliminazione</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sei sicuro di voler eliminare questo iscritto? Questa azione non può essere annullata.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDialogHandler} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={() => handleDelete(selectedSubscriberId)} color="secondary" autoFocus>
                        Elimina
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </div>
    );
};

export default NewsletterSubscribers;
