import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

const NewsletterSubscription = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMessage(''); // Resetta il messaggio quando il modale viene chiuso
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('Invio richiesta di iscrizione per:', email, name, phoneNumber);
            const response = await axios.post('/newsletter/subscribe', { email, name, phoneNumber });
            console.log('Risposta del server:', response.data);
            setMessage(response.data.message);
        } catch (error) {
            console.error('Errore durante l\'iscrizione:', error);
            setMessage('Errore durante l\'iscrizione. Riprova più tardi.');
        }
    };

    return (
        <div>
            <Button style={{color: "black", width: '340px', color: "white", backgroundColor: "#6490bc"}} onClick={handleClickOpen}>
                <FontAwesomeIcon style={{color: "black", marginRight: "10px"}} icon={faEnvelope}/>
                <Trans i18nKey='Newsletter'/> <FontAwesomeIcon style={{marginLeft: "10px",color: "black"}} icon={faEnvelope}/>
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent style={{backgroundColor: "black"}} >
                    <Typography variant="h6"> <Trans i18nKey='Newsletter2'/> </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Numero di telefono"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <div style={{display: "flex", alignItems: "center", marginTop: "0.5rem", justifyContent: "space-between"}}>
                            <Button type="submit" color="primary">{t('Iscriviti')}</Button>
                            <Button style={{marginLeft: "10px"}} onClick={handleClose} color="primary">
                                {t('Close')}
                            </Button>
                        </div>
                    </form>
                    {message && <Typography variant="body1">{message}</Typography>}
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </div>
    );
};

export default NewsletterSubscription;
