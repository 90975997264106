import React from 'react';
import { Typography, useMediaQuery, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import ponza from '../../../assets/images/yacht/ponza.webp';
import '../../../theme/Yacht.css';
import lux from "../../../assets/lux.png";
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

function YachtPonza() {
  const { t } = useTranslation();
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  const sections = [
    {
      title: t('Imbarco'),
      content: t('ImbarcoDescrizione'),
      content2: t('ImbarcoDescrizione'),
    },
    {
      title: t('Sbarco'),
      content: t('SbarcoDescrizione'),
      content2: t('ImbarcoDescrizione'),
    },
    {
      title: t('Equipaggio'),
      content: t('EquipaggioDescrizione'),
      content2: t('ImbarcoDescrizione'),
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>

      <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
        <img src={lux} alt='gold' style={{ width: isMobile ? '100%' : "500px", marginTop: "5rem", objectFit: 'contain' }} />

        <Typography sx={{ fontSize: '26px', marginTop: "0.6rem", textAlign: isMobile ? 'left' : 'left' }}>
          {t('Yacht')}
        </Typography>
        <Typography sx={{ fontSize: '30px', marginTop: "1.5rem", fontStyle: "italic", color: "#6490bc", textAlign: 'center' }}>
          <Trans i18nKey="YachtPonzaTitle" />
        </Typography>
      </div>

      <Helmet>
        <title>{t('YachtPonzaTitle')}</title>
        <meta name="description" content={t('YachtPonzaDesc')} />
        <html lang={i18next.language} />
      </Helmet>


    <div style={{display: "flex", width: "80%", justifyContent: 'space-around', alignItems: "center"}}>
     
      <div class="book">
      <p className='book-inside-text'>
        <Trans i18nKey='YachtPonzaDesc'/>
      </p>
      <p className='book-inside-text'>
        <Trans i18nKey='YachtPonzaDesc2'/>
      </p>
      <p className='book-inside-text'>
        <Trans i18nKey='YachtPonzaDesc3'/>
      </p>
      <p className='book-inside-text'>
        <Trans i18nKey='YachtPonzaDesc4'/>
      </p>
    <div class="cover">
    <p>{t('Scopri')}</p>
    
    </div>
    {!isMobile  && (
    <div className='book-button'>
    <Link to='/contact'>
    <Button>
        {t('Contatti')}
    </Button>
    </Link>
    </div>
    )}
   </div>
   </div>




    </div>
  );
}

export default YachtPonza;
