import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, TextField, InputAdornment, Tooltip, useMediaQuery, FormControl, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEye, faGlasses, faLink, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { WhatsappShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappIcon, FacebookIcon, TelegramIcon, TwitterIcon } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Search as SearchIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../theme/Blog.css';  // Importa il file CSS
import lux from '../../assets/lux.png';
import logo from '../../assets/logo.png';
import EmptyStateMessage from '../components/EmptyStateMessage';
import {InputLabel} from '@mui/material';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const navigate = useNavigate();
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('/posts?limit=10&page=1'); // Limita a 10 post per pagina
                setPosts(response.data);
                setFilteredPosts(response.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = posts.filter(post => 
            post.title.toLowerCase().includes(query) || 
            post.category.toLowerCase().includes(query)
        );
        setFilteredPosts(filtered);
    };

    const handleCategoryChange = (e) => {
        const category = e.target.value;
        setCategoryFilter(category);
        if (category === "") {
            setFilteredPosts(posts);
        } else {
            const filtered = posts.filter(post => post.category === category);
            setFilteredPosts(filtered);
        }
    };
    

    const handleCopyLink = (url) => {
        toast.success('Link copiato negli appunti!');
    };

    return (
        <>
            {!isMobile && (
                <div className="header">
                    <img src={lux} alt='Luxury' className="header-image" />
                </div>
            )}

            <div className="blog-container">
                <div className="content-container">
                    <div className="search-header"
                    style={{marginTop: isMobile ? '3rem' : undefined, gap: isMobile ? '0.2rem' : undefined, flexDirection: isMobile ? 'column' : undefined}}
                    >
                        <div className="logo-container">
                            <img src={logo} alt='Luxury' className="logo" />
                            {!isMobile && (
                                <Typography className="blog-title">BLOG <FontAwesomeIcon icon={faNewspaper} className="blog-icon" /></Typography>
                            )}
                        </div>
                        {isMobile && (
                            <Typography className="blog-title mobile">BLOG <FontAwesomeIcon icon={faNewspaper} className="blog-icon" /></Typography>
                        )}
                        <div style={{marginTop: isMobile ? "0.5rem" : undefined}} className="search-container">
                            <TextField 
                                className="search-field"
                                style={{width: isMobile ? '300px' : undefined}} 
                                value={searchQuery}
                                placeholder='Cerca tra gli articoli..'
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon className="search-icon" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <FormControl>
    <InputLabel>Filtra per categoria</InputLabel>
    <Select style={{width: "180px"}}
        value={categoryFilter}
        onChange={handleCategoryChange}
        label="Filtra per categoria"
    >
        <MenuItem value="">Tutte</MenuItem>
        <MenuItem value="Elicotteri">Elicotteri</MenuItem>
        <MenuItem value="Supercar">Supercar</MenuItem>
        <MenuItem value="Aviazione">Aviazione</MenuItem>
        <MenuItem value="Yacht e Barche di Lusso">Yacht e Barche di Lusso</MenuItem>
        <MenuItem value="Security">Security</MenuItem>
        <MenuItem value="News">News</MenuItem>
        <MenuItem value="Luxury">Luxury</MenuItem>
        <MenuItem value="Lifestyle">Lifestyle</MenuItem>
        <MenuItem value="Attualità">Attualità</MenuItem>
        <MenuItem value="Cultura">Cultura</MenuItem>
    </Select>
</FormControl>

                        </div>
                  
                    {filteredPosts.length === 0 ? (
                        <EmptyStateMessage />
                    ) : (
                        <div style={{
                            justifyContent: isMobile ?  "center" : undefined,
                             flexDirection: isMobile ? 'column' : undefined, 
                             gap: isMobile ? '35px' : '5rem',
                            alignItems: isMobile ?  "center" : undefined, 
                            display: isMobile ?  'flex' : 'grid', 
                            marginTop: isMobile ? "2rem" : undefined}} className="grid-container">
                            {filteredPosts.map((post) => {
                                const formattedDate = new Date(post.createdAt).toLocaleDateString('it-IT', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                });

                                return (
                                    <div key={post._id} style={{width: isMobile ? '98%' : undefined}} className="card-blog" onClick={() => navigate(`/blog/${post.slug}`)}>
                                        <div className="top-section">
                                            <span className="regular-text">
                                                <FontAwesomeIcon icon={faClock} style={{ color: '#6490bc', marginRight: '5px' }} />
                                                {formattedDate}
                                            </span>
                                            <div className="icons">
                                                <div className="social-media">
                                                    <WhatsappShareButton url={`${window.location.origin}/blog/${post.slug}`}>
                                                        <WhatsappIcon size={20} round />
                                                    </WhatsappShareButton>
                                                    <FacebookShareButton url={`${window.location.origin}/blog/${post.slug}`}>
                                                        <FacebookIcon size={20} round />
                                                    </FacebookShareButton>
                                                    <TelegramShareButton url={`${window.location.origin}/blog/${post.slug}`}>
                                                        <TelegramIcon size={20} round />
                                                    </TelegramShareButton>
                                                    <TwitterShareButton url={`${window.location.origin}/blog/${post.slug}`}>
                                                        <TwitterIcon size={20} round />
                                                    </TwitterShareButton>
                                                    <CopyToClipboard text={`${window.location.origin}/blog/${post.slug}`} onCopy={() => handleCopyLink(`${window.location.origin}/blog/${post.slug}`)}>
                                                        <FontAwesomeIcon className="icon-link" icon={faLink} />
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="title">{post.title}</span>

                                        <img src={post.coverImage} alt='post-img' style={{width: "100%",
                                            borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem",
                                            border: '1px solid rgba(100, 100, 111, 0.5)',
                                            boxShadow: 'rgba(100, 100, 111, 0.7) 0px 7px 20px 0px',
                                            padding: '5px',
                                            objectFit: "contain"}}/>
                                        <div className="bottom-section">
                                            <div style={{display: "flex",  padding: "4px", borderBottom: "1px solid #6490bc", marginBottom: "0.3rem", justifyContent: 'space-between', alignItems: "center"}}>
                                                <span className="regular-text">{post.readingTime}
                                                    <FontAwesomeIcon icon={faGlasses} style={{color: '#6490bc', marginLeft: '5px'}}/>
                                                </span>
                                                <span className="regular-text">
                                                    Visualizzazioni: {post.views} 
                                                    <FontAwesomeIcon icon={faEye} style={{color: '#6490bc', marginRight: "5px", marginLeft: '5px'}}/>
                                                </span>
                                            </div>
                                            <Typography style={{fontSize: '14px'}}>{post.excerpt}</Typography>
                                            <Button style={{width:"100%", marginTop: "0.4rem", backgroundColor: "#6490bc"}} onClick={() => navigate(`/blog/${post.slug}`)}>Leggi</Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Blog;
