import React, { useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogContent, DialogActions, useMediaQuery } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faCcVisa, faCcMastercard, faCcPaypal, faCcStripe, faBitcoin, faEthereum,  faYoutube,} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import {faArrowLeftLong, faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import { motion } from 'framer-motion';
import banner from "../../assets/images/video/banner.webm";
import lux from "../../assets/lux.png";
import gold from "../../assets/gold.png";
import simone from "../../assets/images/simone.png";
import { InstagramEmbed } from 'react-social-media-embed';
import Categorie from './Categorie';
import SidebarBlog from '../../admin/blog/SidebarBlog';
import partner1 from "../../assets/images/partner1.png";
import "../../theme/StorySimone.css";
function Homepage() {
  const [storyOpen, setStoryOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const socialMediaPosts = [
    "https://www.instagram.com/p/C8eh9bFIuQe/",
    "https://www.instagram.com/p/C8ZYHoCON-3/",
    "https://www.instagram.com/p/C8UOk8-Pd9v/",
    "https://www.instagram.com/p/C8MgVRKIng2/",
    "https://www.instagram.com/p/C8HWml2pYz9/",
    "https://www.instagram.com/p/C8CjRjzol4q/",
    "https://www.instagram.com/p/C7l19f4IUMv/",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.3, duration: 0.5 } },
  };

  const hoverVariants = {
    hover: {
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
    tap: {
      scale: 0.95
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function PaymentMethods() {
    return (
      <div style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        marginTop: "20px",
        padding: "10px"
      }}>
        <FontAwesomeIcon style={{color: "white"}} icon={faCcVisa} size="2x" />
        <FontAwesomeIcon style={{color: "white"}} icon={faCcMastercard} size="2x" />
        <FontAwesomeIcon style={{color: "white"}} icon={faCcStripe} size="2x" />
        <FontAwesomeIcon style={{color: "white"}} icon={faCcPaypal} size="2x" />
        <FontAwesomeIcon style={{color: "white"}} icon={faBitcoin} size="2x" />
        <FontAwesomeIcon style={{color: "white"}} icon={faEthereum} size="2x" />
      </div>
    );
  }

  const prevPost = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : socialMediaPosts.length - 1));
  };

  const nextPost = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % socialMediaPosts.length);
  };

  const phrases = [
    "ServicePhrase1",
    "ServicePhrase2",
    "ServicePhrase3",
    "ServicePhrase4",
    "ServicePhrase5",
    "ServicePhrase6",
    "ServicePhrase7",
    "ServicePhrase8",
  ];

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Helmet>
        <title>{t('MetaHomeTitle')}</title>
        <meta name="description" content={t('MetaHomeDesc')} />
        <meta name="keywords" content="tour di lusso Roma, giro in elicottero a roma, giro elicottero roma, tour elicottero Roma, noleggio supercar Roma, esperienze esclusive Roma, tour esclusivi Roma, jet privato Roma, vacanze di lusso Roma"></meta>
        <meta name="author" content="Luxury Agency Rome" />
        <meta name="publisher" content="Luxury Agency Rome" />
        <meta property="og:title" content={t('MetaHomeTitle')} />
        <meta property="og:description" content={t('MetaHomeDesc')} />
        <meta property="og:url" content="https://www.luxuryagencyrome.it" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.luxuryagencyrome.it" />
        <html lang={i18n.language} />
      </Helmet>

    

      {/* <div style={{ display: "flex", marginTop: "2rem", alignItems: "center", justifyContent: "center", gap: "1.5rem" }}>
        <Button onClick={handleClickOpen}>
          <FontAwesomeIcon icon={faInstagram} style={{ marginRight: "10px" }}></FontAwesomeIcon>
          Instagram
        </Button>
        <Link style={{ color: 'white' }} to='https://www.tiktok.com/@luxuryagencyrome'>
          <Button>
            <FontAwesomeIcon icon={faTiktok} style={{ marginRight: "10px" }}></FontAwesomeIcon>
            TikTok
          </Button>
        </Link>
        <Link style={{ color: "#3b5998" }} to='https://www.youtube.com/channel/UCKxKU5Q5T3BKQPTnBLs9tsw'>
          <Button onClick={handleClickOpen}>
            <FontAwesomeIcon icon={faYoutube} style={{ marginRight: "10px" }}></FontAwesomeIcon>
            YouTube
          </Button>
        </Link>
      </div> */}

<div style={{
      display: "flex",
      marginTop: "3.5rem",
      flexDirection: "column",
      padding: isMobile ? '5px': "20px",
      justifyContent: "center",
      alignItems: "center"
    }}>

{isMobile && (
          <img src={lux} alt='lux' style={{width: isMobile ? '100%' : '500px' }} />
          )}

{isMobile && (
            <div style={{ textAlign: "center", borderTop: "1px solid grey",
              marginTop: "1rem", borderBottom: "1px solid grey",
              width: "100%", borderRadius: "0.6rem",
              border: '1px solid rgba(100, 100, 111, 0.5)',
              backgroundColor: "black",
            justifyContent: "center", display: "flex" }}>
              <Typography style={{
                animation: 'slideIn 3s infinite',
                fontSize: isMobile ? '30px' : '40px',
                color: '#6490bc'
              }}>
                <Trans i18nKey={phrases[currentPhraseIndex]} />
              </Typography>
            </div>
            )}


      <div style={{ display: "flex", width: "100%", 
        alignItems: "center", justifyContent: 'center', position: "relative" }}>
        <video
          autoPlay
          muted
          playsInline
          loop
          style={{
            width: "100%",
            marginTop: isMobile ? '0.7rem' : 0,
            height: isMobile ? 'auto' : '450px',
            objectFit: isMobile ? undefined : "cover",
            borderRadius: isMobile ? "1rem" : '2rem'

          }}>
          <source src={banner} type="video/webm" />
          Your browser does not support the video tag.
        </video>

        

        
        {!isMobile && (

        <div style={{
          display: "flex",
          borderTopRightRadius: '20px',
          borderBottomLeftRadius: '20px',
          clipPath: isMobile ? undefined : 'polygon(30px 0%, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0% 30px)',
          zIndex: 1,
          position: 'absolute',
          flexDirection: 'column',
          padding: isMobile ? "1px" : "35px",
          border: "1px solid grey",
          boxShadow: isMobile ? 'none' : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
          border: "2px solid rgba(100, 100, 111, 0.5)",
          backgroundColor: "rgba(0,0,0,0.7)",
          justifyContent: "center",
          alignItems: "center"
        }}>
      
    {!isMobile && (
          <img src={lux} alt='lux' style={{width: isMobile ? '100%' : '500px' }} />
    )}
          <span alt='Home Intro 2' style={{
            marginTop: "1rem",
            color: "white",
            textAlign: "center",
            textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000"
          }}>
            <Typography style={{
              fontSize: isMobile ? '18px' : '22px',
              textDecorationColor: "gold"
            }}>
              <Trans i18nKey='HomeIntro2' />
            </Typography>
          </span>

          <div style={{ textAlign: isMobile ? 'center' : "right", display: "flex", flexDirection: "column" }}>
            <span alt="Home Intro" style={{
              marginTop: isMobile ? "0.4rem" : '1rem',
              fontFamily: "Belanosima", marginBottom: "1rem",
              fontStyle: "italic",
              fontSize: isMobile ? '20px' : '18px',
              color: "#B8B3AF"
            }}>
              <Trans i18nKey="HomeIntro" />
            </span>
            {!isMobile && (
            <div style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
              <Typography style={{

                animation: 'slideIn 3s infinite',
                fontSize: isMobile ? '30px' : '40px',
                color: '#6490bc'
              }}>
                <Trans i18nKey={phrases[currentPhraseIndex]} />
              </Typography>
            </div>
            )}
          </div>
          
            
            {!isMobile && (
            <Link to='/contact' style={{textDecoration: 'none', color: "white"}}>
          <Typography style={{ marginTop: "0.4rem", 
          fontFamily: 'Belanosima', backgroundColor: '#6490BC',
          padding: "15px", borderRadius: '0.5rem',
          fontSize: '20px',
             textAlign: "center", color: "white"}}>

              <Trans i18nKey='ContactClick'/>
           </Typography>
          </Link>
            )}
        </div>

          )}
      </div>

    

    </div>


    {isMobile && (
                <div style={{
                  display: "flex",

                  borderTopRightRadius: '0px',
                  borderBottomLeftRadius: '20px',
                  clipPath: isMobile ? undefined : 'polygon(30px 0%, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0% 30px)',
                  flexDirection: 'column',
                  padding: isMobile ? "10px" : "35px",
                  borderTop: "none",
                  boxShadow: isMobile ? 'none' : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
                  border: "2px solid rgba(100, 100, 111, 0.5)",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
              
            {!isMobile && (
                  <img src={lux} alt='lux' style={{width: isMobile ? '100%' : '500px' }} />
            )}
                  <span alt='Home Intro 2' style={{
                    marginTop: "1rem",
                    color: "white",
                    textAlign: "center",
                    textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000"
                  }}>
                    <Typography style={{
                      fontSize: isMobile ? '18px' : '22px',
                      textDecorationColor: "gold"
                    }}>
                      <Trans i18nKey='HomeIntro2' />
                    </Typography>
                  </span>
        
                  <div style={{ textAlign: isMobile ? 'center' : "right", display: "flex", flexDirection: "column" }}>
                    <span alt="Home Intro" style={{
                      marginTop: isMobile ? "0.4rem" : '1rem',
                      fontFamily: "Belanosima", marginBottom: "1rem",
                      fontStyle: "italic",
                      fontSize: isMobile ? '20px' : '18px',
                      color: "#B8B3AF"
                    }}>
                      <Trans i18nKey="HomeIntro" />
                    </span>
                    {!isMobile && (
                    <div style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
                      <Typography style={{
        
                        animation: 'slideIn 3s infinite',
                        fontSize: isMobile ? '30px' : '40px',
                        color: '#6490bc'
                      }}>
                        <Trans i18nKey={phrases[currentPhraseIndex]} />
                      </Typography>
                    </div>
                    )}


{isMobile && (
            <Link to='/contact' style={{textDecoration: 'none', color: "white"}}>
          <Typography style={{ marginTop: "0.4rem", 
          fontFamily: 'Belanosima', backgroundColor: '#6490BC',
          padding: "15px", borderRadius: '0.5rem',
          fontSize: '20px',
             textAlign: "center", color: "white"}}>
        <Trans i18nKey='ContactClick'/>
           </Typography>
          </Link>
            )}
                    
        
        
                  </div>
        
                  
                </div>
      )}
    


      <Dialog open={open} onClose={handleClose}>
        <InstagramEmbed key={currentIndex} url={socialMediaPosts[currentIndex]}
          style={{
            boxShadow: "0px 0px 20px 0px white", borderRadius: "0.5rem",
            width: 'auto',
            display: "flex", justifyContent: "center"
          }} />
        <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: 'flex', justifyContent: 'start', color: "white", alignItems: "center" }}>
            <Button style={{ color: "#6490bc" }} onClick={prevPost}> <FontAwesomeIcon icon={faArrowLeftLong} /> </Button>
            <span style={{ fontFamily: "Belanosima" }}>POST</span>
            <Button style={{ color: "#6490bc" }} onClick={nextPost}> <FontAwesomeIcon icon={faArrowRightLong} /> </Button>
          </div>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

          <div style={{justifyContent: "center", alignContent: "center", display: "flex", width: "100%"}}>
      <Categorie />
      </div>

      <div style={{ display: "flex", marginTop: "2rem", flexDirection: isMobile ? 'column' : undefined, 
        width: "100%", justifyContent: "space-around" }}>
        <div style={{
          display: "flex", alignItems: "center",
           flexDirection: 'column',
          justifyContent: "center", padding: "10px"
        }}>
          <Typography style={{
            fontSize: '22px',
            textAlign: 'center',
            textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
            textDecorationColor: "gold"
          }}>
            <Trans i18nKey='PartnerTitle' />
          </Typography>
          <img src={partner1} alt='gold'
            style={{ width: isMobile ? '50px' : "80px", marginTop: "0.5rem", objectFit: 'contain' }} />
        </div>
        <div style={{
          display: "flex", marginBottom: "2rem",
          alignItems: "center", flexDirection: 'column',
          justifyContent: "center", padding: "10px"
        }}>
          <Typography style={{
            fontSize: '22px',
            textAlign: 'center',
            textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
            textDecorationColor: "gold"
          }}>
            <Trans i18nKey='Payment' />
          </Typography>
          <PaymentMethods />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
