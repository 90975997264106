import React, { useState, useEffect, useContext} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Grid, Box, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import lux from "../../assets/lux.png";
import { AuthContext } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

const EditPost = () => {
    const { user } = useContext(AuthContext);  // Usa il metodo logout dal contesto
    const { id } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [originalPost, setOriginalPost] = useState({ title: '', content: '' });
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`/posts/${id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
                });
                setTitle(response.data.title);
                setContent(response.data.content);
                setOriginalPost(response.data);
            } catch (error) {
                console.error('Error fetching post:', error);
            }
        };

        fetchPost();
    }, [id]);

    const handleSave = async () => {
        try {
            await axios.put(`/posts/${id}`, { title, content }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            });
            toast.success('Post aggiornato con successo!');
            navigate('/admin/dashboard');
        } catch (error) {
            toast.error('Errore nell\'aggiornamento del post');
        }
    };

    if (!user) {
        return <Navigate to="/admin/access" />;
    }

    return (
        <div style={{display: "flex", marginTop: "5rem", marginBottom: "5rem", flexDirection: 'column', alignItems: "center", 
            justifyContent: "center"}}>
                            <img src={lux} alt='Luxury' style={{ width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>
      
                <Typography style={{fontSize: "30px", marginTop: "2rem", color: "white", fontFamily: "Belanosima"}}>
                <FontAwesomeIcon icon={faPenToSquare} style={{marginRight: "10px"}} />  MODIFICA UN POST <FontAwesomeIcon icon={faPenToSquare} style={{marginLeft: "10px"}} />
                </Typography>
          <div maxWidth="md">

        <Container style={{marginTop: "1rem"}}>
            <Typography variant="h4" >
                Nuovo post:
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Box style={{
                        backgroundColor: 'black',
                        padding: "20px", borderRadius: "1rem",

                    }} component="form">
                        <TextField
                            label="Titolo"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Contenuto"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                        />
                        <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 1 }}>
                            Salva Modifiche
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box style={{textAlign: "right"}}>

                        <Typography style={{fontSize: "16px", color: '#6490bc'}} variant="h6">
                            {originalPost.title}
                        </Typography>
                        <Typography style={{fontSize: "14px"}} variant="body1">
                            {originalPost.content}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <ToastContainer />
        </Container>
        </div>
        </div>
    );
};

export default EditPost;
