import { Box, Typography, Container, Grid, Button, Paper, useTheme, useMediaQuery } from '@mui/material';
import { FlightTakeoff, DirectionsCar, Videocam } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import helicopter from '../../../../../assets/images/gallery/6.JPG';
import transfer from "../../../../../assets/images/gallery/21.JPG";
import videographer from "../../../../../assets/images/gallery/2.JPG";
import { useState } from 'react';
import lux from "../../../../../assets/lux.png";
import gold from "../../../../../assets/gold.png";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../../../../../lang/i18n';
import { Modal, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function EconomyPackage() {
    const { t } = useTranslation();
    const theme = useTheme();
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const [open, setOpen] = useState(false);
const [activeImage, setActiveImage] = useState(0);
const handleOpen = (index) => {
    setOpen(true);
    setActiveImage(index);
};
const handleClose = () => setOpen(false);
const handleNext = () => setActiveImage((prev) => (prev + 1) % images.length);
const handlePrev = () => setActiveImage((prev) => (prev - 1 + images.length) % images.length);

const images = [helicopter, transfer, videographer]; // Array delle immagini

    
    return (
        <Container maxWidth="lg" sx={{
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[5], marginTop: "6rem",
            color: 'white', display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "center"
        }}>
            <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
    <Box sx={{ position: 'relative', outline: 'none' }}>
        <IconButton onClick={handlePrev} sx={{ backgroundColor: 'white', padding: "5px", color: "black", position: 'absolute', left: 16, top: '50%', transform: 'translateY(-50%)' }}>
            <ArrowBackIosNewIcon />
        </IconButton>
        <img src={images[activeImage]} alt={`Service ${activeImage + 1}`} style={{ width: '80vw', height: '80vh', objectFit: 'contain' }} />
        <IconButton onClick={handleNext} sx={{ backgroundColor: 'white', padding: "5px", color: "black", position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)' }}>
            <ArrowForwardIosIcon />
        </IconButton>
    </Box>
</Modal>
                        <Helmet>
    <title>{t('MetaEcoTitle')}</title>
    <meta name="description" content={t('MetaEcoDesc')} />
    <meta name="keywords" content="tour di lusso Roma, elicottero Roma, noleggio supercar Roma, esperienze esclusive Roma, tour esclusivi Roma, jet privato Roma, vacanze di lusso Roma"></meta>
    <meta name="author" content="Luxury Agency Rome" />
    <meta name="publisher" content="Luxury Agency Rome" />
    <meta property="og:title" content={t('MetaEcoTitle')} />
    <meta property="og:description" content={t('MetaEcoDesc')} />
    <meta property="og:url" content="https://www.luxuryagencyrome.it" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.luxuryagencyrome.it" />
    <html lang={i18n.language} />
</Helmet>

<img src={lux} alt='Luxury' style={{ width: isMobile  ? '100%' : "300px", objectFit: 'contain'}}/>
            <img src={gold} alt='Gold' style={{ marginTop: "1rem", width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>


<div 
            style={{width: isMobile ? '100%' : "100%", marginTop: "2rem", display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "center", 
            padding: "30px", 
            background: `linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,1)), url(${helicopter}) ${isMobile ? 'top' : 'center'} / ${isMobile ? 'cover' : 'cover'} no-repeat`,}}>
           
            <Typography variant="h4" sx={{color: '#6490bc', fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
                {t('EconomyPackageTitle')}
            </Typography>

            </div>


                <Container style={{display: 'flex', 
                flexDirection:  isMobile ? 'column' : undefined,
                gap: isMobile ? '3rem' : 'none',

                padding: "20px", marginTop: "1.5rem",
                backgroundColor: 'black', borderRadius: "0.5rem",
                justifyContent: "space-around"}}>


        <Container style={{ display: "flex", 
        gap: "2rem",    padding: "30px",
        justifyContent: "center", flexDirection: "column"}}>
            
<Typography sx={{ marginTop: "2rem", textAlign: "center", fontSize: '20px'}}>
        <Trans i18nKey='EconomyPackageDetails.description'/>
            </Typography>

                <Container style={{display: "flex",             justifyContent: 'space-around', alignContent: "center"}}>
                    <Paper sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: "10px",
                     backgroundColor: 'white' }}>
                        <FlightTakeoff color="black" sx={{ fontSize: 50 }} />
                        <div>
                            <Typography sx={{  fontSize: "16px", color: 'black', fontWeight: 'medium' }}>{t('DurataDelVolo')}</Typography>
                            <Typography style={{color: 'black', fontSize: "16px"}} > {t('EconomyPackageDetails.flightDuration')}</Typography>
                        </div>
                    </Paper>
                    
                </Container>
                {/* Servizio di trasferimento */}

                
            <Typography sx={{ textAlign: "center", color: "#6490bc", fontSize: '20px'}}>
        <Trans i18nKey='ServiziAggiuntivi'/>
            </Typography>

                <Grid item xs={12} md={4}>
                    <Paper elevation={6} sx={{ display: 'flex', alignItems: 'center', gap: 2, backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                        <DirectionsCar color="secondary" sx={{ fontSize: 50 }} />
                        <div>
                            <Typography sx={{ fontSize: "16px", fontWeight: 'medium' }}>{t('TrasferimentoAuto')}</Typography>
                            <Typography style={{fontSize: "16px", }}>{t('EconomyPackageDetails.transfer')}</Typography>
                        </div>
                    </Paper>
                </Grid>
                {/* Servizio di videomaking */}
                <Grid item xs={12} md={4}>
                    <Paper elevation={6} sx={{display: 'flex', alignItems: 'center', gap: 2, backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                        <Videocam color="secondary" sx={{ fontSize: 50 }} />
                        <div>
                            <Typography sx={{fontSize: "16px",  fontWeight: 'medium' }}>{t('Video')}</Typography>
                            <Typography style={{fontSize: "16px"}}>{t('EconomyPackageDetails.video')}</Typography>
                        </div>
                    </Paper>
                </Grid>
            </Container>

            <Container style={{display: "flex", marginTop: "1rem",
           alignItems: "center", flexDirection: "column"}}>
            <Typography style={{fontSize: '30px', textAlign: "center"}}>
                    Gallery
                </Typography>
                {/* Immagini del servizio */}
                {[helicopter, transfer, videographer].map((image, index) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{marginTop: "3rem"}}
        transition={{ duration: 0.5 }}
        key={index}
        onClick={() => handleOpen(index)}
    >
        <img src={image} alt={`Service ${index + 1}`} style={{ width: isMobile ? '100%' : '220px', borderRadius: '0.3rem', boxShadow: "0px 0px 5px 0px white" }} />
    </motion.div>
))}
                </Container>
            </Container>


            <Link to='/contact'>

            <Button sx={{ mt: 4, backgroundColor: '#6490bc', color: 'black', border: "1px solid white"}}>
              <FontAwesomeIcon icon={faEnvelope} style={{marginRight: "10px"}}/>  {t('Prenota')}
              <FontAwesomeIcon icon={faEnvelope} style={{marginLeft: "10px"}}/> 
            </Button>
            </Link>
        </Container>
    );
}

export default EconomyPackage;
