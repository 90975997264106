import { useMediaQuery } from '@mui/system';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import lux from '../../assets/lux.png';
function HelicopterInterior() {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  return (
    <div style={{ marginTop: "6rem",marginBottom: "4rem", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: 'center',
     height: isMobile? '400px' : 'auto', width: 'auto' }}>

<img src={lux} alt='Luxury' style={{ width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>


      <div className="sketchfab-embed-wrapper" style={{ height: '500px', width: '100%' }}>
        <iframe
          title="Come è fatto un elicottero?"
          frameBorder="0"
          allowFullScreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allow="autoplay; fullscreen; xr-spatial-tracking"
          src="https://sketchfab.com/models/3f5db29feec74dbaa86058876e44c7c0/embed"
          style={{ height: '100%', marginTop: "2rem",  borderRadius: "1rem", width: '100%' }}
        ></iframe>
       
      </div>
    </div>
  );
}

export default HelicopterInterior;
