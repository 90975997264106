import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Typography, TextField, Button, Avatar, FormControl, InputLabel, Select, MenuItem, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PaymentIcon from '@mui/icons-material/Payment';
import ServiceIcon from '@mui/icons-material/Build';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faEnvelope, faEuro, faHistory, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const RequestDetails = () => {
    const { id } = useParams();
    const [request, setRequest] = useState(null);
    const [message, setMessage] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [servicesUsed, setServicesUsed] = useState([]);
    const [status, setStatus] = useState('');
    const [openServiceDialog, setOpenServiceDialog] = useState(false);
    const [newService, setNewService] = useState({ type: '', dateUsed: '', amountPaid: 0, paymentStatus: 'non pagato', paymentMethod: 'contanti' });
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    useEffect(() => {
        const fetchRequest = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`/requests/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRequest(response.data);
                setPaymentStatus(response.data.paymentStatus);
                setServicesUsed(response.data.servicesUsed || []);
                setStatus(response.data.status);
            } catch (error) {
                console.error('Error fetching request:', error);
            }
        };

        fetchRequest();
    }, [id]);

    const handleSendMessage = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.post(`/requests/${id}/message`, {
                sender: 'admin',
                message,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setMessage('');
            setRequest(prevRequest => ({
                ...prevRequest,
                conversation: [...prevRequest.conversation, { sender: 'admin', message, timestamp: new Date() }]
            }));
            toast.success('Messaggio inviato con successo!');
        } catch (error) {
            console.error('Error sending message:', error);
            toast.error('Errore durante l\'invio del messaggio');
        }
    };

    const handleUpdateDetails = async (updatedDetails) => {
        try {
            const token = localStorage.getItem('authToken');
            await axios.put(`/requests/${id}/details`, updatedDetails, {
                headers: { Authorization: `Bearer ${token}` }
            });

            toast.success('Dettagli aggiornati con successo!');
        } catch (error) {
            console.error('Error updating details:', error);
            toast.error('Errore durante l\'aggiornamento dei dettagli');
        }
    };

    const handleStatusChange = (event) => {
        const newStatus = event.target.value;
        setStatus(newStatus);
        handleUpdateDetails({ status: newStatus, paymentStatus });
    };

    const handlePaymentStatusChange = (event) => {
        const newPaymentStatus = event.target.value;
        setPaymentStatus(newPaymentStatus);
        handleUpdateDetails({ status, paymentStatus: newPaymentStatus });
    };

    const handleAddService = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.put(`/requests/${id}/add-service`, { service: newService }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setServicesUsed(response.data.servicesUsed);
            setOpenServiceDialog(false);
            setNewService({ type: '', dateUsed: '', amountPaid: 0, paymentStatus: 'non pagato', paymentMethod: 'contanti' });
            toast.success('Servizio aggiunto con successo!');
        } catch (error) {
            console.error('Error adding service:', error);
            toast.error('Errore durante l\'aggiunta del servizio');
        }
    };

    const calculateTotalAmountPaid = () => {
        return servicesUsed.reduce((total, service) => total + service.amountPaid, 0);
    };

    if (!request) {
        return <div>Loading...</div>;
    }


    return (
        <div style={{                
        padding: "20px",
        border: "1px solid #4B4B4B", maxHeight: '80vh', 
        overflowY: 'auto', backgroundColor: 'black', 
        marginTop: "4.5rem", borderRadius: '0px', display: 'flex', flexDirection: 'column' }}>
                               <Typography style={{ textAlign: "center", 
                                fontFamily: "Belanosima", fontSize: "30px" }}>
                                Scheda cliente: <span style={{color: '#6490bc'}}> {request.name}</span> 
                               </Typography>
           
            <div style={{ 
                border: "1px solid #4B4B4B",
                display: 'flex', flexDirection: isMobile ? 'column' : undefined, gap: '2rem'}}>
                <div style={{ backgroundColor: "black", 
                    borderRight: '3px solid #4B4B4B', padding: "20px", flex: 1, display: 'flex', flexDirection: 'column' }}>


                    <div style={{ marginBottom: '12px',
                    backgroundColor: "#4b4b4b", borderRadius: "0.3rem",
                    padding: "10px", marginTop: "0.5rem", display: "flex", 
                        flexDirection: isMobile ? 'column' : undefined,
                        alignItems: "center", justifyContent: "space-around" }}>
                        <div style={{display: "flex", flexDirection: isMobile ? 'column' : undefined, gap: "1rem", alignItems: "center"}}>
                            <Typography style={{display: "flex", 
                                alignItems: 'center', fontFamily: "Belanosima"}}>
                                <FontAwesomeIcon icon={faEnvelope} style={{marginRight: "8px"}}/> {request.email}
                            </Typography>
                            {!isMobile && (  <span style={{color: "white"}}>-</span> )}
                            <Typography style={{display: "flex", alignItems: 'center', fontFamily: "Belanosima"}}>
                                <FontAwesomeIcon icon={faWhatsapp} style={{color: "limegreen", marginRight: "8px"}}/> {request.phone}
                            </Typography>
                        </div>
                        <Typography style={{fontFamily: "Belanosima"}}>
                            Importo speso in L.A.R. <span style={{color: "#6490bc"}}>{calculateTotalAmountPaid()}</span> <FontAwesomeIcon icon={faEuro} style={{color: "#6490bc"}}/>
                        </Typography>
                    </div>

                    <div style={{ marginTop: '1.5rem', flex: 1 }}>
                        <div style={{display: 'flex', gap: isMobile ? '0.3rem' : undefined, flexDirection: isMobile ? 'column' : undefined,alignItems: 'center', justifyContent: "space-between"}}>
                            <Typography style={{ textAlign: "center", fontSize: isMobile ? '18px' : "24px", fontFamily: "Belanosima" }}>
                                <FontAwesomeIcon icon={faListAlt} style={{ color: "#6490bc", marginRight: "15px" }} />
                                Storico Servizi
                                <FontAwesomeIcon icon={faHistory} style={{ color: "#6490bc", marginLeft: "15px" }} />
                            </Typography>
                            {!isMobile && (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                                onClick={() => setOpenServiceDialog(true)}
                                style={{ color: "white", backgroundColor: "#007BFF" }}
                            >
                                Aggiungi Servizio
                            </Button>
                            )}
                        </div>

                    
                        {servicesUsed.length === 0 ? (
                    <Typography style={{fontSize: "20px", fontFamily: "Belanosima"}}>
                        Il cliente non ha ancora usato nessun servizio L.A.R.
                    </Typography>
                ) : (
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? 'repeat(1,1fr)' : 'repeat(2, 1fr)',
                    gap: '16px',
                    marginTop: "0.8rem",
                    marginBottom: '16px',
                    overflowY: 'auto',
                    flex: 1
                }}>
                    {servicesUsed.map((service, index) => (
            <div key={index} style={{
                padding: '10px',
                borderBottom: '2px solid white',
                backgroundColor: "#4b4b4b", 
                borderRadius: '0.4rem',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',

            }}>
                <Typography style={{ color: "#6490bc",fontSize: "18px", fontFamily: "Belanosima" }}>Servizio {service.type} - <span style={{color: "white"}}>
                    {new Date(service.dateUsed).toLocaleDateString()}
                    </span>
                    </Typography>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Typography style={{ fontSize: "18px", fontFamily: "Belanosima" }}><span style={{color: "#6490bc"}}> {service.amountPaid} € </span> -  {service.paymentMethod}</Typography>
                <Typography 
      style={{ 
        fontSize: "16px", 
        fontFamily: "Belanosima",
        color: paymentStatus === 'non pagato' ? 'red' : 
               paymentStatus === 'parzialmente pagato' ? 'orange' : 
               paymentStatus === 'pagato' ? 'green' : 'black'
      }}> {paymentStatus}
    </Typography>    
    </div>        </div>
        ))}
        
    </div>
    
)}

                        {isMobile && (
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                                onClick={() => setOpenServiceDialog(true)}
                                style={{ color: "white", backgroundColor: "#007BFF" }}
                            >
                                Aggiungi Servizio
                            </Button>
                            </div>
                            )}
                    </div>
                </div>
                
                <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{display: "flex", alignItems: "center", gap: isMobile ? '1rem': undefined, flexDirection: isMobile ? 'column' : undefined, justifyContent: "space-between"}}>
                        <Typography style={{ fontFamily: "Belanosima" }}>Conversazione - Richiesta: <span style={{color: '#6490bc'}}>{request.type}</span></Typography>
                        <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
                            <FormControl>
                                <InputLabel>Stato Richiesta</InputLabel>
                                <Select
                                    value={status}
                                    onChange={handleStatusChange}
                    
                                    style={{
                                        color: "white", backgroundColor: '#1e1e1e'}}
                                >
                                    <MenuItem value="in attesa">In attesa ⌛</MenuItem>
                                    <MenuItem value="attiva">Attiva 🟩</MenuItem>
                                    <MenuItem value="completa">Completa ✅</MenuItem>
                                    <MenuItem value="archiviata">Archiviata 🗃️</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Stato Pagamento</InputLabel>
                                <Select
                                    value={paymentStatus}
                                    onChange={handlePaymentStatusChange}
                                    label="Stato Pagamento"
                                    style={{
                                        color: "white", backgroundColor: '#1e1e1e'}}
                                >
                            <MenuItem value="non pagato">Non Pagato 🔴
                            </MenuItem>
                            <MenuItem value="parzialmente pagato">Parzialmente pagato 🟡</MenuItem>
                            <MenuItem value="pagato">Pagato 🟢</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <Box style={{ marginTop: "1rem", boxShadow: '0 1px 3px rgba(0,0,0,0.1)', maxHeight: '400px', overflowY: 'auto' }}>
                        {request.conversation.map((msg, index) => (
                            <Box key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', padding: '16px', borderRadius: '8px', backgroundColor: msg.sender === 'admin' ? 'black' : '#007BFF' }}>
                                <Avatar>{msg.sender.charAt(0).toUpperCase()}</Avatar>
                                <Box style={{ marginLeft: '16px' }}>
                                    <Typography style={{fontFamily: "Belanosima"}}>{msg.sender}</Typography>
                                    <Typography style={{fontFamily: "Belanosima"}} variant="body2">{msg.message}</Typography>
                                    <Typography style={{fontFamily: "Belanosima", color: msg.sender === 'admin' ? "#007BFF" : 'black'}} variant="caption">{new Date(msg.timestamp).toLocaleString()}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box style={{ display: "flex", gap: "3rem", alignItems: "center", marginTop: '24px' }}>
                        <TextField
                            label="Rispondi"
                            multiline
                            rows={2}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            fullWidth
                        />
                        <Button style={{backgroundColor: "#007BFF"}} onClick={handleSendMessage}>
                            Invia
                        </Button>
                    </Box>
                </Box>
            </div>
            <Dialog  open={openServiceDialog} onClose={() => setOpenServiceDialog(false)}>
                <DialogTitle>Aggiungi Servizio</DialogTitle>
                <DialogContent >
                    <DialogContentText  style={{color: "#007BFF"}}>
                        Compila i dettagli del nuovo servizio utilizzato dal cliente.
                    </DialogContentText>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Nome Servizio</InputLabel>
                        <Select
                            value={newService.type}
                            onChange={(e) => setNewService({ ...newService, type: e.target.value })}
                            label="Nome Servizio"
                        >
                            <MenuItem value="Elicotteri">Elicotteri</MenuItem>
                            <MenuItem value="Supercar">Supercar</MenuItem>
                            <MenuItem value="NCC">NCC</MenuItem>
                            <MenuItem value="Pubblicità Aerea">Pubblicità Aerea</MenuItem>
                            <MenuItem value="Jet Privati">Jet Privati</MenuItem>
                            <MenuItem value="Yacht e Barche di Lusso">Yacht e Barche di Lusso</MenuItem>
                            <MenuItem value="Security">Security</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Data Utilizzo"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={newService.dateUsed}
                        onChange={(e) => setNewService({ ...newService, dateUsed: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Importo Pagato"
                        type="number"
                        fullWidth
                        value={newService.amountPaid}
                        onChange={(e) => setNewService({ ...newService, amountPaid: e.target.value })}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Stato Pagamento</InputLabel>
                        <Select
                            value={newService.paymentStatus}
                            onChange={(e) => setNewService({ ...newService, paymentStatus: e.target.value })}
                            label="Stato Pagamento"
                        >
                            <MenuItem value="non pagato">Non Pagato 🔴
                            </MenuItem>
                            <MenuItem value="parzialmente pagato">Parzialmente pagato 🟡</MenuItem>
                            <MenuItem value="pagato">Pagato 🟢</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Metodo Pagamento</InputLabel>
                        <Select
                            value={newService.paymentMethod}
                            onChange={(e) => setNewService({ ...newService, paymentMethod: e.target.value })}
                            label="Metodo Pagamento"
                        >
                            <MenuItem value="contanti">Contanti</MenuItem>
                            <MenuItem value="carta">Carta</MenuItem>
                            <MenuItem value="bonifico">Bonifico</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenServiceDialog(false)} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleAddService}>
                        Aggiungi
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </div>
    );
};

export default RequestDetails;
