import {
    Typography,
    Button,
    useTheme,
    useMediaQuery,
    Dialog,
    DialogContent,
    IconButton,
  } from '@mui/material';
import React from 'react';
  import { faEnvelope, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
  import CloseIcon from '@mui/icons-material/Close';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { Trans, useTranslation } from 'react-i18next';
  


  // Import images
  import lux from '../../../../../assets/lux.png';
  import gold from '../../../../../assets/gold.png';
  import vescovio1 from '../../../../../assets/images/pranzo/oasi1.jpg';
  import vescovio2 from '../../../../../assets/images/pranzo/oasi2.jpg';
  import vescovio3 from '../../../../../assets/images/pranzo/oasi3.jpg';
  import vescovio4 from '../../../../../assets/images/pranzo/oasi4.jpg';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../../../../../lang/i18n';
  
  const images = [vescovio1, vescovio2, vescovio3, vescovio4];


  function VoloVescovio() {
    const { t } = useTranslation();
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

 // Style for the main container
 const mainContainerStyle = {
    width: "auto",
    marginTop: '5rem',
    padding: "40px",
    borderRadius: "1rem",
    color: 'white',
    display: 'flex',
    background: `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)), url(${vescovio2}) center / ${
        isMobile ? 'cover' : 'cover'
      } no-repeat`,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  };

  const serviceImages = images.map((image, index) => (
    <img
      key={`image-${index}`}
      src={image}
      alt={`Service ${index + 1}`}
      style={{
        width: isMobile ? '300px' : 'auto',
        boxShadow: '0px 0px 10px 0px black',
        border: '1px solid black',
        objectFit: 'contain',
        height: isMobile ? 'auto' : '120px',
        borderRadius: '1rem',
        cursor: 'pointer',
      }}
      onClick={() => handleImageClick(index)}
    />
  ));
  
    // Style for the ad section
    const adSectionStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
      const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      };
    
      const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      };
    
  
    return (
      <div style={mainContainerStyle}>
        <Helmet>
    <title>{t('MetaVescovioTitle')}</title>
    <meta name="description" content={t('MetaVescovioDesc')} />
    <meta name="keywords" content="tour di lusso Roma, elicottero Roma, noleggio supercar Roma, esperienze esclusive Roma, tour esclusivi Roma, jet privato Roma, vacanze di lusso Roma"></meta>
    <meta name="author" content="Luxury Agency Rome" />
    <meta name="publisher" content="Luxury Agency Rome" />
    <meta property="og:title" content={t('MetaVescovioTitle')} />
    <meta property="og:description" content={t('MetaVescovioDesc')} />
    <meta property="og:url" content="https://www.luxuryagencyrome.it/elicotteri/tour/vescovio" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.luxuryagencyrome.it/elicotteri/tour/vescovio" />
    <html lang={i18n.language} />
</Helmet>
        <img
          src={lux}
          alt='Luxury'
          style={{ width: isMobile ? '100%' : '500px', objectFit: 'contain' }}
        />
        <img
          src={gold}
          alt='Gold'
          style={{ marginTop: '1rem', width: isMobile ? '100%' : '500px', objectFit: 'contain' }}
        />
  
        <div style={adSectionStyle}>
          <Typography variant='h2' sx={{ color: '#6490bc', fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
            {t('VoloConPranzoDetails.VoloConPranzo4')}
          </Typography>
        </div>
  
          <div style={{ alignItems: "center", justifyContent: "center", display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <Typography sx={{ marginTop: '2rem', textAlign: 'center', fontSize: '18px' }}>
              <Trans i18nKey='VoloConPranzoDetails.descriptionVolo4' />
            </Typography>


                <div style={{display: "flex", alignItems: "center", justifyContent: 'center', gap: "1rem"}}>
            {!isMobile && (
            <>
              {serviceImages}
              </>
          )}

</div>






            {isMobile && (
            <div
              style={{
                display: 'flex',
             
                flexDirection: isMobile ? 'column' : undefined,
                gap: isMobile ? '1rem' : '1rem',
                width: "100%",
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {serviceImages}
            </div>
)}
            
          </div>

  <Link to='/contact'>
        <Button
          sx={{ mt: 4, backgroundColor: '#6490bc', color: 'black', border: '1px solid white' }}
        >
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} /> {t('Prenota')}
          <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: '10px' }} />
        </Button>
        </Link>
         {/* Modal/Lightbox Implementation */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>

        <DialogContent style={{backgroundColor: 'white', borderRadius: "0.7rem", 
        padding: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
         
         <Typography  gutterBottom style={{fontSize: "30px", color: "black"}}>
            Gallery
        </Typography>
        <img src={images[currentIndex]} alt={`Selected ${currentIndex + 1}`} style={{ borderRadius: "0.5rem", width: '100%' }} />
          <div style={{ display: 'flex', gap: '3rem', marginTop: '1rem' }}>
            <IconButton onClick={handlePrev}>
              <FontAwesomeIcon style={{ color: "black"}}icon={faChevronLeft} />
            </IconButton>
            <IconButton onClick={handleClose} style={{ color: "#e74c3c"}}>
            <CloseIcon />
          </IconButton>
            <IconButton onClick={handleNext}>
              <FontAwesomeIcon style={{ color: "black"}}icon={faChevronRight} />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
      </div>
    );
  }
  
  export default VoloVescovio;
  