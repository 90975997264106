import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../theme/Email.css';

const EmailThread = ({ thread }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card  className="email-card">
            <CardContent>
                <Box className="email-thread-header">
                    <Typography style={{fontFamily: "Belanosima"}}>
                        {thread.from}
                    </Typography>
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        className="email-expand-button"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {thread.emails.map(email => (
                        <Box key={email._id} className="email-thread-content">
                            <Typography style={{fontFamily: "Belanosima"}} className="email-card-title">
                                Oggetto: <span style={{color: "#6490BC"}}> {email.subject}</span>
                            </Typography>
                            <Typography style={{fontFamily: "Belanosima"}}className="email-card-date">
                                Data: {new Date(email.date).toLocaleString()}
                            </Typography>
                            <Typography style={{fontFamily: "Belanosima"}} className="email-card-body">
                                {email.body}
                            </Typography>
                        </Box>
                    ))}
                </Collapse>
            </CardContent>
        </Card>
    );
};

export default EmailThread;
