import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { Typography, useMediaQuery, Popover, Box, Button, Grid, Tabs, Tab, TextField, InputAdornment, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faCheckCircle, faSearch, faExclamationTriangle, faList } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PostList from './blog/PostList';
import MemoList from './components/MemoList';
import MemoForm from './components/MemoForm';
import MemoTodoList from './components/MemoTodoList';
import RequestList from './components/RequestList';
import axios from 'axios';
import logo from "../assets/logo.png";
import { io } from 'socket.io-client';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TooltipStatus from "./components/TooltipStatus";
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import ClientList from './components/ClientList';
import EmailList from './components/EmailList';

const Dashboard = () => {
    const navigate = useNavigate();
    const { user, logout, loading } = useContext(AuthContext);
    const [postCount, setPostCount] = useState(0);
    const [subscriberCount, setSubscriberCount] = useState(0);
    const [requestCount, setRequestCount] = useState(0);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [apiErrors, setApiErrors] = useState([]);
    const [memos, setMemos] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    useEffect(() => {
        const fetchData = async () => {
            const errors = [];
    
            try {
                const postResponse = await axios.get('/posts/count');
                setPostCount(postResponse.data.postCount);
            } catch (error) {
                console.error('Error fetching posts number:', error);
                errors.push({ statusCode: error.response?.status, message: 'Errore nel recupero del conteggio dei post' });
            }
    
            try {
                const token = localStorage.getItem('authToken');
                const subscriberResponse = await axios.get('/newsletter/subscribers/count', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setSubscriberCount(subscriberResponse.data.count);
            } catch (error) {
                console.error('Error fetching subscriber count:', error);
                errors.push({ statusCode: error.response?.status, message: 'Errore nel recupero del conteggio degli iscritti' });
            }
    
            try {
                const token = localStorage.getItem('authToken');
                const requestResponse = await axios.get('/requests/count', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRequestCount(requestResponse.data.count);
            } catch (error) {
                console.error('Error fetching request count:', error);
                errors.push({ statusCode: error.response?.status, message: 'Errore nel recupero del conteggio delle richieste' });
            }
    
            try {
                const token = localStorage.getItem('authToken');
                const memosResponse = await axios.get('/memos', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setMemos(memosResponse.data);
            } catch (error) {
                console.error('Error fetching memos:', error);
                errors.push({ statusCode: error.response?.status, message: 'Errore nel recupero delle memo' });
            }
    
            setApiErrors(errors);
        };
    
        fetchData();
        const interval = setInterval(fetchData, 30000); // Aggiorna ogni 30 secondi
    
        return () => clearInterval(interval);
    }, []);
    

    useEffect(() => {
        // const socket = io('http://localhost:8000');
        const socket = io('https://api-lar-014fd3aff401.herokuapp.com');
        socket.on('memoUpdate', (data) => {
            switch (data.type) {
                case 'create':
                    toast.success(`🔔 - Notifica - 🔔 Nuova Memo aggiunta da ${data.memo.addedBy}`);
                    setMemos((prevMemos) => [data.memo, ...prevMemos]);
                    break;
                case 'update':
                    toast.info(`🔔 - Notifica - 🔔 Memo aggiornata: ${data.memo.content}`);
                    setMemos((prevMemos) => prevMemos.map((memo) => memo._id === data.memo._id ? data.memo : memo));
                    break;
                case 'delete':
                    toast.error(`🔔 - Notifica - 🔔 Memo eliminata: ${data.memo.content}`);
                    setMemos((prevMemos) => prevMemos.filter((memo) => memo._id !== data.memo._id));
                    break;
                default:
                    break;
            }
        });

        socket.on('requestUpdate', (data) => {
            if (data.type === 'create') {
                toast.success(`📬 Nuova richiesta ricevuta: ${data.request.name} - ${data.request.type}`);
                setRequestCount(prevCount => prevCount + 1);
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const formatDate = (date) => {
        const optionsDate = { weekday: 'short', year: '2-digit', month: '2-digit', day: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        const dateString = date.toLocaleDateString('it-IT', optionsDate);
        const timeString = date.toLocaleTimeString('it-IT', optionsTime);
        return (
            <div style={{ display: "flex", marginTop: isMobile ? '0.5rem' : undefined, flexDirection: isMobile ? 'column' :undefined, marginRight: "10px", 
            textAlign: isMobile ? 'center' : "right", alignItems: "center" }}>
                {dateString} {!isMobile && ( <br/>)} -  {timeString}
            </div>
        );
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setSearchTerm('');
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <Navigate to="/admin/access" />;
    }

    const systemStatus = apiErrors.length === 0 ? 'Server: LIVE ' : 'Server: Errori Rilevati';

    
    return (
        <div style={{
            padding: "5px",
        
        }}>

                            <Typography
                        style={{
                            fontSize: isMobile ? '22px' : "30px",
                            marginTop: "4.5rem", textAlign: "center",
                            marginBottom: '0.4rem',
                            color: 'white',
                            fontFamily: "Belanosima"
                        }}>
                          Admin Dashboard - Ciao {user.username}
                         </Typography>
                         
                         {isMobile && (
                            <Typography style={{ fontFamily: 'Belanosima', marginBottom: "0.5rem", fontSize: "16px", color: "grey" }}>
                                {formatDate(currentTime)}
                            </Typography>
                            )}
        
            <div style={{
                
                marginBottom: "3rem",
                borderRadius: "0rem",
                display: "flex",
                borderBottom: '1px solid grey',
                borderTop: "1px solid grey",
                height: "auto" // Set the height to take up the full remaining vertical space
            }}>
                 
                <Grid container>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" style={{
                            backgroundColor: "black",
                            padding: "0px",
                            borderBottom:"1px solid grey",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            flexDirection: isMobile ? 'column' : undefined
                        }} justifyContent="space-between">
                            <div style={{display:"flex",  gap: "0.4rem", alignItems: "center"}}>
                           {!isMobile && (
                            <img src={logo} alt='Luxury' style={{ width: isMobile ? '80px' : "80px", marginLeft: "5px", objectFit: 'contain' }} />
                           )}
                            </div>
                            <Box style={{ display: "flex", marginTop: isMobile ? '0.4rem' : undefined,
    flexDirection: isMobile ? 'column' : undefined }} alignItems="center" justifyContent="center">
    
        <Box  onClick={handleClick} display="flex" alignItems="center" style={{ flexDirection: "column", cursor: 'pointer' }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon icon={apiErrors.length === 0 ? faCheckCircle : faExclamationTriangle} 
                style={{ marginRight: '10px', color: apiErrors.length === 0 ? 'limegreen' : '#e74c3c' }} />
                <Typography style={{ fontFamily: 'Belanosima', color: 'white', fontSize: '14px' }}>
                    {systemStatus}
                </Typography>
                <FontAwesomeIcon icon={apiErrors.length === 0 ? faCheckCircle : faExclamationTriangle} style={{
                    marginLeft: '10px', color: apiErrors.length === 0 ? 'limegreen' : '#e74c3c'
                }} />
            </div>
            {apiErrors.length === 0 ? (
                <span style={{ fontSize: "12px", color: "grey" }}>
                    Tutti i sistemi funzionano
                </span>
            ) : (
                <span style={{ fontSize: "12px", color: "grey" }}>
                    Controlla gli errori
                </span>
            )}
        </Box>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
    >
        <Box style={{backgroundColor:"black", borderRadius: "0.5rem", border: "1px solid white" }} p={2}>
            {apiErrors.length > 0 ? (
                <TooltipStatus errors={apiErrors} />
            ) : (
                <Typography style={{ fontSize: '16px', color: 'limegreen' }}>
                    Stato: (200) - Ok!
                </Typography>
            )}
        </Box>
    </Popover>
</Box>


                            <a href='https://https://t.me/+CI0Z8ihsmrg2ODQ0'
                            target='blank' rel='noreferrer noopener'>
                        <Typography style={{display: "flex", 
                        fontSize: "14px", marginTop: isMobile ? "0.5rem" : undefined,
                            fontFamily: "Belanosima",
                            alignItems: "center"}}>
                            GRUPPO STAFF
                         <FontAwesomeIcon style={{marginLeft: "10px"}} icon={faTelegram}/>
                            </Typography>
                            </a>

                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                style={{ marginTop: isMobile ? '0.5rem' : 0, 
                                    marginBottom: isMobile ? "1rem" : 0, borderBottom: 'none' }} // Rimuovi il bordo inferiore dell'indicatore
                                TabIndicatorProps={{ style: { display: 'none' } }} // Rimuovi l'indicatore
                            >
                                <Tab
                                    style={{
                                        fontFamily: 'Belanosima',
                                        color: "white", borderRadius: "0.3rem",
                                        backgroundColor: selectedTab === 0 ? 'black' : 'transparent', // Cambia lo sfondo se selezionato
                                    }}
                                    label="Clienti"
                                />
                                <Tab
                                    style={{
                                        fontFamily: 'Belanosima',
                                        color: "white",borderRadius: "0.3rem",
                                        backgroundColor: selectedTab === 1 ? 'black' : 'transparent',
                                    }}
                                    label="Richieste"
                                />
                                 <Tab
                    style={{
                        fontFamily: 'Belanosima',
                        color: "white",
                        borderRadius: "0.3rem",
                        backgroundColor: selectedTab === 2 ? 'black' : 'transparent',
                    }}
                    label="Email" // Nuova tab per le email
                />
                                <Tab
                                    style={{
                                        fontFamily: 'Belanosima',
                                        color: "white", borderRadius: "0.3rem",
                                        backgroundColor: selectedTab === 3 ? 'black' : 'transparent',
                                    }}
                                    label="Blog"
                                />
                                 <Tab
                                    style={{
                                        fontFamily: 'Belanosima',
                                        color: "white", borderRadius: "0.3rem",
                                        backgroundColor: selectedTab === 4 ? 'black' : 'transparent',
                                    }}
                                    label="Memo"
                                />
                                 {/* <Tab
                                    style={{
                                        fontFamily: 'Belanosima',
                                        color: "white",
                                        backgroundColor: selectedTab === 3 ? '#180060' : 'transparent',
                                        borderBottom: selectedTab !== 3 ? '1px solid white' : 'none'
                                    }}
                                    label="Social"
                                /> */}
                            </Tabs>


                            <TextField
                                placeholder="Cerca..."
                                value={searchTerm}
                               
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FontAwesomeIcon style={{ color: "white" }} icon={faSearch} />
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ width: isMobile ? '100%' : "320px", 
                                    marginRight: isMobile ? 0 : '20px',
                                    backgroundColor: "black", color: "white", marginBottom: isMobile ? '15px' : '0' }}
                            />

                            {!isMobile && (
                            <Typography style={{ fontFamily: 'Belanosima', fontSize: "16px", color: "grey" }}>
                                {formatDate(currentTime)}
                            </Typography>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8} style={{
                        backgroundColor: '#1',
                        overflow: "auto", padding: '10px' }}>
                             {selectedTab === 0 && (
                           <div style={{ display: "flex", marginTop: "5px", borderRadius: '0.5rem' }}>
                                <ClientList searchTerm={searchTerm} />
                            </div>
                        )}
                          {selectedTab === 1 && (
                           <div style={{ display: "flex", marginTop: "5px", borderRadius: '0.5rem' }}>
                                <RequestList searchTerm={searchTerm} />
                            </div>
                        )}
                         {selectedTab === 2 && (
                        <div style={{ display: "flex", marginTop: "5px", borderRadius: '0.5rem' }}>
                            <EmailList searchTerm={searchTerm} />
                        </div>
                    )}
                        {selectedTab === 3 && (
                            <div style={{ display: "flex", marginTop: "5px", borderRadius: '0.5rem' }}>
                                <PostList searchTerm={searchTerm} />
                            </div>
                        )}
                        {selectedTab === 4 && (
                            <div style={{ display: "flex", marginTop: "5px", borderRadius: '0.5rem' }}>
                                <MemoList searchTerm={searchTerm} />
                            </div>
                        )}
                      
                        
                           {/* {selectedTab === 3 && (
                            <div style={{ display: "flex", marginTop: "5px", borderRadius: '0.5rem' }}>
                                <InstagramInsights />
                            </div>
                        )} */}
                    </Grid>

                    <Grid item xs={12} md={4} style={{ 
                    height: "auto", backgroundColor: 'black',
                         display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flexGrow: 1,
                        
                             borderLeft: isMobile ? 'none' : '1px solid grey',
                             padding: "10px", display: "flex", gap: "1rem", flexDirection: 'column' }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography style={{ fontFamily: "Belanosima", color: "white", fontSize: '18px' }}>
                                    Utenti iscritti alla Newsletter: {subscriberCount}
                                    <FontAwesomeIcon style={{ fontFamily: "Belanosima", marginLeft: "10px" }} icon={faUserAlt} />
                                </Typography>
                                <Button style={{ color: "black", color: "white", backgroundColor: "#6490bc", border: "1px solid black" }} onClick={() => navigate('/admin/newsletter-users')}>
                                    Lista <FontAwesomeIcon icon={faList} style={{marginLeft: "5px"}}/>
                                </Button>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography style={{ fontFamily: "Belanosima", color: "white", fontSize: '18px' }}>
                                    Richieste ricevute: {requestCount}
                                    <FontAwesomeIcon style={{ fontWeight: "bold", marginLeft: "10px" }} icon={faCheckCircle} />
                                </Typography>a vbo
                            </div>
                            <MemoTodoList memos={memos} />

                            <MemoForm />
                        </div>
                    </Grid>
                </Grid>
                <ToastContainer />
            </div>
            </div>
    );
};

export default Dashboard;
