import { Box, Typography, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

// Importazione delle immagini
import pilota from "../../assets/images/heli2.jpg";
import ferrari from "../../assets/images/ferrari.jpg";
import ncc from '../../assets/ncc.jpg';
import Yacht from '../../assets/images/yacht.avif';
import security from "../../assets/images/security.jpg";
import jet from "../../assets/images/jet.jpg";
import pubae from "../../assets/images/pubblicita/pubae.jpg";
import { Link } from 'react-router-dom';

function Categorie() {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    const { t } = useTranslation();

    const services = [
        { title: t('ElicotteriTitle'), img: pilota, link: "/elicotteri" },
        {title: t('PubblicitaAerea'), img: pubae, link: "/pubblicita-aerea"},
        { title: t('NoleggioTitle'), img: ferrari, link: "/supercar" },
        { title: t('NCC'), img: ncc,  link: "/ncc" },
        { title: t('JetPrivati'), img: jet, link: "/jet" },
        { title: t('Yacht'), img: Yacht, link: "/yacht" },
        { title: t('Security'), img: security, link: "/security" }
    ];

    return (
        <div style={{display: "flex", width: "90%", padding: "20px", flexDirection: 'column', alignItems: "center" }}>
            <Typography variant="h4" sx={{ mb: isMobile ? 5 : 2, color: '#6490bc', textAlign: 'center' }}>
                {t('NostriServizi')}
            </Typography>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(7, 1fr)',
                gap: 4,
                alignItems: "center",
                justifyContent: 'center'
            }}>
                {services.map((service, index) => (
                    <CategoryCard key={index} service={service} />
                ))}
            </Box>
        </div>
    );
}

function CategoryCard({ service }) {
    const { title, img, link } = service;

    return (
        <Link to={link}>
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.1, }}
            style={{ position: 'relative', 
                borderTopRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                clipPath: 'polygon(30px 0%, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0% 30px)',
                width: '100%', height: "300px", overflow: 'hidden',
            
            borderRadius: '8px', cursor: 'pointer' }}
        >
            <img src={img} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.5s', '&:hover': { transform: 'scale(1.1)' } }} />
            <Box sx={{

                position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', opacity: 0, transition: 'opacity 0.5s',
                '&:hover': { opacity: 1 }
            }}>
                <Typography  sx={{ backgroundColor: 'black', padding: "10px", borderRadius: "0.4rem", color: 'white', textShadow: '0 2px 4px rgba(0,0,0,0.5)', zIndex: 10 }}>
                    {title}
                </Typography>
            </Box>
        </motion.div>
        </Link>
    );
}

export default Categorie;
