import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, useMediaQuery, Tooltip } from '@mui/material';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faGlasses, faLink } from '@fortawesome/free-solid-svg-icons';
import lux from "../../assets/lux.png";
import SidebarBlog from './SidebarBlog';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PostDetail = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`/posts/slug/${slug}`);
                setPost(response.data);

                // Increment the post views
                await axios.put(`/posts/increment-views/${slug}`);
            } catch (error) {
                console.error('Error fetching post:', error);
            }
        };

        fetchPost();
    }, [slug]);

    if (!post) {
        return <div>Loading...</div>;
    }

    const formattedDate = new Date(post.createdAt).toLocaleDateString('it-IT', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    const handleCopyLink = (url) => {
        toast.success('Link copiato negli appunti!');
    };

    return (
        <Container style={{
            marginBottom: "3rem",
            marginTop: isMobile ? '3rem ': "6rem"}} maxWidth="lg">
            <div style={{display: "flex", 
            gap: isMobile ?  "0.8rem" : 0,
                alignItems: isMobile ?  "center" : undefined,
                flexDirection: isMobile ? 'column' : undefined, width:"100%", justifyContent:'space-between'}}>
            <Typography variant="h4" style={{display: "flex", 
                color: '#6490bc',
                    alignItems: "center"}} component="h1">
                BLOG
            </Typography>
                <img src={lux} alt='Luxury' style={{ width: isMobile ? '100%' : "500px", objectFit: 'contain' }} />
            </div>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', mt: 4 }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h4" style={{display: "flex", alignItems: "center"}} component="h1" >
                        {post.title}
                    </Typography>

                    <div style={{display: 'flex',
                    marginTop: isMobile ? '0.5rem' : 0, 
                        flexDirection: isMobile ? 'column' : undefined,
                        justifyContent: isMobile ? 'center' : "space-between", alignItems: "center"}}>
                  <div style={{display: "flex", alignItems: "center", 
                  flexDirection: isMobile ? 'column' : undefined,
                        justifyContent: isMobile ? 'center' : "space-between", gap: isMobile ? '2px' : "1rem"}}>
                    <Typography style={{fontFamily: 'Belanosima', fontSize: "16px"}} color='grey' >
                    Tempo di lettura:                        <span style={{color: "#6490bc"}}>{post.readingTime} <FontAwesomeIcon icon={faGlasses} style={{marginLeft: "5px", color: "#6490bc"}}/></span>
                    </Typography>
                    {!isMobile && (
                    <span style={{color: "grey"}}>⎯</span>
                    )}
                    <Typography style={{fontFamily: 'Belanosima', fontSize: "16px"}} color='grey' >
                       <span style={{color: "#6490bc"}}>Views: {post.views}  <FontAwesomeIcon icon={faEye} style={{marginLeft: "5px", color: "#6490bc"}}/></span>
                    </Typography>
                    </div>
                    <div style={{gap: '1rem', marginTop: isMobile ? '1rem' : 0, display: 'flex', alignItems: "center", justifyContent: 'space-between'}}>   
                        <Tooltip title='Condividi su Facebook'>
                            <FacebookShareButton url={window.location.href}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            </Tooltip>
                            <Tooltip title='Condividi su WhatsApp'>
                            <WhatsappShareButton url={window.location.href}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            </Tooltip>
                            <Tooltip title='Condividi su X'>
                            <TwitterShareButton url={window.location.href}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            </Tooltip>
                            <Tooltip title='Condividi su Telegram'>
                            <TelegramShareButton url={window.location.href}>
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>
                            </Tooltip>
                       
                        <CopyToClipboard text={window.location.href} onCopy={() => handleCopyLink(window.location.href)}>
                            <Tooltip title="Copia link">
                                <FontAwesomeIcon icon={faLink} style={{fontSize: "22px", marginBottom: "7px", color: "white", cursor: 'pointer' }} />
                            </Tooltip>
                        </CopyToClipboard>
                    </div>
                    </div>
                  
                   
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <img src={post.coverImage || lux} alt={post.title} style={{ width: '100%', height: 'auto' }} />
                    </Box>
                    <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: post.content }} />
                   
                </Box>
                {!isMobile && (
                    <Box sx={{ width: 300, ml: 4 }}>
                        <SidebarBlog />
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default PostDetail;
