import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Link } from '@mui/material';

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <Box
            position="fixed"
            bottom="0"
            width="100%"
            bgcolor="white"
            color="black"
            p={2}
            borderTop='1px solid white'
            display="flex"
            justifyContent="space-around"
            alignItems="center"
        >
            <Typography style={{color: "black"}} variant="body2">
                Questo sito utilizza i cookie per migliorare l'esperienza dell'utente. Continuando la navigazione acconsenti all'uso dei cookie. I tuoi dati non verranno ceduti a terze parti e saranno utilizzati solo per rispondere alle tue richieste.<br/> Per maggiori informazioni, leggi la nostra <Link href="/privacy-policy" color="inherit" underline="always">Politica sulla Privacy</Link>.
            </Typography>
            <Button style={{marginRight: "20px"}} variant="contained" color="primary" onClick={handleAccept}>
                     Accetta
                </Button>

        </Box>
    );
};

export default CookieBanner;
