import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const ArticlesContext = createContext();

export const useArticles = () => useContext(ArticlesContext);

export const ArticlesProvider = ({ children }) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('/posts');
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    return (
        <ArticlesContext.Provider value={articles}>
            {children}
        </ArticlesContext.Provider>
    );
};
