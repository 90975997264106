import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Container, TextField, Button, Typography, useMediaQuery, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import axios from 'axios';
import { useNavigate, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import lux from '../../assets/lux.png';
import { AuthContext } from '../../context/AuthContext';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreatePost = () => {
  const { user } = useContext(AuthContext);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const navigate = useNavigate();
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  const handleSubmit = async (e) => {  
    e.preventDefault();
    try {
      const postData = {
        title,
        content,
        category,
        tags: tags.split(',').map(tag => tag.trim()),
        coverImage,
        excerpt
      };
      await axios.post('/posts', postData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  const modules = useMemo(() => ({
    toolbar: [
      [{ 'header': '1'}, { 'header': '2'}, { 'header': '3'}, { 'header': '4'}, { 'header': '5'}, { 'header': '6'}, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      [{ 'align': [] }],
      ['clean']  // rimuove la formattazione
    ],
    clipboard: {
      matchVisual: false,
    },
  }), []);

  const formats = [
    'header', 'font', 'size', 'align',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  useEffect(() => {
    const quill = document.querySelector('.ql-editor');
    if (quill) {
      quill.setAttribute('style', 'font-size: 16px');
    }
  }, []);

  if (!user) {
    return <Navigate to="/admin/access" />;
  }

  return (
    <div style={{display: "flex", marginTop: "5rem", marginBottom: "5rem",
       flexDirection: 'column', alignItems: "center", 
      justifyContent: "center"}}>
      <img src={lux} alt='Luxury' style={{ width: isMobile ? '100%' : "500px", objectFit: 'contain' }}/>

      <Typography style={{fontSize: "30px", marginTop: "2rem", color: "white", fontFamily: "Belanosima"}}>
        <FontAwesomeIcon icon={faPenToSquare} style={{marginRight: "10px"}} /> CREA UN POST <FontAwesomeIcon icon={faPenToSquare} style={{marginLeft: "10px"}} />
      </Typography>
      <Container style={{backgroundColor: "black", marginTop: "1rem", padding: '30px', borderRadius: "1rem"}} maxWidth="md">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Titolo"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules}
            formats={formats}
            placeholder="Scrivi il contenuto del post..."
            style={{ marginBottom: '1rem', background: "white"}}
          />
          <div style={{display: "flex",gap: "1rem", alignItems :"center"}}>
          <FormControl >
    <InputLabel>Categoria</InputLabel>
    <Select 
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        label="Categoria"
    >
        <MenuItem value="Elicotteri">Elicotteri</MenuItem>
        <MenuItem value="Supercar">Supercar</MenuItem>
        <MenuItem value="Aviazione">Aviazione</MenuItem>
        <MenuItem value="Yacht e Barche di Lusso">Yacht e Barche di Lusso</MenuItem>
        <MenuItem value="Security">Security</MenuItem>
        <MenuItem value="News">News</MenuItem>
        <MenuItem value="Luxury">Luxury</MenuItem>
        <MenuItem value="Lifestyle">Lifestyle</MenuItem>
        <MenuItem value="Attualità">Attualità</MenuItem>
        <MenuItem value="Cultura">Cultura</MenuItem>
    </Select>
</FormControl>

            <TextField
              label="Tags (separati da virgola)"
              fullWidth
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              sx={{ mb: 2 }}
            />
          </div>
          <TextField
            label="Immagine di copertina"
            fullWidth
            value={coverImage}
            onChange={(e) => setCoverImage(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Estratto/Anteprima"
            fullWidth
            value={excerpt}
            onChange={(e) => setExcerpt(e.target.value)}
            sx={{ mb: 2 }}
          />
          <center>
            <Button type="submit" style={{backgroundColor: "black"}}>
              <FontAwesomeIcon icon={faPaperPlane} style={{marginRight:"10px"}}/>
              Crea il POST! <FontAwesomeIcon icon={faPaperPlane} style={{marginLeft:"10px"}}/>
            </Button>
          </center>
        </form>
      </Container>
    </div>
  );
};

export default CreatePost;
