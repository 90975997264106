import { AppBar, Toolbar, IconButton, Link, Menu, MenuItem,  Button, Box, Drawer, useMediaQuery, List, ListItem, ListItemIcon, ListItemText, Container, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp, faTiktok, faYoutube, faBlogger } from '@fortawesome/free-brands-svg-icons';
import { useState, useContext } from 'react';
import { IT, GB } from 'country-flag-icons/react/3x2';
import {Link as Links}  from 'react-router-dom';
import gold from '../../assets/gold.png';
import navlogo from "../../assets/logo.png";
import { faHome, faHelicopter, faCar, faInfoCircle, faPlaneDeparture, faSailboat, faUserShield, faEnvelope, faPaperPlane, faPhotoVideo, faMapLocationDot, faLocationPin, faLocationDot, faListCheck, faPeopleGroup, faAddressCard, faNewspaper, faUserTie, faRightFromBracket, faJetFighterUp, faHamburger, faList, faBlog} from '@fortawesome/free-solid-svg-icons';
import logo from "../../assets/logo.png";
import { Trans, useTranslation } from 'react-i18next';
import WhatsAppMessageButton from '../../components/fixed/WhatsAppMessageButton';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Social from './Social';
import { useArticles } from '../../context/ArticleContext';

function Navbar() {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const location = useLocation();
  const [menuActive, setMenuActive] = useState(false);
  const { user, logout} = useContext(AuthContext);
  const articles = useArticles(); // Utilizza il contesto per ottenere gli articoli

  
    const fadeIn = {
      initial: { opacity: 0 },
      animate: { opacity: 1, transition: { delay: 0.5, duration: 1.5 } },
    };
  
  
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const phoneNumber = '393762023340';
  
  // Definisci i messaggi per ogni lingua usando i18n
  const messages = {
    it: "Ciao! Vorrei ricevere maggiori informazioni.",
    en: "Hello! I would like more information."
  };

  // Sceglie il messaggio in base alla lingua corrente
  const message = messages[i18n.language.slice(0, 2)] || messages.it; // Fallback alla lingua italiana se non trovato
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

   // Aggiornare il menuActive basato sulla posizione corrente
   useEffect(() => {
    if (location.pathname.includes('/')) {
      setMenuActive(true);
    } else {
      setMenuActive(false);
    }
  }, [location]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const phrases = [
    "ServicePhrase1",
    "ServicePhrase2",
    "ServicePhrase3",
    "ServicePhrase4",
    "ServicePhrase5",
    "ServicePhrase6",
    "ServicePhrase7",
    "ServicePhrase8",
    "ServicePhrase9",
  ];

  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % articles.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [articles]);

  const truncateTitle = (title, maxLength) => {
    if (title.length <= maxLength) return title;
    return title.substring(0, maxLength) + '...';
  };


  return (
    <AppBar position="fixed" color="primary" style={{
      width: '100%', display: "flex", alignItems: "center", justifyContent: "center",
      boxShadow: "none"
    }}>
      <div style={{
        width: '97%',
        borderBottom: "1px solid #6490bc",
        padding: "3px",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div style={{display: "flex", alignItems: "center"}}>
          {!isMobile && (
        <div onClick={toggleDrawer(true)}>
        <img src={navlogo} alt='LAR Logo' style={{
          marginTop: "3px",
          width: isMobile ? '60px' : "80px", objectFit: "contain" }}/>
        </div>
          )}    


        {!user && !isMobile && (
        <div style={{ display: 'flex', 
          lineHeight: 0,  gap: isMobile ? '5px' : '1rem', marginLeft: "30px"}}>
            <Tooltip title='Seleziona lingua: ITALIANO'>
          <IconButton style={{padding: 0}}  onClick={() => changeLanguage('it')}>
            <IT title="Italiano" style={{ width:  isMobile  ? '20px': '20px', height: isMobile ? '20px' : '24px' }} />
          </IconButton>
          </Tooltip>
          <Tooltip title='Select language: ENGLISH'>

          <IconButton style={{padding: 0}} onClick={() => changeLanguage('en')}>
            <GB title="English" style={{ width:  isMobile  ? '20px': '20px', height: isMobile ? '20px' : '24px' }} />
          </IconButton>
          </Tooltip>
        </div>
        )}
        </div>






                      

{!isMobile && !user && articles.length > 0 && (
          <div style={{ width: "280px", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span style={{ color: "white" }}>Ultimi post:</span>
            <Links to={`/blog/${articles[currentTitleIndex].slug}`} style={{ textDecoration: 'none' }}>
              <Typography style={{ fontFamily: "Belanosima", animation: 'slideIn 3s infinite', fontSize: isMobile ? '12px' : '12px', color: '#6490bc' }}>
                {truncateTitle(articles[currentTitleIndex].title, 40)}
              </Typography>
            </Links>
          </div> 
        )}




        {isMobile ? (
    <div onClick={toggleDrawer(true)}>
    <img src={navlogo} alt='LAR Logo' style={{
      marginTop: "3px",
      width: isMobile ? '60px' : "80px", objectFit: "contain" }}/>
    </div>
        
        ) : (

  <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
         <Links style={{color: '#6490bc'}}  to='/'>
         <Button style={{
                boxShadow: location.pathname === '/' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",

                backgroundColor: location.pathname === '/' ? '#6490bc' : '#121212',
                color: location.pathname === '/' ? 'white' : 'white',
            }}>
               <FontAwesomeIcon  style={{marginRight: '5px'}} icon={faHome} />
            {t('Home')}
            </Button>
          </Links>

          <Button style={{
                 boxShadow: location.pathname === '/servizi' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",

                 backgroundColor: location.pathname === '/servizi' ? '#6490bc' : '#121212',
                 color: location.pathname === '/servizi' ? 'white' : 'white',
            }}
        aria-controls="services-menu"
        onMouseOver={handleMenuClick}>
        <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faListCheck} />
        {t('Servizi')}
      </Button>
      <Menu
        id="services-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{ onMouseLeave: handleMenuClose }}
      >
              <Links style={{color: "white", textDecoration: 'none'}} to='/elicotteri'>
            <MenuItem style={{  backgroundColor: location.pathname === '/elicotteri' ? '#f0f0f0' : 'transparent',
                                color: location.pathname === '/elicotteri' ? 'black' : 'inherit', fontFamily: "Belanosima"}} onClick={handleMenuClose}>{t('Elicotteri')}</MenuItem>
            </Links>

            

            <Links style={{textDecoration: 'none', color: "white"}}  to='/pubblicita-aerea'>
              <MenuItem style={{
                                backgroundColor: location.pathname === '/pubblicita-aerea' ? '#f0f0f0' : 'transparent',
                                color: location.pathname === '/pubblicita-aerea' ? 'black' : 'inherit',
                borderTop: "1px solid white", fontFamily: "Belanosima"}}onClick={handleMenuClose}>{t('PubblicitaAerea')}</MenuItem>
            </Links>

            {/* <Links style={{textDecoration: 'none', color: "white"}}  to='/destinazioni'>
             <MenuItem style={{
                                backgroundColor: location.pathname === '/destinazioni' ? '#f0f0f0' : 'transparent',
                                color: location.pathname === '/destinazioni' ? 'black' : 'inherit',
                borderTop: "1px solid white", fontFamily: "Belanosima"}}>
              {t('Destinazioni')}
              </MenuItem>
          </Links> */}

            <Links style={{textDecoration: 'none', color: "white"}}  to='/supercar'>
              <MenuItem style={{borderTop: "1px solid white", 
                                              backgroundColor: location.pathname === '/supercar' ? '#f0f0f0' : 'transparent',
                                              color: location.pathname === '/supercar' ? 'black' : 'inherit',
              fontFamily: "Belanosima"}}onClick={handleMenuClose}>{t('NoleggioTitle')}</MenuItem>
            </Links>

            <Links style={{textDecoration: 'none', color: "white"}}  to='/ncc'>
              <MenuItem style={{
                                                backgroundColor: location.pathname === '/ncc' ? '#f0f0f0' : 'transparent',
                                                color: location.pathname === '/ncc' ? 'black' : 'inherit',
                borderTop: "1px solid white", fontFamily: "Belanosima"}}onClick={handleMenuClose}>NCC</MenuItem>
            </Links>

            

            <Links style={{textDecoration: 'none', color: "white"}}  to='/jet'>
              <MenuItem style={{
                                                backgroundColor: location.pathname === '/jet' ? '#f0f0f0' : 'transparent',
                                                color: location.pathname === '/jet' ? 'black' : 'inherit',
                borderTop: "1px solid white",fontFamily: "Belanosima"}}onClick={handleMenuClose}>{t('JetPrivati')}</MenuItem>
            </Links>

            <Links style={{textDecoration: 'none', color: "white"}} to='/yacht'>
              <MenuItem style={{
                                                backgroundColor: location.pathname === '/yacht' ? '#f0f0f0' : 'transparent',
                                                color: location.pathname === '/yacht' ? 'black' : 'inherit',
                borderTop: "1px solid white",fontFamily: "Belanosima"}}onClick={handleMenuClose}>{t('Yacht')}</MenuItem>
            </Links>

            <Links style={{textDecoration: 'none', color: "white"}} to='/security'>
              <MenuItem style={{
                 backgroundColor: location.pathname === '/security' ? '#f0f0f0' : 'transparent',
                 color: location.pathname === '/security' ? 'black' : 'inherit',
                borderTop: "1px solid white", fontFamily: "Belanosima"}}onClick={handleMenuClose}>Security</MenuItem>
            </Links>
            </Menu>

            <Links style={{color: '#6490bc'}} to='/blog'>
            <Button style={{
                 boxShadow: location.pathname === '/blog' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",

                 backgroundColor: location.pathname === '/blog' ? '#6490bc' : '#121212',
                 color: location.pathname === '/blog' ? 'white' : 'white',
            }}>
              <FontAwesomeIcon style={{marginRight: '5px'}} icon={faNewspaper} />
              BLOg
            </Button>
          </Links>
          </Box>
        )}
        
        {isMobile &&  !user  && (
          <div style={{display:"flex", marginLeft: "5px", alignItems: 'center', justifyContent: "center", 
            gap: "22px"
          }}>
           <Link style={{  color: "#44C354"}} to='https://wa.me/393762023340?text=Ciao!%20Vorrei%20ricevere%20maggiori%20informazioni.'>
             <FontAwesomeIcon icon={faWhatsapp} />
             </Link>
          <Link style={{color: '#3b5998'}} to='https://www.instagram.com/lar_luxuryagencyrome/'>
             <FontAwesomeIcon icon={faInstagram} />
             </Link>
             <Link style={{color: 'white'}} to='https://www.tiktok.com/@luxuryagencyrome'>
             <FontAwesomeIcon icon={faTiktok} />
             </Link>
             
             <Link style={{color: "#e74c3c"}} to='https://www.youtube.com/channel/UCKxKU5Q5T3BKQPTnBLs9tsw'>
             <FontAwesomeIcon icon={faYoutube} />
             </Link>
             </div>
        )}

        {!isMobile && (

        <div style={{display: "flex",
        justifyContent: "center", gap: '1.4rem',
         alignItems: "center", width: 'auto'}}>

             <Tooltip  title='La Nostra Storia'>
            <Links style={{display: "flex", textDecoration: "none", color: "white",
             alignItems: "center", flexDirection: "column"}} to='/about'>
              <FontAwesomeIcon 
              style={{
                cursor: 'pointer', marginTop: '5px',
                borderRadius: location.pathname === '/about' ? '0.3rem': undefined,
                padding:  location.pathname === '/about' ? '7px' : undefined,
                backgroundColor: location.pathname === '/about' ? '#6490bc' : '#121212',
                color: location.pathname === '/about' ? 'white' : 'white',
              }}
              icon={faPeopleGroup} />
              <span style={{marginTop: '4px',  color: '#6490bc', fontSize: "12px"}}>{t('ChiSiamo')}</span>
            </Links>
            </Tooltip>


        <Tooltip  title='Mandaci una richiesta di informazioni!'>
            <Links style={{display: "flex", textDecoration: "none", 
            color: "white", alignItems: "center", flexDirection: "column"}} to='/contact'>
              <FontAwesomeIcon 
              style={{
                cursor: 'pointer', marginTop: '5px',
                borderRadius: location.pathname === '/contact' ? '0.3rem': undefined,
                padding:  location.pathname === '/contact' ? '7px' : undefined,
                backgroundColor: location.pathname === '/contact' ? '#6490bc' : '#121212',
                color: location.pathname === '/contact' ? 'white' : 'white',
              }}
              icon={faEnvelope} />
              <span style={{marginTop: '4px',color: '#6490bc',  fontSize: "12px"}}>{t('ContattiButton')}</span>
            </Links>
            </Tooltip>

<Tooltip  title='Visita la nostra Gallery'>
            <Links style={{display: "flex", textDecoration: "none", color: "white",
             alignItems: "center", flexDirection: "column"}} to='/gallery'>
              <FontAwesomeIcon 
              style={{
                cursor: 'pointer', marginTop: '5px',
                borderRadius: location.pathname === '/gallery' ? '0.3rem': undefined,
                padding:  location.pathname === '/gallery' ? '7px' : undefined,
                backgroundColor: location.pathname === '/gallery' ? '#6490bc' : '#121212',
                color: location.pathname === '/gallery' ? 'white' : 'white',
              }}
              icon={faPhotoVideo} />
              <span style={{marginTop: '4px',color: '#6490bc',  fontSize: "12px"}}>Gallery</span>
            </Links>
            </Tooltip>
</div>
        )}


{isMobile && !user && (
  <div style={{display: "flex",
  gap: "1.5rem",
  alignItems: "center", justifyContent: "center"}}>
  
            <Tooltip  title='Manda una richiesta di informazioni!'>
            <Links style={{display: "flex", textDecoration: "none", 
            color: "white", alignItems: "center", flexDirection: "column"}} to='/contact'>
              <FontAwesomeIcon 
              style={{
                cursor: 'pointer', marginTop: '5px',
                borderRadius: location.pathname === '/contact' ? '0.3rem': undefined,
                padding:  location.pathname === '/contact' ? '7px' : undefined,
                backgroundColor: location.pathname === '/contact' ? '#6490bc' : '#121212',
                color: location.pathname === '/contact' ? 'white' : 'white',
              }}
              icon={faEnvelope} />
<span style={{marginTop: '4px',color: '#6490bc', fontSize: "12px", textTransform: "uppercase"}}>{t('ContattiButton')}</span>
</Links>
            </Tooltip>

            <Tooltip  title='Apri il menu'>
            <div 
            onClick={toggleDrawer(true)}
            style={{display: "flex", textDecoration: "none", color: "white",
             alignItems: "center", flexDirection: "column"}}>
              <FontAwesomeIcon 
              style={{
                cursor: 'pointer', marginTop: '5px',
                borderRadius: location.pathname === '/gallery' ? '0.3rem': undefined,
                padding:  location.pathname === '/gallery' ? '7px' : undefined,
                backgroundColor: location.pathname === '/gallery' ? '#6490bc' : '#121212',
                color: location.pathname === '/gallery' ? 'white' : 'white',
              }}
              icon={faList} />
              <span style={{marginTop: '4px',color: '#6490bc',  fontSize: "12px"}}>MENU</span>
            </div>
            </Tooltip>

            
            </div>
)}

{user && (
            <div style={{display:"flex",  padding: "5px", alignItems: "center", 
              gap: "1rem",
              borderRadius: "0.5rem", width: isMobile ? "auto" : "auto", justifyContent: 'space-between',
              backgroundColor: location.pathname === '/admin/dashboard' ? 'black' : 'transparent',
               }}>

            <Links style={{color: "black"}} to='/admin/dashboard'>
            <Tooltip title='Accedi alla Dashboard Admin'>
               <Button
              style={{
                
                backgroundColor: location.pathname === '/admin/dashboard' ? 'limegreen' : '#e74c3c',
                color: location.pathname === '/admin/dashboard' ? 'black' : 'white',
                textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
                color: location.pathname === '/admin/dashboard' ? 'white' : 'white'}}>
            ADMIN
            <FontAwesomeIcon  style={{marginLeft: '10px', color: location.pathname === '/admin/dashboard' ? 'black' : 'white'}} icon={faUserTie} />
            </Button>
            </Tooltip>
            </Links>
            
            <Tooltip title='Esci e fai Logout'>
            <Button 
             style={{color: location.pathname === '/admin/dashboard' ? 'white' : 'white',
              textShadow: "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",

             }}
            onClick={logout}>
               Logout <FontAwesomeIcon style={{color: location.pathname === '/admin/dashboard' ? 'white' : 'white', marginLeft: "5px" }} icon={faRightFromBracket} />
              </Button>
              </Tooltip>
</div>
                      )}       

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <div
            style={{ width: isMobile ? '220px' : '350px',

              padding: isMobile ? "10px" : '30px',
              borderLeft :"1px solid white",
              display: "flex", flexDirection: "column",
              height: '100%', backgroundColor: "black" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >

            <center>
              <Links to='/'>
                
          <img src={logo} alt='#6490bc' variants={fadeIn} initial="initial" animate="animate"
          style={{width: isMobile  ? '180px' : "300px", objectFit: 'contain'}}/>
          </Links>


 <div style={{ display: 'flex',  justifyContent: "center",
  gap: isMobile ? '25px' : '1rem', }}>
    <Tooltip title='Seleziona lingua: ITALIANO'>
  <IconButton style={{padding: 0}}  onClick={() => changeLanguage('it')}>
    <IT title="Italiano" style={{ width:  isMobile  ? '20px': '20px', height: isMobile ? '20px' : '24px' }} />
  </IconButton>
  </Tooltip>
  <Tooltip title='Select language: ENGLISH'>

  <IconButton style={{padding: 0}} onClick={() => changeLanguage('en')}>
    <GB title="English" style={{ width:  isMobile  ? '20px': '20px', height: isMobile ? '20px' : '24px' }} />
  </IconButton>
  </Tooltip>
</div>


                    </center>

                    {/* <ListItem
            style={{
              backgroundColor: location.pathname === '/destinazioni' ? 'black' : 'transparent',
              color: location.pathname === '/destinazioni' ? 'black' : 'black',
              padding: "5px", borderRadius: "0.5rem"
            }}
            button component={Links} to="/destinazioni">
              <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faPhotoVideo} />
              </ListItemIcon>
              <ListItemText primary={t('Destinazioni')} />
            </ListItem> */}
           

<div style={{display: "flex",  marginTop: " 0.7rem", width: 'auto',  flexDirection: "column", gap: '1.5rem', }}>
 
 
<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/' ? '#6490bc' : '#121212',
              color: location.pathname === '/' ? 'white' : 'white',
             }}
            button component={Links} to="/">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faHome} />
              </ListItemIcon>
              
              {t('Home')}
            </Button>
            </div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px",width: "100%",
              boxShadow: location.pathname === '/blog' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/blog' ? '#6490bc' : '#121212',
              color: location.pathname === '/blog' ? 'white' : 'white',
             }}
            button component={Links} to="/blog">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faNewspaper} />
              </ListItemIcon>
              
              L.A.R. BLOG
            </Button>
            </div>


 
  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%",}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/elicotteri' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/elicotteri' ? '#6490bc' : '#121212',
              color: location.pathname === '/elicotteri' ? 'white' : 'white',
             }}
            button component={Links} to="/elicotteri">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faHelicopter} />
              </ListItemIcon>
              
              {t('Elicotteri')}
            </Button>
            </div>



            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/pubblicita-aerea' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/pubblicita-aerea' ? '#6490bc' : '#121212',
              color: location.pathname === '/pubblicita-aerea' ? 'white' : 'white',
             }}
            button component={Links} to="/pubblicita-aerea">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faPlaneDeparture} />
              </ListItemIcon>
              
              {t('PubblicitaAerea')}
            </Button>
            </div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/jet' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/jet' ? '#6490bc' : '#121212',
              color: location.pathname === '/jet' ? 'white' : 'white',
             }}
            button component={Links} to="/jet">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faJetFighterUp} />
              </ListItemIcon>
            
              {t('JetPrivati')}
            </Button>
            </div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/yacht' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/yacht' ? '#6490bc' : '#121212',
              color: location.pathname === '/yacht' ? 'white' : 'white',
             }}
            button component={Links} to="/yacht">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faSailboat} />
              </ListItemIcon>
              
              {t('Yacht')}
            </Button>
            </div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", 
              width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/supercar' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/supercar' ? '#6490bc' : '#121212',
              color: location.pathname === '/supercar' ? 'white' : 'white',
             }}
            button component={Links} to="/supercar">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faCar} />
              </ListItemIcon>
              
              {t('Super Cars')}
            </Button>
            </div>
           




            {/* <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/ncc' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/ncc' ? '#6490bc' : '#121212',
              color: location.pathname === '/ncc' ? 'white' : 'white',
             }}
            button component={Links} to="/ncc">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faCar} />
              </ListItemIcon>
              
              {t('NCC')}
            </Button>
            </div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px",width: "100%",
              boxShadow: location.pathname === '/security' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/security' ? '#6490bc' : '#121212',
              color: location.pathname === '/security' ? 'white' : 'white',
             }}
            button component={Links} to="/security">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faPeopleGroup} />
              </ListItemIcon>
              
              {t('Security')}
            </Button>
            </div> */}

            

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/about' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/about' ? '#6490bc' : '#121212',
              color: location.pathname === '/about' ? 'white' : 'white',
             }}
            button component={Links} to="/about">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faInfoCircle} />
              </ListItemIcon>
              
              {t('ChiSiamo')}
            </Button>
            </div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Button 
             style={{
              display: "flex", justifyContent:"center",
              fontSize: "12px", width: "100%",
              boxShadow: location.pathname === '/gallery' ? "white 0px 3px 2px, red 0px 3px 5px" : "#6490bc 0px 3px 2px, #000 0px 3px 2px",
              backgroundColor: location.pathname === '/gallery' ? '#6490bc' : '#121212',
              color: location.pathname === '/gallery' ? 'white' : 'white',
             }}
            button component={Links} to="/gallery">
                <ListItemIcon>
              <FontAwesomeIcon style={{color: "white"}} icon={faPhotoVideo} />
              </ListItemIcon>
              
              {t('Gallery')}
            </Button>
            </div>






            </div>

              

                     <Container style={{display: "flex", marginTop: isMobile ? '1rem' :  "1.5rem",
                    alignItems: 'center',  gap: '1rem', justifyContent: "center", padding: '5px'}}>

          <a style={{color: "white"}} href='https://www.instagram.com/lar_luxuryagencyrome/' target='blank' rel='noreferrer noopener'>
            <FontAwesomeIcon icon={faInstagram} style={{
              backgroundColor: "#6490bc", padding: "5px",borderRadius: ".3rem",
              width: "12px"}}/>
          </a>
          <a style={{color: "white"}} href='https://www.tiktok.com/@luxuryagencyrome' target='blank' rel='noreferrer noopener'>
            <FontAwesomeIcon icon={faTiktok} style={{
              backgroundColor: "#6490bc", padding: "5px",borderRadius: ".3rem",
              width: "12px"}}/>
          </a>
          <a style={{color: "white"}} href='https://wa.me/393762023340?text=Ciao!%20Vorrei%20ricevere%20maggiori%20informazioni.' target='blank' rel='noreferrer noopener'>
            <FontAwesomeIcon icon={faWhatsapp} style={{
              backgroundColor: "#6490bc", padding: "5px",borderRadius: ".3rem",
              width: "12px"}}/>
          </a>
          <a style={{color: "white"}} href='https://www.youtube.com/channel/UCKxKU5Q5T3BKQPTnBLs9tsw' target='blank' rel='noreferrer noopener'>
            <FontAwesomeIcon icon={faYoutube} style={{
              backgroundColor: "#6490bc", padding: "5px", borderRadius: ".3rem",
              width: "12px"}}/>
          </a>

          </Container> 

          <Typography style={{
            fontSize: '14px', textAlign: "center",
            marginTop: isMobile ? 0 : '1rem',
            fontFamily: "Belanosima"}}>
          © 2024 Luxury Agency Rome S.r.l
          </Typography>

          </div>




        </Drawer>



        {!isMobile && (
//           <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>

// <IconButton
//       style={{color: "white"}}
//       href={whatsappUrl}
//       target="_blank">
//               <FontAwesomeIcon icon={faWhatsapp}/>
//       </IconButton>
//                         <a style={{color: "white"}} href='https://www.tiktok.com/@luxuryagencyrome' target='blank' rel='noreferrer noopener'>
//             <IconButton color="inherit">
//               <FontAwesomeIcon icon={faTiktok} />
//             </IconButton>
//             </a>
//             <a style={{color: "white"}} href='https://www.instagram.com/lar_luxuryagencyrome/' target='blank' rel='noreferrer noopener'>
//             <IconButton color="inherit">
//               <FontAwesomeIcon icon={faInstagram} />
//             </IconButton>
//             </a>
//             <a style={{color: "white"}} href='https://www.youtube.com/channel/UCKxKU5Q5T3BKQPTnBLs9tsw' target='blank' rel='noreferrer noopener'>

//             <IconButton color="inherit">
//               <FontAwesomeIcon icon={faYoutube} />
//             </IconButton>
//           </a>
//           </Box>
<Social/>
        )}
      </div>
    </AppBar>
  );
}

export default Navbar;
