import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';

import HomePage from './pages/Homepage/Homepage';
import Navbar from './components/fixed/Navbar';
import Footer from './components/fixed/Footer';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './lang/i18n';
import Elicotteri from './pages/Servizi/Elicotteri/Elicotteri';
import TourPersonalizzati from './pages/Servizi/Elicotteri/TourPersonalizzati';
import Supercar from './pages/Servizi/SuperCar/Supercar';
import NCC from './pages/Servizi/NCC/NCC';
import Jet from "./pages/Servizi/Jet/Jet";
import Yacht from "./pages/Servizi/Yacht/Yacht";
import GalleryPage from "./pages/Gallery/GalleryPage";
import Security from './pages/Servizi/Security/Security';
import EconomyPackage from './pages/Servizi/Elicotteri/Tour/Pacchetti/Economy';
import PremiumPackage from './pages/Servizi/Elicotteri/Tour/Pacchetti/Premium';
import LuxuryPackage from './pages/Servizi/Elicotteri/Tour/Pacchetti/Luxury';
import PubblicitaAerea from './pages/Servizi/PubblicitaAerea/PubblicitaAerea';
import VoloBaitaSara from './pages/Servizi/Elicotteri/Tour/VoloConPranzo/VoloBaitaSara';
import VoloPaliano from './pages/Servizi/Elicotteri/Tour/VoloConPranzo/VoloPaliano';
import VoloPonzano from './pages/Servizi/Elicotteri/Tour/VoloConPranzo/VoloPonzano';
import VoloVescovio from './pages/Servizi/Elicotteri/Tour/VoloConPranzo/VoloVescovio';
import Destinazioni from './pages/Destinazioni';
import Avventura from './pages/Servizi/Elicotteri/Tour/Avventura';
import BackButton from './components/fixed/BackButton';
import VoloCampotosto from './pages/Servizi/Elicotteri/Tour/VoloConPranzo/VoloCampotosto';
import HelicopterInterior from './pages/Scuola/HelicopterInterior';
import PilotaGiorno from './pages/Servizi/Elicotteri/PilotaGiorno/PilotaGiorno';
import { AuthProvider } from './context/AuthContext';
import Access from "./admin/Access";
import Dashboard from './admin/Dashboard';
import axios from 'axios';
import YachtPonza from './pages/Servizi/Yacht/YachtPonza';
import YachtCrociera from './pages/Servizi/Yacht/YachtCrociera';
import YachtCatamarano from './pages/Servizi/Yacht/YachtCatamarano';
import CreatePost from './admin/blog/CreatePost';
import PostDetail from './admin/blog/PostDetail';
import EditPost from './admin/blog/EditPost';
import Blog from './admin/blog/Blog';
import NewsletterSubscribers from './admin/blog/NewsletterSubscribers';
import CookieBanner from "./components/fixed/CookieBanner";
import PrivacyPolicy from './pages/PrivacyPolicy';
import { SocketProvider } from './context/SocketContext'; // Importa il contesto del socket

import lux from './assets/lux.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import RequestList from './admin/components/RequestList';
import RequestDetails from './admin/components/RequestDetails';
import ResponsePage from './admin/components/ResponsePage';
import { ArticlesProvider } from './context/ArticleContext'; // Importa il provider del contesto

axios.defaults.baseURL = 'https://api-lar-014fd3aff401.herokuapp.com/api';
axios.defaults.withCredentials = true;

const NotFoundPage = ({ isMobile }) => (
  <Box style={{ display: 'flex', marginTop: '6rem', gap: "2.5rem", 
  flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <img src={lux} alt='Luxury' style={{ width: isMobile ? '100%' : "500px", objectFit: 'contain' }} />
    <Typography style={{textAlign: "center", 
      color: "white", borderRadius: "1rem", backgroundColor: '#6490bc', padding: "30px"}} >
      <FontAwesomeIcon icon={faWarning} style={{ marginRight:" 10px"}} />
      Errore 404
      <FontAwesomeIcon icon={faWarning} style={{marginLeft:" 10px"}} />
      <br/> Pagina non trovata
      <br/>
      <Link to='/'>
      <Button>
        Torna alla Home
        </Button>
        </Link>
      </Typography>
      <br/>
    
  </Box>
);

function Layout({ isMobile }) {
  return (
    <>
      <Navbar />
      <BackButton />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<GalleryPage />} />
        {/* <Route path="/destinazioni" element={<Destinazioni />} /> */}
        {/* MAIN PAGES */}
        <Route path="/elicotteri" element={<Elicotteri />} />
        <Route path="/supercar" element={<Supercar />} />
        <Route path="/ncc" element={<NCC />} />
        <Route path="/jet" element={<Jet />} />
        <Route path="/yacht" element={<Yacht />} />
        <Route path="/security" element={<Security />} />
        <Route path="/pubblicita-aerea" element={<PubblicitaAerea />} />
        <Route path="/pilota-per-un-giorno" element={<PilotaGiorno />} />
        {/* SEZIONE ELICOTTERI */}
        <Route path="/elicotteri/tour" element={<TourPersonalizzati />} />
        <Route path="/elicotteri/tour/economy" element={<EconomyPackage />} />
        <Route path="/elicotteri/tour/premium" element={<PremiumPackage />} />
        <Route path="/elicotteri/tour/luxury" element={<LuxuryPackage />} />
        {/* VOLO CON PRANZO */}
        <Route path="/elicotteri/tour/baita-saracinesco" element={<VoloBaitaSara />} />
        <Route path="/elicotteri/tour/paliano" element={<VoloPaliano />} />
        <Route path="/elicotteri/tour/ponzano" element={<VoloPonzano />} />
        <Route path="/elicotteri/tour/vescovio" element={<VoloVescovio />} />
        <Route path="/elicotteri/tour/campotosto" element={<VoloCampotosto />} />
        <Route path="/scuola" element={<HelicopterInterior />} />
        <Route path="/elicotteri/tour/avventura" element={<Avventura />} />
        {/* YACHT */}
        <Route path="/yacht/ponza" element={<YachtPonza />} />
        <Route path="/yacht/crociera" element={<YachtCrociera />} />
        <Route path="/yacht/catamarano" element={<YachtCatamarano />} />
        <Route path="*" element={<NotFoundPage isMobile={isMobile} />} />
        {/* ADMIN */}
        <Route path="/admin/access" element={<Access />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/posts/create" element={<CreatePost />} />
        <Route path="/admin/posts/edit/:id" element={<EditPost />} />
        <Route path="/admin/newsletter-users" element={<NewsletterSubscribers />} />
        {/* BLOG */}
        <Route path="/blog/" element={<Blog />} />
        <Route path="/blog/:slug" element={<PostDetail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/richieste" element={<RequestList/>} />
        <Route path="/richieste/:id" element={<RequestDetails />} />
        <Route path="/richieste/rispondi/:id" element={<ResponsePage />} />
        <Route path="*" element={<NotFoundPage isMobile={isMobile} />} />
      </Routes>
      <CookieBanner />
      <Footer />
    </>
  );
}

function App() {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <SocketProvider> {/* Wrap the application with the SocketProvider */}
            <ArticlesProvider> {/* Wrap the application with the ArticlesProvider */}
              <Layout isMobile={isMobile} />
            </ArticlesProvider>
          </SocketProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
