import React, { useState } from 'react';
import { Typography, Dialog } from '@mui/material';
import { Container, useMediaQuery } from '@mui/system';
import { useTranslation } from 'react-i18next';
import helitour from "../../assets/images/helitour.jpg";
import lux from '../../assets/lux.png';
import gold from "../../assets/gold.png";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import foto1 from "../../assets/images/gallery/1.JPG";
import foto2 from "../../assets/images/gallery/2.JPG";
import foto3 from "../../assets/images/gallery/3.JPG";
import foto4 from "../../assets/images/gallery/4.JPG";
import foto5 from "../../assets/images/gallery/5.JPG";
import foto6 from "../../assets/images/gallery/6.JPG";
import foto7 from "../../assets/images/gallery/7.JPG";
import foto8 from "../../assets/images/gallery/8.JPG";
import foto9 from "../../assets/images/gallery/9.JPG";
import foto10 from "../../assets/images/gallery/10.JPG";
import foto11 from "../../assets/images/gallery/11.JPG";
import foto12 from "../../assets/images/gallery/12.JPG";
import foto13 from "../../assets/images/gallery/13.JPG";
import foto14 from "../../assets/images/gallery/14.JPG";
import foto15 from "../../assets/images/gallery/15.JPG";
import foto16 from "../../assets/images/gallery/16.JPG";
import foto17 from "../../assets/images/gallery/17.JPG";
import foto18 from "../../assets/images/gallery/18.JPG";
import foto19 from "../../assets/images/gallery/19.JPG";
import foto20 from "../../assets/images/gallery/20.JPG";
import foto21 from "../../assets/images/gallery/21.JPG";
import foto22 from "../../assets/images/gallery/22.JPG";
import foto23 from "../../assets/images/gallery/23.JPG";
import foto24 from "../../assets/images/gallery/24.JPG";
import foto25 from "../../assets/images/gallery/25.JPG";
import foto26 from "../../assets/images/gallery/26.JPG";


const GalleryPage = () => {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);


  const handleOpenModal = (galleryIndex) => {
    setGalleryCurrentIndex(galleryIndex);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  const socialMediaPosts = [
    "https://www.instagram.com/p/C8eh9bFIuQe/",
    "https://www.instagram.com/p/C8ZYHoCON-3/",
    "https://www.instagram.com/p/C8UOk8-Pd9v/",
    "https://www.instagram.com/p/C8MgVRKIng2/",
    "https://www.instagram.com/p/C8HWml2pYz9/",
    "https://www.instagram.com/p/C8CjRjzol4q/",
    "https://www.instagram.com/p/C7l19f4IUMv/",
  ];

  const videoLinks = [
    "https://www.dailymotion.com/embed/video/k5LLqz4aOk2qtDAzgVe?autoplay=0",
  ]

  const galleryPhotos= [
    `${foto1}`,
    `${foto2}`,
    `${foto3}`,
    `${foto4}`,
    `${foto5}`,
    `${foto6}`,
    `${foto7}`,
    `${foto8}`,
    `${foto9}`,
    `${foto10}`,
    `${foto11}`,
    `${foto12}`,
    `${foto13}`,
    `${foto14}`,
    `${foto15}`,
    `${foto16}`,
    `${foto17}`,
    `${foto18}`,
    `${foto19}`,
    `${foto20}`,
    `${foto21}`,
    `${foto22}`,
    `${foto23}`,
    `${foto24}`,
    `${foto25}`,
    `${foto26}`,
];



  const prevPost = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : socialMediaPosts.length - 1));
  };

  const nextPost = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % socialMediaPosts.length);
  };

  return (
    <div style={{display: "flex", marginTop: "5rem", flexDirection: 'column', alignItems: "center", justifyContent: "center"}}>
        
        <img src={lux} alt='Luxury' style={{ width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>
        <img src={gold} alt='Gold' style={{ marginTop: "1rem", width: isMobile  ? '100%' : "500px", objectFit: 'contain'}}/>

        <div style={{
            width: isMobile ? 'auto' : "97%", display: "flex", 
            flexDirection: 'column',
            alignItems: "center", justifyContent: "center", marginTop: "1.5rem",
            padding: "30px", 
            background: `url(${helitour}) ${isMobile ? 'top' : 'center'} / ${isMobile ? 'contain' : 'cover'} no-repeat`
        }}>     
            <Typography sx={{ fontSize: isMobile ? '20px' : '24px', textAlign: "center"}}>
                Gallery
            </Typography>
            <div style={{
            width: isMobile ? 'auto' : "auto", display: "flex", 
            gap: "3rem", flexDirection: isMobile ? 'column' : undefined,

            backgroundColor: "rgba(0,0,0,0.4)", padding: '30px', borderRadius: "1rem",
             justifyContent: "center", marginTop: "1.5rem",
                  }}> 
          

                <Container style={{display: "flex", flexDirection: 'column'}}>
            {/* <Typography sx={{ fontSize: isMobile ? '20px' : '18px', 
                                        textAlign: "center"}}>
                <Trans i18nKey='GalleryIntro'/>
                </Typography> */}

           
          <div style={{
  display: "grid", width: "100%",
  alignItems: "center", justifyContent: "center",
  gridTemplateColumns: isMobile ? 'repeat(3,1fr)' : "repeat(3, 1fr)", // 10 colonne di uguale larghezza
  gridGap: "30px", // Distanza tra le immagini
  padding: '20px'
}}>
  {galleryPhotos.map((url, galleryIndex) => (
 <img
 key={galleryIndex}
 onClick={() => handleOpenModal(galleryIndex)}
 src={url}
 alt={`Slide image ${galleryIndex + 1}`}
 style={{
   borderRadius: "0.8rem",
   width: '100%',
   objectFit: 'contain'
 }}
/>

 
  ))}
</div>


                            </Container>
                            
          </div>

          {/* <div style={{display: "flex", 
            borderRadius: "1rem", alignItems: "center", justifyContent: "center",

            gap: "2rem", padding: "20px",  flexDirection: "column"}}>
                                                      <Typography sx={{ fontSize: isMobile ? '20px' : '24px', 
                                        textAlign: "justify"}}>
                VIDEO GALLERY
            </Typography>
                {videoLinks.map((url, index) => (
            <iframe
                                style={{
                                    width: '300px', 
                                    height: '180px', 
                                    borderRadius: "0.3rem",
                                    display: "flex",
                                    
                                }}
                                frameborder="0"
                                src={url}
                                allowFullScreen
                                title="Dailymotion Video Player"
                                allow="autoplay"
                            ></iframe>
                          ))}

                            </div> */}

{openModal && (
        <Dialog  open={openModal} onClose={handleCloseModal}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ position: 'absolute', right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={galleryPhotos[galleryCurrentIndex]}
            alt={`Full screen image ${galleryCurrentIndex + 1}`}
            style={{ width: 'auto', objectFit: 'contain' }}
          />
        </Dialog>
      )}
        </div>
    </div>
  );
};

export default GalleryPage;
