import { createTheme } from '@mui/material/styles';
import { color } from 'framer-motion';

const theme = createTheme({
  palette: {
    primary: {
      main: '#161616', // Un blu profondo per un senso di professionalità e serietà
    },
    secondary: {
      main: '#6490bc', // Oro per un look lussuoso
    },
  },
  typography: {
    h1: {
      fontWeight: '400',
      fontStyle: 'normal',
      color: '#6490bc', // Testi in oro per i titoli
    },
    body1: {
      fontSize: '1.2rem',
      color: '#ffffff', // Testo bianco per il contrasto su sfondi scuri
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "position": "relative",
          "textShadow": "1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
          "border": "none",
          "background": "var(--bg, #121212)",
          "color": "var(--text-color, #fff)",
          "padding": "10px",
          "height": "30px",
          "textTransform": "uppercase",
          "transition": "0.2s",
          "fontFamily": "Belanosima",
          "borderRadius": "5px",
          "opacity": "0.8",
          "letterSpacing": "1px",
          "boxShadow": "#6490bc 0px 3px 2px, #000 0px 3px 2px",
          "&:hover": {
            "opacity": "1",
            "background": "var(--bg, white)",
            "color": "black"
          },
          "&:active": {
            "top": "4px",
            "boxShadow": "#6490bc 0px 3px 2px, #000 0px 3px 5px"
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
        backgroundColor: "black"  
      } 
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: "#4b4b4b",
        fontFamily: "Belanosima",
        borderRadius: 0,
        fontSize: "14px",

      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: 'white'
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: "#6490bc"
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontFamily: "Belanosima",
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: "white",
        borderRadius: 0,
        height: 'auto',
        color: "black",
        border: "1px solid grey",
        fontSize: "16px",
        fontFamily: "Belanosima",
      }
    }
  },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Playfair Display",
          fontStyle: 'normal',
          color:  "white",
        }
      }
    }
  }
});

export default theme;
